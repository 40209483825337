export default {
    ABOUT: "O",
    ADVANCED_TOOLS: "Zaawansowane narzędzia dla handlowców",
    APPROVE: "Zatwierdzić",
    APY: "Apy",
    AUDITS: "Audyty",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog marki",
    BURNED_TOKEN: "Spalony token",
    BUY_FARM_SPEND: "Kupuj, hoduj, wydawaj i stawaj!",
    BUY_ONE80_TOKENS: "Kup jeden80 tokenów",
    CANCEL: "Anulować",
    CIRCULATE_SUPPLY: "Dostawa krążąca",
    CLOSE: "Blisko",
    COMMUNITY: "Wspólnota",
    COMMUNITY_JOIN: "Dopamine przekształca świat Defi, nasza społeczność i deweloperzy tworzą świat finansowy bez pokładów, platformę handlową defi na razie i przyszłość. Dołącz do nas!",
    CONFIRM: "Potwierdzać",
    CONFIRM_SWAP: "Potwierdź zamianę",
    CONNECT_WALLET: "Połącz z portfelem",
    CONNECT_WALLET_TRADE_TOKEN: "Połącz swój portfel, wymień dowolny token na ETH w kilka sekund.",
    CONTRIBUTORS: "Współpracownicy",
    CRYPTO_GROW: "Zobacz, jak rośnie twoje krypto!",
    CUSTOMER_SUPPORT: "Obsługa klienta",
    DEPOSIT_FEE: "Opłata za depozyt:",
    DETAILS: "Detale",
    DEVELOPERS: "Deweloperzy",
    DISCONNECT_WALLET: "Odłączyć portfel",
    DOCUMENTATION: "Dokumentacja",
    EARNED: "Zarobiony",
    EARN_PASSIVE_INCOME: "Zarabiaj dochód pasywny 24/7 za pomocą Dopamine",
    EARN_WHILE_SLEEP: "Zarabiaj podczas snu!",
    ETHEREUM: "Binance",
    EXCHANGE: "Wymieniać się",
    FARMS: "Gospodarstwa",
    FARMS_POOLS: "Gospodarstwa i baseny",
    FINISHED: "Skończone",
    GITHUB: "Github",
    GUIDES: "Przewodnicy",
    HARVEST: "Zbiór",
    HEART_ECOSYSTEM: "Token Dopamine jest sercem naszego ekosystemu.",
    HELP: "Pomoc",
    IDEAS_LAB: "Idee Lab",
    JOIN_ONE80: "Dołącz do rewolucji Dopamine Defi.",
    LAUNCH_APP: "Uruchom aplikację",
    LIQUIDITY: "Płynność",
    LIQUIDITY_PROVIDER_FEE: "Opłata dostawcy płynności",
    LITE_PAPER: "Lite Paper",
    LIVE: "Relacja na żywo",
    LOADING: "Ładowanie...",
    LOAD_MORE: "Załaduj więcej",
    MARKET_CAP: "Kapitan rynkowy",
    MAX: "Max",
    MINIMUM_RECEIVED: "Minimum otrzymane",
    MULTICHAIN_INSTANT_ACCESS: "Multichain już wkrótce, dając natychmiastowy dostęp do świata kryptografii.",
    NO_FARMS: "Żadnych gospodarstw",
    NO_POOLS: "Brak basenów",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Dopamine Korzyści dla wszystkich, Hodl, wymiana i handel 24/7.",
    ONE80_EARNED: "Dopamine zarobił",
    ONE80_MAKES_EASY: "Dopamine ułatwia, aby Twoje krypto działało dla Ciebie.",
    ONE80_WORLD: "Dopamine sprawia, że ​​nasz świat się kręci.",
    OUTPUT_ESTIMATED: "Wyjście jest szacowane. Jeśli zmiany ceny o więcej niż 0,8%, Twoja transakcja powróci.",
    OUTPUT_RECEIVE_ATLEAST: "Wyjście jest szacowane. Przynajmniej otrzymasz",
    PARTHER_BURNED: "LP Parther/Busd spalono",
    POOLS: "Totalizator piłkarski",
    PRICE: "Cena £",
    PRICE_IMPACT: "Wpływ na cenę",
    READABLE_CONTENT: "Jest to od dawna ustalony fakt, że czytelnik będzie rozproszony przez czytelną treść.",
    SEARCH_FARMS: "Wyszukaj gospodarstwa",
    SEARCH_POOLS: "Wyszukaj pule",
    SORT_BY: "Sortuj według",
    STAKE: "Stawka:",
    STAKED_ONLY: "Tylko postawiłem",
    STAKE_EARN_ONE80: "Tokeny stawki, aby zarobić Dopamine",
    STAKE_ONE80: "Tokeny LP, aby zarobić Dopamine",
    STAKE_TOKENS: "Tokeny stawki",
    START_EARNING: "Zacząć zarabiać",
    SUCCESS_COMPLETE: "Zakończone sukcesem",
    SUSTAIN: "Zrównoważony rozwój",
    TOKEN_PRICE: "Cena tokena",
    TOTAL_LIQUIDITY: "Całkowita płynność:",
    TOTAL_STAKE: "Total Staked:",
    TOTAL_STAKED_S: "Całkowity postawiony",
    TOTAL_SUPPLY: "Całkowita podaż",
    TRADERS_CHOOSE_ONE80: "Handlowcy wybierają Dopamine, aby automatycznie wymieniać i kierować transakcjami do najlepszych DEX, dając najlepsze wyniki.",
    TRADE_EARN_JOIN: "Handluj, zarabiaj i dołącz do naszej społeczności, skorzystaj z nagród Dopamine.",
    TRADE_NOW: "Handluj teraz",
    TRADE_REGISTRATION_HASSLE: "Wymienić wszystko. Bez rejestracji, bez kłopotów",
    TRANSACTION_REVERT: "lub transakcja powróci.",
    TROUBLE_SHOOT: "Kłopoty",
    TRUSTED_WORLD: "Zaufany na całym świecie",
    UNLOCK_WALLET: "Odblokować portfel",
    UNSTAKE_TOKENS: "Niestejsze tokeny",
    VIEW_ETHERSCAN: "Zobacz na BscScan",
    YOU_RECEIVE: "Otrzymasz",
    //Exchange and Liquidity
    ADD: "Dodać",
    ADD_LIQUIDITY: "Dodaj płynność",
    AMOUNT: "Kwota",
    BALANCE: "Saldo:",
    CLICK_SUPPLY_REVIEW: "Gdy będziesz zadowolony z dostawy kliknięcia stawki, aby przejrzeć.",
    CONNECT_WALLET_LIQUIDITY: "Połącz się z portfelem, aby zobaczyć swoją płynność.",
    CREATE_PAIR: "Utwórz parę",
    CREATE_POOL: "Utwórz pulę.",
    DONT_HAVE_LIQUIDITY: "Nie masz jeszcze płynności w tej puli.",
    DONT_SEE_POOL_JOINED: "Nie widzisz puli, do którego dołączyłeś?",
    ENABLE: "Włączać",
    ENTER_AMOUNT: "Wprowadź ilość",
    ENTER_AMOUNT_S: "Wprowadź ilość",
    FIRST_LIQUIDITY_PROVIDER: "Jesteś pierwszym dostawcą płynności.",
    FOR: "dla",
    FROM: "Z",
    IMPORT_IT: "Importować to.",
    IMPORT_POOL: "Pula importu",
    INSUFFICIENT: "Niewystarczający",
    INSUFFICIENT_BALANCE: "Niewystarczająca ilość środków",
    INSUFFICIENT_LIQUIDITY: "Niewystarczająca płynność tego handlu.",
    LIQUIDITY_PROV_FEE: "Opłata dostawcy płynności:",
    LIQUIDITY_REWARDS: "Nagrody dostawcy płynności",
    LP_TOKENS_IN_WALLET: "Tokeny LP w portfelu",
    MINUTES: "Minuty",
    NO_LIQUTITY_LIST: "Brak listy libutyczności",
    NO_POOL_FOUND: "Nie znaleziono basenu.",
    PER: "za",
    POOL: "Basen",
    POOL_FOUND: "Znaleziono basen!",
    POOL_SHARE: "Twój udział w basenie:",
    POOL_TOKENS: "Twoje tokeny basenowe:",
    PRICE_IMPACTS: "Wpływ ceny:",
    PRICE_IMPACT_HIGH: "Wpływ ceny na wysoki",
    RATES: "Stawki",
    RATIO_PRICE_POOL: "Wskaźnik dodanych tokenów ustali cenę tej puli.",
    RECENT_TRANSACTIONS: "ostatnie tranzakcje",
    REMOVE: "Usunąć",
    REMOVE_LIQUIDITY: "Usuń płynność",
    REMOVING_POOL_TOKENS: "Usuwanie tokenów basenu przekształca twoją pozycję z powrotem w tokeny podstawowe w bieżącej stawce, proporcjonalne do udziału w puli. Nakładane opłaty są zawarte w otrzymywanych kwotach.",
    ROUTE: "Trasa:",
    SELECT_A_TOKEN: "Wybierz token",
    SETTINGS: "Ustawienia",
    SHARE_OF_POOL: "Udział w puli:",
    SLIPPAGE_TOL: "Tolerancja poślizgu",
    SLIPPAGE_TOLERANCE: "Tolerancja poślizgu:",
    SUPPLY: "Dostarczać",
    SWAP: "Zamieniać",
    TO: "Do",
    TOKEN_FARM_UNSTAKE: "Lub, jeśli postawiłeś swoje tokeny Dopamine-LP w farmie, odkręć je, aby je tutaj zobaczyć.",
    TRANSACTION_DEADLINE: "Termin transakcji",
    TRANSACTION_NOT_FOUND: "Nie znaleziono trance",
    TRANSACTION_REVERT_PERCENTAGE: "Twoja trasakcja powróci, jeśli ceny nie są niekorzystne o więcej niż ten odsetek",
    WITHDRAW_LIQUIDITY: "Dostawcy płynności zarabiają 0.25%% opłaty za wszystkie transakcje proporcjonalne do ich udziału w puli. Opłaty są dodawane do puli, naliczane w czasie rzeczywistym i można je ubiegać przez wycofanie płynności.",
    YOUR_LIQUIDITY: "Twoja płynność",
    bALANCE: "saldo",
    MAXIMUM_SOLD: "Maksymalna sprzedana",
    MINIMUM_RECEIVED_C: "Minimalna otrzymana",
    TOKEN_NAME: "Nazwa tokenu",
    FOUND_MY_ADDRESS: "Znalezione według adresu",
    IMPORT_TOKEN: "Import token",
    SEARCH_NAME: "Szukaj według nazw lub adresów",


    AND: "oraz",
    CONFIRM_SUPPLY: "Potwierdź dostawę",
    CONFIRM_TRANSACTION_WALLET: "Potwierdź tę transakcję w portfelu",
    CONNECT_WALLETTT: "Połącz portfel",
    DEPOSITED: "Zdeponowane:",
    DISMISS: "Odrzucać",
    ERROR: "Błąd",
    INPUT_ESTIMATED_SELL_MOST: "Wejście jest szacowane. Sprzedasz najwięcej",
    LEARN_ABOUT_WALLETS: "Dowiedz się więcej o portfelach",
    NEW_TO_ETHEREUM: "Nowy w Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Wyjście jest szacowane. Jeśli cena zmienia się o więcej niż",
    PRICEE: "Cena £:",
    SUPPLYING: "Dostarczanie",
    SWAPPING: "Zamiana",
    TRANSACTION_REJECTED: "Transakcja odrzucona",
    TRANSACTION_SUBMITTED: "Przedstawiona transakcja",
    VIEW_ON_BSCSCAN: "Zobacz na BSCSCAN",
    WAITING_CONFIRMATION: "oczekiwanie na potwierdzenie",
    YOUR_TRANSACTION_REVERT: "Twoja transakcja powróci.",
    YOU_WILL_RECEIVE: "Otrzymacie :",
    PARTNERSHIPS: "Związki partnerskie",
}