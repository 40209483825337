export default {
    ABOUT: "על אודות",
    ADVANCED_TOOLS: "כלים מתקדמים לסוחרים",
    APPROVE: "לְאַשֵׁר",
    APY: "אפי",
    AUDITS: "ביקורת",
    BAL: "BAL:",
    BITCOIN_C: "ביטקוין",
    BITCOIN_S: "ביטקוין",
    BRAND_BLOG: "בלוג המותג",
    BURNED_TOKEN: "אסימון שרוף",
    BUY_FARM_SPEND: "קנו אותו, חקלו אותו, הוציאו אותו והחזיקו אותו!",
    BUY_ONE80_TOKENS: "קנו אסימונים של 80",
    CANCEL: "לְבַטֵל",
    CIRCULATE_SUPPLY: "אספקה ​​במחזור",
    CLOSE: "סגור",
    COMMUNITY: "קהילה",
    COMMUNITY_JOIN: "Dopamine הופך את עולם ה- Defi, הקהילה והמפתחים שלנו יוצרים עולם פיננסי ללא יסודות, פלטפורמת מסחר Defi לעת עתה והעתיד. הצטרף אלינו!",
    CONFIRM: "לְאַשֵׁר",
    CONFIRM_SWAP: "אשר החלף",
    CONNECT_WALLET: "התחבר לארנק",
    CONNECT_WALLET_TRADE_TOKEN: "חבר את הארנק שלך, סחר בכל אסימון ב- ETH תוך שניות.",
    CONTRIBUTORS: "תורמים",
    CRYPTO_GROW: "צפו בקריפטו שלכם גדל!",
    CUSTOMER_SUPPORT: "שירות לקוחות",
    DEPOSIT_FEE: "דמי פיקדון:",
    DETAILS: "פרטים",
    DEVELOPERS: "מפתחים",
    DISCONNECT_WALLET: "נתק את הארנק",
    DOCUMENTATION: "תיעוד",
    EARNED: "הרוויח",
    EARN_PASSIVE_INCOME: "להרוויח הכנסה פסיבית 24/7 עם 180",
    EARN_WHILE_SLEEP: "הרוויח בזמן שאתה ישן!",
    ETHEREUM: "Binance",
    EXCHANGE: "לְהַחלִיף",
    FARMS: "חוות",
    FARMS_POOLS: "חוות ובריכות",
    FINISHED: "גָמוּר",
    GITHUB: "Github",
    GUIDES: "מדריכים",
    HARVEST: "קְצִיר",
    HEART_ECOSYSTEM: "אסימון Dopamine הוא בלב המערכת האקולוגית שלנו.",
    HELP: "עֶזרָה",
    IDEAS_LAB: "מעבדת רעיונות",
    JOIN_ONE80: "הצטרף למהפכת Defi של Dopamine.",
    LAUNCH_APP: "אפליקציית צהריים",
    LIQUIDITY: "נְזִילוּת",
    LIQUIDITY_PROVIDER_FEE: "עמלת ספק נזילות",
    LITE_PAPER: "נייר לייט",
    LIVE: "לחיות",
    LOADING: "טוען...",
    LOAD_MORE: "טען יותר",
    MARKET_CAP: "שווי שוק",
    MAX: "מקס",
    MINIMUM_RECEIVED: "מינימום שהתקבל",
    MULTICHAIN_INSTANT_ACCESS: "Multichain בקרוב, נותן לך גישה מיידית לעולם של קריפטו.",
    NO_FARMS: "אין חוות",
    NO_POOLS: "אין בריכות",
    ONE80: "180",
    ONE80_BENEFITS: "היתרונות של אחד 80 לכולם, HODL, Exchange and Trade 24/7.",
    ONE80_EARNED: "Dopamine הרוויח",
    ONE80_MAKES_EASY: "Dopamine מקל על הקריפטו שלך לעבוד בשבילך.",
    ONE80_WORLD: "Dopamine גורם לעולמנו להסתובב.",
    OUTPUT_ESTIMATED: "פלט מוערך. אם המחיר משתנה ביותר מ- 0.8% העסקה שלך תחזור.",
    OUTPUT_RECEIVE_ATLEAST: "פלט מוערך. אתה תקבל לפחות",
    PARTHER_BURNED: "LP Parther/Busd נשרף",
    POOLS: "בריכות",
    PRICE: "מחיר",
    PRICE_IMPACT: "השפעת מחיר",
    READABLE_CONTENT: "עובדה ארוכה מבוססת כי הקורא יוסח על ידי התוכן הקריא.",
    SEARCH_FARMS: "חוות חיפוש",
    SEARCH_POOLS: "בריכות חיפוש",
    SORT_BY: "מיין לפי",
    STAKE: "לְהַמֵר:",
    STAKED_ONLY: "נוקב בלבד",
    STAKE_EARN_ONE80: "אסימוני אחזקות להרוויח אחד 80",
    STAKE_ONE80: "אסימוני LP של STAME כדי להרוויח אחד 80",
    STAKE_TOKENS: "אסימוני כתם",
    START_EARNING: "התחל להרוויח",
    SUCCESS_COMPLETE: "הושלם בהצלחה",
    SUSTAIN: "קיימות",
    TOKEN_PRICE: "מחיר אסימון",
    TOTAL_LIQUIDITY: "נזילות מוחלטת:",
    TOTAL_STAKE: "מוחלט:",
    TOTAL_STAKED_S: "סה\"כ נוקב",
    TOTAL_SUPPLY: "אספקה ​​מוחלטת",
    TRADERS_CHOOSE_ONE80: "סוחרים בוחרים 180 כדי להחליף ולנתב סחר באופן אוטומטי ל- DEXs הטובים ביותר, מה שמאפשר לך את התוצאות הטובות ביותר.",
    TRADE_EARN_JOIN: "סחר, להרוויח ולהצטרף לקהילה שלנו, נהנה מ- 80 תגמולים.",
    TRADE_NOW: "סחר עכשיו",
    TRADE_REGISTRATION_HASSLE: "סחר בכל דבר. אין רישום, אין טרחה",
    TRANSACTION_REVERT: "או שהעסקה תחזור.",
    TROUBLE_SHOOT: "צרות לירות",
    TRUSTED_WORLD: "מהימן ברחבי העולם",
    UNLOCK_WALLET: "ביטול נעילת הארנק",
    UNSTAKE_TOKENS: "אסימוני אי -סטייה",
    VIEW_ETHERSCAN: "מבט על BscScan",
    YOU_RECEIVE: "אתה תקבל",
    //Exchange and Liquidity
    ADD: "לְהוֹסִיף",
    ADD_LIQUIDITY: "הוסף נזילות",
    AMOUNT: "כמות",
    BALANCE: "איזון:",
    CLICK_SUPPLY_REVIEW: "ברגע שאתה מרוצה מהספקת לחץ על שיעור כדי לבחון.",
    CONNECT_WALLET_LIQUIDITY: "התחבר לארנק כדי להציג את הנזילות שלך.",
    CREATE_PAIR: "צור זוג",
    CREATE_POOL: "ליצור בריכה.",
    DONT_HAVE_LIQUIDITY: "אין לך עדיין נזילות בבריכה הזו.",
    DONT_SEE_POOL_JOINED: "לא רואים בריכה שהצטרפתם?",
    ENABLE: "לְאַפשֵׁר",
    ENTER_AMOUNT: "הכנס סכום",
    ENTER_AMOUNT_S: "הכנס סכום",
    FIRST_LIQUIDITY_PROVIDER: "אתה ספק הנזילות הראשון.",
    FOR: "ל",
    FROM: "מ",
    IMPORT_IT: "לייבא אותו.",
    IMPORT_POOL: "יבוא בריכה",
    INSUFFICIENT: "לא מספיק",
    INSUFFICIENT_BALANCE: "לא מספיק איזון",
    INSUFFICIENT_LIQUIDITY: "לא מספיק נזילות לסחר זה.",
    LIQUIDITY_PROV_FEE: "עמלת ספק נזילות:",
    LIQUIDITY_REWARDS: "תגמולים של ספק נזילות",
    LP_TOKENS_IN_WALLET: "אסימוני LP בארנק שלך",
    MINUTES: "דקות",
    NO_LIQUTITY_LIST: "אין רשימת liqutity",
    NO_POOL_FOUND: "לא נמצא בריכה.",
    PER: "לְכָל",
    POOL: "בריכה",
    POOL_FOUND: "בריכה נמצאה!",
    POOL_SHARE: "שיתוף הבריכה שלך:",
    POOL_TOKENS: "אסימוני הבריכה שלך:",
    PRICE_IMPACTS: "השפעת מחיר:",
    PRICE_IMPACT_HIGH: "השפעת מחיר לגובה",
    RATES: "תעריפים",
    RATIO_PRICE_POOL: "היחס בין אסימונים שתוסיפו יקבע את מחיר הבריכה הזו.",
    RECENT_TRANSACTIONS: "תנועות אחרונות",
    REMOVE: "לְהַסִיר",
    REMOVE_LIQUIDITY: "הסר את הנזילות",
    REMOVING_POOL_TOKENS: "הסרת אסימוני בריכה ממירה את עמדתך חזרה לאסימונים הבסיסיים בקצב הנוכחי, פרופורציונלית לחלקך בבריכה. עמלות שנצברו כלולות בסכומים שאתה מקבל.",
    ROUTE: "מַסלוּל:",
    SELECT_A_TOKEN: "בחר אסימון",
    SETTINGS: "הגדרות",
    SHARE_OF_POOL: "חלק בבריכה:",
    SLIPPAGE_TOL: "סובלנות להחליק",
    SLIPPAGE_TOLERANCE: "סובלנות להחליק:",
    SUPPLY: "לְסַפֵּק",
    SWAP: "לְהַחלִיף",
    TO: "ל",
    TOKEN_FARM_UNSTAKE: "לחלופין, אם הגעת לאסימוני Dopamine-LP שלך בחווה, הסתער אותם כדי לראות אותם כאן.",
    TRANSACTION_DEADLINE: "תאריך יעד לעסקה",
    TRANSACTION_NOT_FOUND: "טרנסציה לא נמצאה",
    TRANSACTION_REVERT_PERCENTAGE: "המסעדה שלך תחזור אם המחיר ישתנה באופן לא חיובי באחוז זה",
    WITHDRAW_LIQUIDITY: "ספקי הנזילות מרוויחים תשלום של 0.25% על כל המסחר ביחס לחלקם בבריכה. עמלות מתווספות לבריכה, צוברות בזמן אמת וניתן לטעון על ידי משיכת הנזילות שלך.",
    YOUR_LIQUIDITY: "הנזילות שלך",
    bALANCE: "איזון",
    MAXIMUM_SOLD: "מקסימום נמכר",
    MINIMUM_RECEIVED_C: "מינימום התקבל",
    TOKEN_NAME: "שם אסימון",
    FOUND_MY_ADDRESS: "נמצא בכתובת",
    IMPORT_TOKEN: "יבוא אסימון",
    SEARCH_NAME: "חפש לפי שמות או כתובת",

    AND: "ו",
    CONFIRM_SUPPLY: "אשר את האספקה",
    CONFIRM_TRANSACTION_WALLET: "אשר את העסקה הזו בארנק שלך",
    CONNECT_WALLETTT: "חבר את הארנק",
    DEPOSITED: "מופקד:",
    DISMISS: "לשחרר",
    ERROR: "שְׁגִיאָה",
    INPUT_ESTIMATED_SELL_MOST: "הקלט מוערך. אתה תמכור לכל היותר",
    LEARN_ABOUT_WALLETS: "למידע נוסף על ארנקים",
    NEW_TO_ETHEREUM: "חדש ב- Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "פלט מוערך. אם המחיר משתנה יותר מ",
    PRICEE: "מחיר:",
    SUPPLYING: "אספקת",
    SWAPPING: "החלפה",
    TRANSACTION_REJECTED: "העסקה נדחתה",
    TRANSACTION_SUBMITTED: "עסקה שהוגשה",
    VIEW_ON_BSCSCAN: "תצוגה ב- BSCSCAN",
    WAITING_CONFIRMATION: "מחכה לאישור",
    YOUR_TRANSACTION_REVERT: "העסקה שלך תחזור.",
    YOU_WILL_RECEIVE: "אתה תקבל :",
    PARTNERSHIPS: "שותפויות",
}