import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import HeaderDashboard from "../components/Header/HeaderDashboard";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Button } from "@material-ui/core";
import WalletModal from "../components/WalletModal.js";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Link } from "react-router-dom";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Multicall } from "ethereum-multicall";
import config from "../config/config";
import ABI from "../ABI/abi";
import { getEthaxHomeDetails } from "../ContractActions/MasterChefAction.js";
import { getTopForm, getTotalValue } from "./HomeCalculation/topfarms.js";
import {
  fetchPoolsPublicDataAsync,
  getTokenPricesFromFarm,
} from "./HomeCalculation/topPools.js";
import SlippageModal from "../components/SlippageModal.js";
import { getsocialmedialinks } from "../Api/CMSActions.js";
var web3 = new Web3(config.rpcurl);

const multicall = new Multicall({
  web3Instance: web3,
});

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { ...rest } = props;
  const theme = useSelector((state) => state.theme);
  const [count, setCount] = useState(1);
  const [totalsupply, settotalsupply] = useState(0);
  const [circulatebalance, setcirculatebalance] = useState(0);
  const [marketcap, setmarketcap] = useState(0);
  const [burnbalance, setburnbalance] = useState(0);
  const [ethaxprice, setEthaxPrice] = useState(0);
  const [lockedEthax, setLockedEthax] = useState(0);
  const [harvestValue, setHarvestDollar] = useState(0);
  const [totalHarvest, setTotalHarvest] = useState(0);
  const [lockedtotal, setlockedtotal] = useState(0);
  const [userbalance, setbalanceOfUser] = useState(0);
  const [slippageValue, setslippageValue] = useState(0.5);
  const [singleHopOnly, setsingleHopOnly] = useState(false);
  const [transdeadline, settransdeadline] = useState(5);
  const [socialmedialink, setsocialmedialink] = useState({
    facebook: "",
    instagram: "",
    linkedin: "",
    reddit: "",
    telegram: "",
    twitter: "",
    youtube: "",
  });
  const web3Reducer = useSelector((state) => state.web3Reucer);
  useEffect(() => {
    readcontract();
    getTotalValueLockedss();
    getsocialmeadiaFunc();
    // document.title = `You clicked ${count} times`;
    // console.log("Count: " + count);
    setCount(1);
  }, [count]);

  const getsocialmeadiaFunc = async () => {
    var { result } = await getsocialmedialinks();
    console.log(result, "result");
    setsocialmedialink({
      facebook: result?.facebook,
      instagram: result?.instagram,
      linkedin: result?.linkedin,
      reddit: result?.reddit,
      telegram: result?.telegram,
      twitter: result?.twitter,
      youtube: result?.youtube,
    });
  };

  // const getTotalValueLockedss = async () => {
  //   var totalValuess = await getTotalValue();
  //   var finalTotalLocked = parseInt(totalValuess)
  //   setlockedtotal(finalTotalLocked)
  //   // console.log('datatotalValuesstotalValuess: ', await parseFloat(totalValuess));
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      getEthaxdetails();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const getTotalValueLockedss = async () => {
    const topPools = await fetchPoolsPublicDataAsync();
    const topFarms = await getTopForm();
    let totalLocked = 0;
    if (topFarms && topFarms.length > 0) {
      if (topFarms[0] && topFarms[1] && topPools[0]) {
        const totalLiquidity1 =
          topFarms[0].lpTotalInQuoteToken * topFarms[0].quoteTokenPriceBusd;
        const totalLiquidity2 =
          topFarms[1].lpTotalInQuoteToken * topFarms[1].quoteTokenPriceBusd;
        totalLocked = parseFloat(totalLiquidity1) + parseFloat(totalLiquidity2);
        const totalPool1 =
          (parseFloat(topPools[0].gettotalstaked) *
            topPools[0].stakingTokenPrice) /
          1000000000000000000;
        totalLocked =
          parseFloat(totalLiquidity1) +
          parseFloat(totalLiquidity2) +
          parseFloat(totalPool1);
        // const poolBal = getTotalStakedBalance();
        // totalLocked += poolBal;
      }
    }
    setlockedtotal(totalLocked);
    // console.log('datatotalValuesstotalValuess: ', parseFloat(totalLocked));
  };

  const getEthaxdetails = async () => {
    const {
      lockedValue,
      finalpendingValue,
      totalpendingValue,
      balanceOfUser,
      lockeddollarvalue,
    } = await getEthaxHomeDetails();
    setLockedEthax(lockeddollarvalue);
    setTotalHarvest(finalpendingValue);
    setHarvestDollar(totalpendingValue);
    setbalanceOfUser(balanceOfUser);
  };

  async function readcontract() {
    var contractCallContext = [
      {
        reference: "totalSupply",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "totalSupply",
            methodName: "totalSupply",
            methodParameters: [],
          },
        ],
      },
      {
        reference: "balanceOf",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: ["0x0000000000000000000000000000000000000000"],
          },
        ],
      },
      {
        reference: "balanceOfETHLP",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          },
        ],
      },
      {
        reference: "balanceOfBusdLP",
        contractAddress: config.BUSD,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          },
        ],
      },
    ];
    const results = await multicall.call(contractCallContext);
    var totalSupply = await getFormatMulticall(results, "totalSupply", 0);
    var burnbal = await getFormatMulticall(results, "balanceOf", 0);
    var EthlpBAl = await getFormatMulticall(results, "balanceOfETHLP", 0);
    EthlpBAl = new BigNumber(EthlpBAl.hex, 16);
    EthlpBAl = EthlpBAl.toString(10);
    EthlpBAl = EthlpBAl / 1000000000000000000;

    var BusdLpbal = await getFormatMulticall(results, "balanceOfBusdLP", 0);
    BusdLpbal = new BigNumber(BusdLpbal.hex, 16);
    BusdLpbal = BusdLpbal.toString(10);
    BusdLpbal = BusdLpbal / 1000000000000000000;

    let busdprice = BusdLpbal / EthlpBAl;
    console.log('busdprice: ', busdprice);

    setEthaxPrice(busdprice.toFixed(3));
    let ten = new BigNumber(totalSupply.hex, 16);
    var userdigibalance = ten.toString(10);
    totalSupply = userdigibalance / 1000000000000000000;
    let market_cap = totalSupply * busdprice.toFixed(3);
    settotalsupply(totalSupply);
    setmarketcap(market_cap);
    let ten1 = new BigNumber(burnbal.hex, 16);
    var burn_bal = ten1.toString(10);
    burn_bal = burn_bal / 1000000000000000000;
    setburnbalance(burn_bal);
    let circulating_bal = totalSupply - burn_bal;
    setcirculatebalance(circulating_bal);
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var returnVal =
        results &&
        results.results &&
        results.results[name] &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext[pos] &&
        results.results[name].callsReturnContext[pos].returnValues &&
        results.results[name].callsReturnContext[pos].returnValues[0]
          ? results.results[name].callsReturnContext[pos].returnValues[0]
          : "";
      return returnVal;
    } catch (err) {
      return "";
    }
  }
  function childSettingClick(value) {
    if (value && value.settings) {
      setslippageValue(value.settings);
    }
    if (value && value.deadline) {
      settransdeadline(value.deadline);
    }

    if (value && value.ismultiHops) {
      var isHops = value.ismultiHops === "true" ? true : false;
      setsingleHopOnly(isHops);
    }
  }
  const Redirectfun = () => {
    history.push("/exchange");
  };
  return (
    <div className="home_header">
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme && theme.value === "dark" ? (
            <img src={require("../assets/images/logo.png")} alt="logo" />
          ) : (
            <img src={require("../assets/images/logo.png")} alt="logo" />
          )
        }
        rightLinks={<HeaderDashboard />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner_first_row">
                <h1>Top</h1>
                <img
                  src={require("../assets/images/banner_small_img.png")}
                  alt="Dopamine"
                  className="img-fluid"
                />
              </div>
              <h1>Decentralized Crypto Exchange</h1>
              <p>
                Trade diverse crypto assets and perpetual markets with unmatched
                revates in our cutting-edge decentralized platform
              </p>
              <button className="primary_btn" onClick={Redirectfun}>
                Start Trading Now
              </button>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="banner_img_bg">
                <img
                  src={require("../assets/images/banner_img.png")}
                  alt="Dopamine"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <section className="section feature_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <h2 className="text-center main_title">Key Features</h2>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-9 m-auto">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="box_home text-center">
                      <img
                        src={require("../assets/images/icon_01.png")}
                        alt="Icon"
                        class="img-fluid"
                      />
                      <h3>Decentralization</h3>
                      <p className="para">
                        Explain how your DEX operates on a blockchain, removing
                        the need for a central authority.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="box_home text-center">
                      <img
                        src={require("../assets/images/icon_02.png")}
                        alt="Icon"
                        class="img-fluid"
                      />
                      <h3>Security</h3>
                      <p className="para">
                        Emphasize the security benefits of decentralized
                        trading, such as reduced risk of hacking and fraud.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section assets_section">
          <div className="container">
            <img
              src={require("../assets/images/img_01.png")}
              alt="Icon"
              class="img-fluid d-none d-md-block"
            />
            <div className="asset_inner">
              <h3 className="main_title_small">
                All the chains that matter in one place
              </h3>
              <p className="para">
                List the cryptocurrencies and tokens supported on your platform.
                Mention any plans for future additions.
              </p>
              <div className="asset_btn_grps">
                <a href="#" target="_blank">
                  <img
                    src={require("../assets/images/googleplay_btn.png")}
                    alt="Icon"
                    class="img-fluid"
                  />
                </a>
                <a href="#" target="_blank">
                  <img
                    src={require("../assets/images/apple_btn.png")}
                    alt="Icon"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section join_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <h2 className="text-center main_title_small">
                  Join the Dopamine Exchange - Crypto for Everyone
                </h2>
              </div>
            </div>
            <div className="row join_section_row mt-5">
              <div className="col-lg-6">
                <div className="box_home stats_box">
                  <h3>Farms & Staking</h3>
                  <p className="para">
                    Explain how your DEX operates on a blockchain, removing the
                    need for a central authority.
                  </p>
                  <div className="stats_list">
                    <div>
                      <label>Dopamine to Harvest</label>
                      <label>
                        <span>-{totalHarvest.toFixed(9)}</span>
                        <span>~ ${harvestValue.toFixed(9)}</span>
                      </label>
                    </div>
                    <div>
                      <label>Dopamine in Wallet Locked</label>
                      <label>
                        <span>-{userbalance.toFixed(3)}</span>
                        <span>~${lockedEthax.toFixed(3)}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box_home stats_box">
                  <h3>Dopamine Stats</h3>
                  <p className="para">
                    Explain how your DEX operates on a blockchain, removing the
                    need for a central authority.
                  </p>
                  <div className="stats_list">
                    <div>
                      <label>Market cap</label>
                      <label>${marketcap.toLocaleString()}</label>
                    </div>
                    <div>
                      <label>Max Supply</label>
                      <label>{totalsupply.toLocaleString()}</label>
                    </div>
                    <div>
                      <label>Total Burned</label>
                      <label>{burnbalance}</label>
                    </div>
                    <div>
                      <label>Dopamine Price</label>
                      <label>${ethaxprice}</label>
                    </div>
                    <div>
                      <label>Total Supply</label>
                      <label>{totalsupply.toLocaleString()}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="counter_section">
                  <div>
                    <label>Total Supply</label>
                    <label>{totalsupply.toLocaleString()}</label>
                  </div>
                  <div>
                    <label>Burned to Date</label>
                    <label>233,585,274</label>
                  </div>
                  <div>
                    <label>Market Cap</label>
                    <label>$4.6 Billion</label>
                  </div>
                  <div>
                    <label>Current Emissions</label>
                    <label>15/Block</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section swap_bg_section">
          <div className="container">
            <div className="box_home stats_box">
              <div className="row">
                <div className="col-lg-6">
                  <h4>Switch and link it just works.</h4>
                  <p className="para">
                    Cross-chain swaps, endless DEX and bridge liquidity, and
                    Flashbots protection from sandwich attacks and MEV.
                  </p>
                  <h5>
                    Always the best available price <br />
                    What you see is what you get
                  </h5>
                  <button className="primary_btn" onClick={Redirectfun}>
                    Trade Now
                  </button>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    src={require("../assets/images/swap_img.png")}
                    alt="Swap"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section community_section">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-8 m-auto">
                <h2 className="text-center main_title_small">
                  Join the Community
                </h2>
                <p className="para text-center">
                  Follow us on Social Media and become a part of the Dopamine
                  Community Today.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-10 m-auto">
                <div className="community_panel">
                  <a
                    href={
                      socialmedialink?.twitter ? socialmedialink?.twitter : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_01.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Twitter</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    href={
                      socialmedialink?.telegram
                        ? socialmedialink?.telegram
                        : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_02.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Telegram</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    href={
                      socialmedialink?.instagram
                        ? socialmedialink?.instagram
                        : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_03.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Instagram</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    href={
                      socialmedialink?.facebook
                        ? socialmedialink?.facebook
                        : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_04.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Facebook</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    href={
                      socialmedialink?.youtube ? socialmedialink?.youtube : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_05.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Youtube</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>

                  <a
                    href={
                      socialmedialink?.reddit ? socialmedialink?.reddit : "#"
                    }
                    target="_blank"
                    className="community_panel_div"
                  >
                    <img
                      src={require("../assets/images/social_06.png")}
                      alt="Social"
                      class="img-fluid"
                    />
                    <div>
                      <h3>Reddit</h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                          fill="#345983"
                        />
                        <path
                          d="M22.0712 10H16.0898C15.582 10 15.161 10.421 15.161 10.9286C15.161 11.4362 15.582 11.8571 16.0898 11.8571H19.8297L10.2693 21.4152C9.91022 21.7743 9.91022 22.3686 10.2693 22.7276C10.4551 22.9133 10.6904 23 10.9257 23C11.161 23 11.3963 22.9133 11.582 22.7276L21.1424 13.1695V16.9086C21.1424 17.4162 21.5635 17.8371 22.0712 17.8371C22.5789 17.8371 23 17.4162 23 16.9086V10.9286C23 10.421 22.5789 10 22.0712 10Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SlippageModal onChildClick={childSettingClick} />
      <FooterHome />
      <WalletModal />
    </div>
  );
}
