import MasterChef from "../ABI/MasterChef.json";
import LPABI from "../ABI/LPABI.json";
import BEP20 from "../ABI/BEP20.json";
import ABI from "../ABI/abi";
import config from "../config/config";
import Web3 from "web3";
import { connection } from "../helper/connection";
import JSBI from 'jsbi/dist/jsbi.mjs';
import {
  Multicall
} from "ethereum-multicall";

import {
  convertToWei,
  divideDecimal
} from "../helper/custommath";
import BigNumber from "bignumber.js";

import {
  convert
} from "../helper/convert"
import { farmcommission, getFormData, } from '../Api/FarmActions';
import { getPoolData } from '../Api/PoolActions';
import farmsTokenlists from "../views/Tokenlists/farmsTokenlists.json"
import poolsTokenlists from '../views/Tokenlists/poolsTokenlists.json'
import farmsconfig from "../views/HomeCalculation/AllTokens.json"
import { getUserRecentTransaction } from "../Api/SwapActions";

var zeroAddr = "0x0000000000000000000000000000000000000000";

export async function getFormsDetails(data) {
  console.log('daasasasta: ', data);
  var get = await connection();
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }

    let { result } = await getFormData(data);
    var range = (result && result.length > 0) ? result : [];
    console.log('rasdsdsdsdssssnge: ', range);
    // var range = farmsTokenlists && farmsTokenlists.tokens ? farmsTokenlists.tokens : []
    // console.log('rangssssssssssssse: ', range);


    const multicall = new Multicall({
      web3Instance: web3,
    });

    var pollArray = [];
    var obj = {};
    if (range && range.length > 0) {
      for (var i in range) {
        var ran = range[i].pid;

        var masterChefContract = [
          {
            reference: "poolInfo",
            contractAddress: config.MasterChef,
            abi: MasterChef,
            calls: [
              {
                reference: "poolInfo",
                methodName: "poolInfo",
                methodParameters: [ran],
              },
              {
                reference: "userInfo",
                methodName: "userInfo",
                methodParameters: [ran, address],
              },
              {
                reference: "ethaxApy",
                methodName: "ethaxApy",
                methodParameters: [],
              },
            ]
          }
        ];

        const poolresults = await multicall.call(masterChefContract);

        var poolinfo = await getFormatMulticall(poolresults, "poolInfo", 0);
        var stakeBal = await getFormatMulticall(poolresults, "poolInfo", 1);
        var ethaxApy = await getFormatMulticall(poolresults, "poolInfo", 2);

        var contractCallContext = [
          {
            reference: "LPtokengetBalanceof",
            contractAddress: poolinfo[0],
            abi: LPABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [config.MasterChef],
              },
              {
                reference: "allowance",
                methodName: "allowance",
                methodParameters: [address, config.MasterChef],
              }
              // {
              //   reference: "symbol",
              //   methodName: "symbol",
              // },
            ],
          }
        ];

        const results = await multicall.call(contractCallContext);

        var LPtokengetBalanceof = await getFormatMulticall(results, "LPtokengetBalanceof", 0);
        var LPtokengettotalSupply = await getFormatMulticall(results, "LPtokengetBalanceof", 1);
        var ApprovedAlready = await getFormatMulticall(results, "LPtokengetBalanceof", 2);
        // var symboll = await getFormatMulticall(results, "LPtokengetBalanceof", 3);

        LPtokengettotalSupply = await divideDecimal(parseInt(LPtokengettotalSupply.hex), 18);
        LPtokengetBalanceof = await divideDecimal(parseInt(LPtokengetBalanceof.hex), 18);
        ApprovedAlready = await divideDecimal(parseInt(ApprovedAlready.hex), 18);
        var stakeBalance = await divideDecimal(parseInt(stakeBal[0].hex), 18);
        console.log(`${config.baseUrl + "/" + range[i].logoURI}`, 'sssssssssssssss')
        obj = {
          tokenA: range[i].tokenSymbol,
          tokenB: range[i].quoteTokenSymbol,
          lpSymbol: range[i].lpSymbol,
          TotalSupply: LPtokengettotalSupply,
          LPBalance: LPtokengetBalanceof,
          endsIn: 0,
          LPaddress: poolinfo[0],
          allowance: ApprovedAlready,
          pid: ran,
          stakeBal: stakeBalance,
          stakeBalOf: parseInt(stakeBal[0].hex),
          earned: 0,
          apr: 0,
          logoURI: range[i].logoURI,
          depositFee: poolinfo[1] / 100,
          apy: parseInt(ethaxApy.hex) / 100,
          status: range[i].status,
          symboll: range[i].addsymbol
        };

        pollArray.push(obj);

      }
    }
    return {
      value: pollArray,
      status: true,
    };

  } catch (err) {
    console.log('erssssssssssssssssr: ', err);
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function fetchPoolsDetails(data) {
  var get = await connection();
  // return;
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }

    let { result } = await getPoolData(data);
    var range = (result && result.length > 0) ? result : [];
    console.log('ransdsdsssssssssge: ', range);
    // var range = poolsTokenlists && poolsTokenlists.tokens ? poolsTokenlists.tokens : []

    const multicall = new Multicall({
      web3Instance: web3,
    });

    var pollArray = [];
    var obj = {};
    if (range) {
      for (var i in range) {
        var ran = range[i].pid;
        console.log('ranssssssssss: ', ran);
        console.log('1111111111111')

        var masterChefContract = [
          {
            reference: "poolInfo",
            contractAddress: config.MasterChef,
            abi: MasterChef,
            calls: [
              {
                reference: "poolInfo",
                methodName: "poolInfo",
                methodParameters: [ran],
              },
              {
                reference: "userInfo",
                methodName: "userInfo",
                methodParameters: [ran, address],
              },
              {
                reference: "ethaxApy",
                methodName: "ethaxApy",
                methodParameters: [],
              },
            ]
          }
        ];

        const poolresults = await multicall.call(masterChefContract);


        var poolinfo = await getFormatMulticall(poolresults, "poolInfo", 0);
        var stakeBal = await getFormatMulticall(poolresults, "poolInfo", 1);
        var ethaxApy = await getFormatMulticall(poolresults, "poolInfo", 2);
        console.log('222222222222222')
        const contractCallContext = [
          {
            reference: "LPtokengetBalanceof",
            contractAddress: poolinfo[0],
            abi: LPABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [config.MasterChef],
              },
              {
                reference: "allowance",
                methodName: "allowance",
                methodParameters: [address, config.MasterChef],
              },
            ],
          }
        ];

        const results = await multicall.call(contractCallContext);
        console.log('3333333333')
        var LPtokengetBalanceof1 = await getFormatMulticall(results, "LPtokengetBalanceof", 0);
        var LPtokengettotalSupply1 = await getFormatMulticall(results, "LPtokengetBalanceof", 1);
        var ApprovedAlready = await getFormatMulticall(results, "LPtokengetBalanceof", 2);

        var TotalSupply = await divideDecimal(parseInt(LPtokengettotalSupply1.hex), 18);
        LPtokengetBalanceof1 = await divideDecimal(parseInt(LPtokengetBalanceof1.hex), 18);
        ApprovedAlready = await divideDecimal(parseInt(ApprovedAlready.hex), 18);
        var stakeBal = await divideDecimal(parseInt(stakeBal[0].hex), 18);
        console.log('44444444444444')

        // obj = {
        //   lpSymbol: range[i].lpSymbol,
        //   TotalSupply: parseFloat(TotalSupply),
        //   LPBalance: parseFloat(LPtokengetBalanceof1),
        //   LPaddress: poolinfo[0],
        //   allowance: ApprovedAlready,
        //   pid: ran,
        //   stakeBal: stakeBal,
        //   earned: 0,
        //   apy: parseInt(ethaxApy.hex) / 100,
        //   lastRewardBlock: 0,
        //   logoURI: range[i].logoURI,
        //   depositFee: poolinfo[1] / 100,
        //   status: range[i].status,
        //   sousID: range[i].sousId,
        //   earnedDollarValue: 0
        // };
        obj = {
          lpSymbol: range[i].lpSymbol,
          TotalSupply: parseFloat(TotalSupply),
          LPBalance: parseFloat(LPtokengetBalanceof1),
          LPaddress: poolinfo[0],
          allowance: ApprovedAlready,
          pid: ran,
          stakeBal: stakeBal,
          earned: 0,
          apy: parseInt(ethaxApy.hex) / 100,
          lastRewardBlock: 0,
          logoURI: range[i].logoURI,
          depositFee: poolinfo[1] / 100,
          status: range[i].status,
          sousID: range[i].sousId,
          earnedDollarValue: 0
        };
        console.log('osdsdsdbj: ', obj);

        pollArray.push(obj);

      }
    }
    return {
      value: pollArray,
      status: true,
    };

  } catch (err) {
    console.log('eeererrr: ', err);
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function getDetails(type, data) {
  var get = await connection();
  // return;
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }

    var range = [];
    var count = 0;
    var apy = 0;
    if (type === "farm") {
      let { result, totalrecords } = await getFormData(data);
      // var getForms = farmsList.filter(val => val.isTokenOnly == false);
      // var range = farmsTokenlists && farmsTokenlists.tokens ? farmsTokenlists.tokens : []

      range = result;
      count = 2;
      // apy=apy;
    } else {
      let { result, totalrecords } = await getPoolData(data);
      console.log('resusdsdsdlt: ', result);
      // var range = poolsTokenlists && poolsTokenlists.tokens ? poolsTokenlists.tokens : []


      // var getForms1 = farmsList.filter(val => val.isTokenOnly == true);
      range = result;
      count = 1;
      // apy=apy;
    }

    const multicall = new Multicall({
      web3Instance: web3,
    });

    var pollArray = [];
    var obj = {};
    if (range) {
      for (var i in range) {
        var ran = range[i].pid;

        var masterChefContract = [
          {
            reference: "poolInfo",
            contractAddress: config.MasterChef,
            abi: MasterChef,
            calls: [
              {
                reference: "poolInfo",
                methodName: "poolInfo",
                methodParameters: [ran],
              },
              {
                reference: "userInfo",
                methodName: "userInfo",
                methodParameters: [ran, address],
              },
            ]
          }
        ];

        const poolresults = await multicall.call(masterChefContract);

        var poolinfo = await getFormatMulticall(poolresults, "poolInfo", 0);
        var stakeBal = await getFormatMulticall(poolresults, "poolInfo", 1);


        if (type === "farm") {

          var contractCallContext = [
            {
              reference: "LPtokengetBalanceof",
              contractAddress: poolinfo[0],
              abi: LPABI,
              calls: [
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [address],
                },
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [config.MasterChef],
                },
                {
                  reference: "allowance",
                  methodName: "allowance",
                  methodParameters: [address, config.MasterChef],
                }
              ],
            }
          ];

          const results = await multicall.call(contractCallContext);

          var LPtokengetBalanceof = await getFormatMulticall(results, "LPtokengetBalanceof", 0);
          var LPtokengettotalSupply = await getFormatMulticall(results, "LPtokengetBalanceof", 1);
          var ApprovedAlready = await getFormatMulticall(results, "LPtokengetBalanceof", 2);

          LPtokengettotalSupply = await divideDecimal(parseInt(LPtokengettotalSupply.hex), 18);
          LPtokengetBalanceof = await divideDecimal(parseInt(LPtokengetBalanceof.hex), 18);
          ApprovedAlready = await divideDecimal(parseInt(ApprovedAlready.hex), 18);
          var stakeBalance = await divideDecimal(parseInt(stakeBal[0].hex), 18);

          obj = {
            tokenA: range[i].tokenSymbol,
            tokenB: range[i].quoteTokenSymbol,
            lpSymbol: range[i].lpSymbol,
            TotalSupply: LPtokengettotalSupply,
            LPBalance: LPtokengetBalanceof,
            endsIn: 0,
            LPaddress: poolinfo[0],
            allowance: ApprovedAlready,
            pid: ran,
            stakeBal: stakeBalance,
            stakeBalOf: parseInt(stakeBal[0].hex),
            earned: 0,
            apr: 0,
            logoURI: range[i].logoURI,
            depositFee: poolinfo[1] / 100,
            apy: parseInt(poolinfo[2].hex) / 100,
            status: range[i].status,
          };

          pollArray.push(obj);
        } else if (type === "pool") {

          const contractCallContext = [
            {
              reference: "LPtokengetBalanceof",
              contractAddress: poolinfo[0],
              abi: LPABI,
              calls: [
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [address],
                },
                {
                  reference: "balanceOf",
                  methodName: "balanceOf",
                  methodParameters: [config.MasterChef],
                },
                {
                  reference: "allowance",
                  methodName: "allowance",
                  methodParameters: [address, config.MasterChef],
                },
              ],
            }
          ];

          const results = await multicall.call(contractCallContext);

          var LPtokengetBalanceof1 = await getFormatMulticall(results, "LPtokengetBalanceof", 0);
          var LPtokengettotalSupply1 = await getFormatMulticall(results, "LPtokengetBalanceof", 1);
          var ApprovedAlready = await getFormatMulticall(results, "LPtokengetBalanceof", 2);

          var TotalSupply = await divideDecimal(parseInt(LPtokengettotalSupply1.hex), 18);
          LPtokengetBalanceof1 = await divideDecimal(parseInt(LPtokengetBalanceof1.hex), 18);
          ApprovedAlready = await divideDecimal(parseInt(ApprovedAlready.hex), 18);
          var stakeBal = await divideDecimal(parseInt(stakeBal[0].hex), 18);

          obj = {
            lpSymbol: range[i].lpSymbol,
            TotalSupply: parseFloat(TotalSupply),
            LPBalance: parseFloat(LPtokengetBalanceof1),
            LPaddress: poolinfo[0],
            allowance: ApprovedAlready,
            pid: ran,
            stakeBal: stakeBal,
            earned: 0,
            apy: parseInt(poolinfo[2].hex) / 100,
            lastRewardBlock: 0,
            logoURI: range[i].logoURI,
            depositFee: poolinfo[1] / 100,
            status: range[i].status,
          };


          pollArray.push(obj);
        }
      }
    }
    return {
      value: pollArray,
      count: count,
      status: true,
    };

  } catch (err) {
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function approvetoken(LPaddress) {
  console.log('LPaddress: ', LPaddress);
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var gasPrice = await web3.eth.getGasPrice();
      var Contract = new web3.eth.Contract(LPABI, LPaddress);
      var balance = await Contract.methods.balanceOf(address).call()
      balance = parseFloat(balance) / 10 ** 18;
      console.log(balance, 'balancebalancebalance')
      if (parseFloat(balance) <= 0) {
        return {
          value: "Insufficient balance",
          status: false,
        };
      }
      var approveAmt = (100000000) * (10 ** 18);
      approveAmt = await convert(approveAmt);

      var estimateGas = await Contract.methods
        .approve(config.MasterChef, approveAmt.toString())
        .estimateGas({ from: address, gasPrice: gasPrice });
      var estimateGas = estimateGas + 100000;
      var result1 = await Contract.methods
        .approve(config.MasterChef, approveAmt.toString())
        .send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
      var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
        result1.events.Approval.returnValues.value : 0
      approvereturnval = parseFloat(approvereturnval) / 10 ** 18;

      if (parseFloat(approvereturnval) <= 0) {
        return {
          value: {},
          status: false,
          approveAmt: 0
        };
      }
      else {
        return {
          value: "Approved success",
          status: true,
          approveAmt: approvereturnval
        };
      }
    } else {
      return {
        value: "Web3 Error",
        status: false,
        approveAmt: 0
      };
    }
  } catch (err) {
    var errMsg = (err && err.message) ? err.message.toString() : "";
    var pos = errMsg.search("User denied");
    var pos1 = errMsg.search("funds");
    var pos2 = errMsg.search("gas required exceeds allowance");
    var pos3 = errMsg.search("max fee per gas less than block base fee")
    if (pos >= 0) {
      return {
        value: "Cancelled",
        status: false,
      };
    } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
      return {
        value: "Please make sure you have enough gas fee!",
        status: false,
      };
    }
    else {
      return {
        value: "Failed",
        status: false,
      };
    }
  }
}

export async function stake(pid, amount, LPaddress, lpBal, lpsym) {

  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var gasPrice = await web3.eth.getGasPrice();
      var Contract = new web3.eth.Contract(LPABI, LPaddress);
      var bal = await Contract.methods.balanceOf(address).call();
      var amo = await convertToWei(amount, 18);
      amo = amo.toString();

      var checkallowance = await Contract.methods.allowance(
        address,
        config.MasterChef
      ).call();
      checkallowance = parseFloat(checkallowance) / 1e18;
      checkallowance = parseFloat(checkallowance).toFixed(config.toFixed);



      if (parseFloat(lpBal) === parseFloat(amount)) {
        amo = JSBI.BigInt(await Contract.methods.balanceOf(address).call());
        amo = String(amo);
      }

      if (parseFloat(bal) < parseFloat(amo)) {

        return {
          value: "Your balance is too low",
          status: false,
        };
      }

      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);
      //var amo = amount * 1000000000000000000;


      try {
        if (parseFloat(checkallowance) < parseFloat(amount)) {

          var Contract = new web3.eth.Contract(LPABI, LPaddress);
          var approveAmt = (100000000) * (10 ** 18);
          approveAmt = await convert(approveAmt);
          var estimateGs = await Contract.methods
            .approve(config.MasterChef, approveAmt.toString())
            .estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGs = estimateGs + 100000;

          await Contract.methods
            .approve(config.MasterChef, approveAmt.toString())
            .send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          var estimateGass = await ContractM.methods.deposit(pid, amo).estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGas = estimateGass + 100000;
          var resp = await ContractM.methods.deposit(pid, amo).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          if (resp && resp.status && resp.status == true && resp.events && resp.events.Deposit && resp.events.Deposit.returnValues && resp.events.Deposit.returnValues.amount) {
            var depositfee = await ContractM.methods.poolInfo(pid).call()

            var data = {
              amount: resp.events.Deposit.returnValues.amount / 1000000000000000000,
              fee: depositfee && depositfee.depositFeeBP ? depositfee.depositFeeBP / 100 : 0,
              walletAddress: address,
              lpAddress: depositfee && depositfee.lpToken ? depositfee.lpToken : '',
              txHash: resp.transactionHash,
              type: 'stake',
              lpSymbol: lpsym
            }
            await farmcommission(data)
            console.log(data, 'data')
          }
        }
        else {
          var estimateGas = await ContractM.methods.deposit(pid, amo).estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGas = estimateGas + 100000;
          var resp = await ContractM.methods.deposit(pid, amo).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          if (resp && resp.status && resp.status == true && resp.events && resp.events.Deposit && resp.events.Deposit.returnValues && resp.events.Deposit.returnValues.amount) {
            var depositfee = await ContractM.methods.poolInfo(pid).call()
            var data = {
              amount: resp.events.Deposit.returnValues.amount / 1000000000000000000,
              fee: depositfee && depositfee.depositFeeBP ? depositfee.depositFeeBP / 100 : 0,
              walletAddress: address,
              lpAddress: depositfee && depositfee.lpToken ? depositfee.lpToken : '',
              txHash: resp.transactionHash,
              type: 'stake',
              lpSymbol: lpsym
            }
            await farmcommission(data)
            console.log(data, 'data')
          }
        }
      } catch (err) {
        console.log(err, '11errerrerrerrerr')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds allowance");
        var pos3 = errMsg.search("max fee per gas less than block base fee")
        if (pos >= 0) {
          return {
            value: "Cancelled",
            status: false,
          };
        } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
          return {
            value: "Please make sure you have enough gas fee!",
            status: false,
          };
        }
        else {
          return {
            value: "Failed",
            status: false,
          };
        }
      }

      return {
        value: "Staked success",
        status: true,
      };
    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    console.log(err, 'errerrerrerrerr')
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function stakePool(pid, amount, LPaddress, lpBal, lpsym) {

  var get = await connection();
  try {
    if (get && get.web3) {

      var web3 = get.web3;
      var address = get.address;
      var gasPrice = await web3.eth.getGasPrice();
      var Contract = new web3.eth.Contract(BEP20, LPaddress);
      var bal = await Contract.methods.balanceOf(address).call();
      var checkallowance = await Contract.methods.allowance(
        address,
        config.MasterChef
      ).call();
      checkallowance = parseFloat(checkallowance) / 1e18;
      checkallowance = parseFloat(checkallowance).toFixed(config.toFixed);
      var decimals = await Contract.methods.decimals().call();

      if (parseFloat(bal) < parseFloat(amount)) {
        return {
          value: "Your balance is too low",
          status: false,
        };
      }

      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);
      var amo = await convertToWei(amount, 18);
      amo = amo.toString()

      if (parseFloat(lpBal) === parseFloat(amount)) {
        amo = JSBI.BigInt(await Contract.methods.balanceOf(address).call());
        amo = String(amo);
      }
      try {
        if (parseFloat(checkallowance) < parseFloat(amount)) {

          var Contract = new web3.eth.Contract(BEP20, LPaddress);

          var approveAmt = (100000000) * (10 ** 18);
          approveAmt = await convert(approveAmt);
          var estim = await Contract.methods
            .approve(config.MasterChef, approveAmt.toString())
            .estimateGas({ from: address, gasPrice: gasPrice });
          var estimGas = estim + 100000;

          await Contract.methods
            .approve(config.MasterChef, approveAmt.toString())
            .send({ from: address, gasPrice: gasPrice, gasLimit: estimGas });

          var estimateGass = await ContractM.methods.deposit(pid, amo).estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGas = estimateGass + 100000;
          var resp = await ContractM.methods.deposit(pid, amo).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          if (resp && resp.status && resp.status == true && resp.events && resp.events.Deposit && resp.events.Deposit.returnValues && resp.events.Deposit.returnValues.amount) {
            var depositfee = await ContractM.methods.poolInfo(pid).call()
            var data = {
              amount: resp.events.Deposit.returnValues.amount / 10 ** decimals,
              fee: depositfee && depositfee.depositFeeBP ? depositfee.depositFeeBP / 100 : 0,
              walletAddress: address,
              lpAddress: depositfee && depositfee.lpToken ? depositfee.lpToken : '',
              txHash: resp.transactionHash,
              type: 'stake',
              lpSymbol: lpsym
            }
            await farmcommission(data)
          }
        }
        else {
          var estimateGass = await ContractM.methods.deposit(pid, amo).estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGas = estimateGass + 100000;
          var resp = await ContractM.methods.deposit(pid, amo).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          if (resp && resp.status && resp.status == true && resp.events && resp.events.Deposit && resp.events.Deposit.returnValues && resp.events.Deposit.returnValues.amount) {
            var depositfee = await ContractM.methods.poolInfo(pid).call()
            var data = {
              amount: resp.events.Deposit.returnValues.amount / 10 ** decimals,
              fee: depositfee && depositfee.depositFeeBP ? depositfee.depositFeeBP / 100 : 0,
              walletAddress: address,
              lpAddress: depositfee && depositfee.lpToken ? depositfee.lpToken : '',
              txHash: resp.transactionHash,
              type: 'stake',
              lpSymbol: lpsym
            }
            await farmcommission(data)
          }
        }
      } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds allowance");
        var pos3 = errMsg.search("max fee per gas less than block base fee")
        if (pos >= 0) {
          return {
            value: "Cancelled",
            status: false,
          };
        } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
          return {
            value: "Please make sure you have enough gas fee!",
            status: false,
          };
        }
        else {
          return {
            value: "Failed",
            status: false,
          };
        }
      }


      return {
        value: "Staked success",
        status: true,
      };
    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    console.log('err33333: ', err);
    return {
      value: "Ero",
      status: false,
    };
  }
}


export async function harverstpool(pid, type) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var gasPrice = await web3.eth.getGasPrice();
      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);
      var bal = await ContractM.methods.pendingEthax(pid, address).call();
      bal = await convert(bal)

      if (bal === 0 || bal === "0" || parseFloat(bal) < 0) {
        return {
          value: "Insufficient reward earned",
          status: false,
        };
      }

      if (type == 'harvest') {
        try {
          var estimateGas = await ContractM.methods.deposit(pid, 0).estimateGas({ from: address, gasPrice: gasPrice });
          var estimateGas = estimateGas + 100000
          let result = await ContractM.methods
            .deposit(pid, 0)
            .send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
          var tx = (result && result.transactionHash) ?
            result.transactionHash : "";
          return {
            value: "Withdraw Successful",
            status: true,
            txhash: tx
          };
        }
        catch (err) {
          var errMsg = (err && err.message) ? err.message.toString() : "";
          var pos = errMsg.search("User denied");
          var pos1 = errMsg.search("funds");
          var pos2 = errMsg.search("gas required exceeds allowance");
          var pos3 = errMsg.search("max fee per gas less than block base fee")
          if (pos >= 0) {
            return {
              value: "Cancelled",
              status: false,
            };
          } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
            return {
              value: "Please make sure you have enough gas fee!",
              status: false,
            };
          }
          else {
            return {
              value: "Failed",
              status: false,
            };
          }

        }
      }
      else {
        try {
          var Contract = new web3.eth.Contract(BEP20, config.DopamineToken);
          var checkallowance = await Contract.methods.allowance(
            address,
            config.MasterChef
          ).call();
          checkallowance = parseFloat(checkallowance) / 1e18;
          // checkallowance = parseFloat(checkallowance).toFixed(config.toFixed);
          var balance = parseFloat(bal) / 1e18

          if (parseFloat(checkallowance) < parseFloat(balance)) {
            var approveAmt = (100000000) * (10 ** 18);
            approveAmt = await convert(approveAmt);

            await Contract.methods
              .approve(config.MasterChef, approveAmt.toString())
              .send({ from: address });

            var estimateGas = await ContractM.methods.deposit(pid, bal).estimateGas({ from: address, gasPrice: gasPrice });
            var estimateGas = estimateGas + 100000
            await ContractM.methods.deposit(pid, bal).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
            return {
              value: "Deposit Successful",
              status: true,
            };
          }
          else {
            var estimateGas = await ContractM.methods.deposit(pid, bal).estimateGas({ from: address, gasPrice: gasPrice });
            var estimateGas = estimateGas + 100000
            await ContractM.methods.deposit(pid, bal).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
            return {
              value: "Deposit Successful",
              status: true,
            };
          }


        } catch (err) {
          var errMsg = (err && err.message) ? err.message.toString() : "";
          var pos = errMsg.search("User denied");
          var pos1 = errMsg.search("funds");
          var pos2 = errMsg.search("gas required exceeds allowance");
          var pos3 = errMsg.search("max fee per gas less than block base fee")
          if (pos >= 0) {
            return {
              value: "Cancelled",
              status: false,
            };
          } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
            return {
              value: "Please make sure you have enough gas fee!",
              status: false,
            };
          }
          else {
            return {
              value: "Failed",
              status: false,
            };
          }

        }
      }

    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    console.log('errssswews: ', err);
    var errMsg = (err && err.message) ? err.message.toString() : "";
    var pos = errMsg.search("User denied");
    var pos1 = errMsg.search("funds");
    var pos2 = errMsg.search("gas required exceeds allowance");
    var pos3 = errMsg.search("max fee per gas less than block base fee")
    if (pos >= 0) {
      return {
        value: "Cancelled",
        status: false,
      };
    } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
      return {
        value: "Please make sure you have enough gas fee!",
        status: false,
      };
    }
    else {
      return {
        value: "Failed",
        status: false,
      };
    }
  }
}


export async function harverst(pid) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var gasPrice = await web3.eth.getGasPrice();
      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);

      var bal = await ContractM.methods.pendingEthax(pid, address).call();
      console.log('baaaaaaaaaaaaaal: ', bal);

      if (bal === 0 || bal === "0" || parseFloat(bal) < 0) {
        return {
          value: "Insufficient reward earned",
          status: false,
        };
      }
      var estimateGas = await ContractM.methods
        .deposit(pid, 0).estimateGas({ from: address, gasPrice: gasPrice });
      var estimateGas = estimateGas + 100000;
      let result = await ContractM.methods
        .deposit(pid, 0)
        .send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });

      var tx = (result && result.transactionHash) ?
        result.transactionHash : "";
      console.log(tx, 'txxxxxxxxxxxxxxxxxxxx')
      return {
        value: "Withdraw Successful",
        status: true,
        txhash: tx
      };
    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    var errMsg = (err && err.message) ? err.message.toString() : "";
    var pos = errMsg.search("User denied");
    var pos1 = errMsg.search("funds");
    var pos2 = errMsg.search("gas required exceeds allowance");
    var pos3 = errMsg.search("max fee per gas less than block base fee")
    if (pos >= 0) {
      return {
        value: "Cancelled",
        status: false,
      };
    } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
      return {
        value: "Please make sure you have enough gas fee!",
        status: false,
      };
    }
    else {
      return {
        value: "Failed",
        status: false,
      };
    }
  }
}

export async function getreward(details) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);
      var pollArray = [];
      if (details) {
        for (var i in details) {
          var ran = details[i].pid;
          var bal = await ContractM.methods.pendingEthax(ran, address).call();
          pollArray.push({
            bal: bal,
            pid: ran
          });
        }
      }

      return {
        value: pollArray,
        status: true,
      };
    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function unstake(amount, pid, unstakeBal, lpaddress, lpsymbol) {
  console.log('amounsssspsymbol: ', amount, pid, unstakeBal, lpaddress, lpsymbol);
  var get = await connection();
  try {

    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var ContractM = new web3.eth.Contract(MasterChef, config.MasterChef);
      var gasPrice = await web3.eth.getGasPrice();
      var stakeBalance = await ContractM.methods.userInfo(pid, address).call();
      var stakeBal = (stakeBalance && stakeBalance[0]) ? parseFloat(stakeBalance[0]) / 10 ** 18 : 0

      if (unstakeBal < amount) {
        return {
          value: "Invalid Amount",
          status: true,
        };
      }
      var amo = await convertToWei(amount, 18);
      amo = amo.toString();
      if (parseFloat(amount) === parseFloat(unstakeBal)) {
        amo = JSBI.BigInt(stakeBalance[0]);
        amo = String(amo);
      }
      try {
        amo = await convert(amo);
        amo = amo.toString();
        var check = amo.split('.')
        if (check && (check.length === 2)) {
          amo = check[0]
        }
        else {
          amo = amo;
        }
        var estimateGass = await ContractM.methods.withdraw(pid, amo).estimateGas({ from: address, gasPrice: gasPrice });
        var estimateGas = estimateGass + 100000;

        var resp = await ContractM.methods.withdraw(pid, amo).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });
        var data = {
          amount: amount,
          fee: 0,
          walletAddress: address,
          lpAddress: lpaddress,
          txHash: (resp && resp.transactionHash) ? resp.transactionHash : "",
          type: 'unstake',
          lpSymbol: lpsymbol
        }
        console.log(data, 'data9999')
        await farmcommission(data)


      } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds allowance");
        var pos3 = errMsg.search("max fee per gas less than block base fee")
        if (pos >= 0) {
          return {
            value: "Cancelled",
            status: false,
          };
        } else if (pos1 >= 0 || pos2 >= 0 || pos3 >= 0) {
          return {
            value: "Please make sure you have enough gas fee!",
            status: false,
          };
        }
        else {
          return {
            value: "Failed",
            status: false,
          };
        }
      }


      return {
        value: "Staked success",
        status: true,
      };
    } else {
      return {
        value: "Web3 Error",
        status: false,
      };
    }
  } catch (err) {
    console.log('err: ', err);
    return {
      value: "Ero",
      status: false,
    };
  }
}

export async function getStakeUnstakeBalance(pid, lpaddress) {

  var get = await connection();
  // return;
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }
    const multicall = new Multicall({
      web3Instance: web3,
    });
    var masterChefContract = [
      {
        reference: "poolInfo",
        contractAddress: config.MasterChef,
        abi: MasterChef,
        calls: [

          {
            reference: "userInfo",
            methodName: "userInfo",
            methodParameters: [pid, address],
          },
        ]
      },
      {
        reference: "LPtokengetBalanceof",
        contractAddress: lpaddress,
        abi: LPABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address],
          },
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.MasterChef],
          },
        ]
      }
    ];


    const poolresults = await multicall.call(masterChefContract);
    var stakeBal = await getFormatMulticall(poolresults, "poolInfo", 0);
    var lpBal = await getFormatMulticall(poolresults, "LPtokengetBalanceof", 0);
    var totalSupply = await getFormatMulticall(poolresults, "LPtokengetBalanceof", 1);

    stakeBal = (stakeBal && stakeBal[0] && stakeBal[0].hex) ? await divideDecimal(parseInt(stakeBal[0].hex), 18) : 0;
    lpBal = (lpBal && lpBal.hex) ? await divideDecimal(parseInt(lpBal.hex), 18) : 0;
    totalSupply = (totalSupply && totalSupply.hex) ? await divideDecimal(parseInt(totalSupply.hex), 18) : 0;

    return {
      stakeBal: parseFloat(stakeBal),
      lpBal: parseFloat(lpBal),
      totalSupply: parseFloat(totalSupply),
    }

  } catch (err) {
    console.log(err, 'errerrerrerrerrerrerr')
    return {
      stakeBal: 0,
      lpBal: 0
    }
  }

}

export async function getEthaxHomeDetails() {

  var get = await connection();
  // return;
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }
    const multicall = new Multicall({
      web3Instance: web3,
    });
    var masterChefContract = [
      {
        reference: "poolInfo",
        contractAddress: config.MasterChef,
        abi: MasterChef,
        calls: [

          {
            reference: "userInfo",
            methodName: "userInfo",
            methodParameters: [0, address],
          },
          {
            reference: "userInfo1",
            methodName: "userInfo",
            methodParameters: [1, address],
          },
          {
            reference: "userInfo2",
            methodName: "userInfo",
            methodParameters: [2, address],
          },
          {
            reference: "pendingEthax",
            methodName: "pendingEthax",
            methodParameters: [0, address],
          },
          {
            reference: "pendingEthax1",
            methodName: "pendingEthax",
            methodParameters: [1, address],
          },
          {
            reference: "pendingEthax2",
            methodName: "pendingEthax",
            methodParameters: [2, address],
          }
        ]
      },

      {
        reference: "balanceOfETHLP",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          }
        ],
      },
      {
        reference: "balanceOfBusdLP",
        contractAddress: config.BUSD,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          }
        ],
      },
      {
        reference: "balanceOfUser",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [address],
          }
        ],
      },
    ];


    const poolresults = await multicall.call(masterChefContract);


    var EthlpBAl = await getFormatMulticall(poolresults, "balanceOfETHLP", 0);
    EthlpBAl = new BigNumber(EthlpBAl.hex, 16);
    EthlpBAl = EthlpBAl.toString(10);
    EthlpBAl = EthlpBAl / 1e18;


    var balanceOfUser = await getFormatMulticall(poolresults, "balanceOfUser", 0);
    balanceOfUser = new BigNumber(balanceOfUser.hex, 16);
    balanceOfUser = balanceOfUser.toString(10);
    balanceOfUser = balanceOfUser / 1e18;

    var BusdLpbal = await getFormatMulticall(poolresults, "balanceOfBusdLP", 0);

    BusdLpbal = new BigNumber(BusdLpbal.hex, 16);
    BusdLpbal = BusdLpbal.toString(10);
    BusdLpbal = BusdLpbal / 1e18;

    var busdprice = BusdLpbal / EthlpBAl;

    let lockeddollarvalue = parseFloat(balanceOfUser).toFixed(3) * parseFloat(busdprice).toFixed(3)

    var stakeBal = await getFormatMulticall(poolresults, "poolInfo", 0);
    var stakeBalone = await getFormatMulticall(poolresults, "poolInfo", 1);
    var stakeBaltwo = await getFormatMulticall(poolresults, "poolInfo", 2);

    var pendingEthax = await getFormatMulticall(poolresults, "poolInfo", 3);
    var pendingEthaxOne = await getFormatMulticall(poolresults, "poolInfo", 4);
    var pendingEthaxTwo = await getFormatMulticall(poolresults, "poolInfo", 5);

    pendingEthax = (pendingEthax && pendingEthax && pendingEthax.hex) ? await divideDecimal(parseInt(pendingEthax.hex), 18) : 0;
    pendingEthaxOne = (pendingEthaxOne && pendingEthaxOne && pendingEthaxOne.hex) ? await divideDecimal(parseInt(pendingEthaxOne.hex), 18) : 0;
    pendingEthaxTwo = (pendingEthaxTwo && pendingEthaxTwo && pendingEthaxTwo.hex) ? await divideDecimal(parseInt(pendingEthaxTwo.hex), 18) : 0;

    var finalpendingValue = parseFloat(pendingEthax) + parseFloat(pendingEthaxOne) + parseFloat(pendingEthaxTwo);
    var totalpendingValue = parseFloat(busdprice) * parseFloat(finalpendingValue)


    stakeBal = (stakeBal && stakeBal[0] && stakeBal[0].hex) ? await divideDecimal(parseInt(stakeBal[0].hex), 18) : 0;
    stakeBalone = (stakeBalone && stakeBalone[0] && stakeBalone[0].hex) ? await divideDecimal(parseInt(stakeBalone[0].hex), 18) : 0;
    stakeBaltwo = (stakeBaltwo && stakeBaltwo[0] && stakeBaltwo[0].hex) ? await divideDecimal(parseInt(stakeBaltwo[0].hex), 18) : 0;
    var lockedValue = parseFloat(stakeBal) + parseFloat(stakeBalone) + parseFloat(stakeBaltwo)
    lockedValue = parseFloat(busdprice) * parseFloat(lockedValue)
console.log( {
  lockedValue: parseFloat(lockedValue),
  finalpendingValue: parseFloat(finalpendingValue),
  totalpendingValue: parseFloat(totalpendingValue),
  balanceOfUser: parseFloat(balanceOfUser),
  lockeddollarvalue: parseFloat(lockeddollarvalue)
},'eeeeeeeeee1111111tttttttt')
    return {
      lockedValue: parseFloat(lockedValue),
      finalpendingValue: parseFloat(finalpendingValue),
      totalpendingValue: parseFloat(totalpendingValue),
      balanceOfUser: parseFloat(balanceOfUser),
      lockeddollarvalue: parseFloat(lockeddollarvalue)
    }

  } catch (err) {
    console.log(err, 'errerrerrerrerrerrerr')
    return {
      lockedValue: 0,
      finalpendingValue: 0,
      totalpendingValue: 0,
      balanceOfUser: 0,
      lockeddollarvalue: 0
    }
  }

}

export const BIG_TEN = new BigNumber(10)

export async function getTotalValueLocked() {
  var get = await connection();
  // return;
  try {
    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }
    const multicall = new Multicall({
      web3Instance: web3,
    });

    var { result } = await getFormData()
    const datas = await Promise.all(result && result && result.length > 0
      && result.map(async (farmConfig) => {
      // const datas = await Promise.all(farmsconfig && farmsconfig.tokens && farmsconfig.tokens.length > 0
      // && farmsconfig.tokens.map(async (farmConfig) => {
        console.log('farmConddddddddddddfig: ', farmConfig);
        // const lpAdress = farmConfig.lpAddresses[config.NetworkId];
        const lpAdress = farmConfig.lpAddresses;
        var masterChefContract = [
          {
            reference: "InfoOne",
            contractAddress: farmConfig.tokenAddresses,
            abi: ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [lpAdress]
              }
            ]
          },

          {
            reference: "InfoTwo",
            contractAddress: farmConfig.quoteTokenAdresses,
            abi: ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [lpAdress]
              }
            ]
          },

          {
            reference: "InfoThree",
            contractAddress: lpAdress,
            abi: LPABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [config.MasterChef]
              }
            ]
          },
          {
            reference: "InfoFour",
            contractAddress: lpAdress,
            abi: LPABI,
            calls: [
              {
                reference: "totalSupply",
                methodName: "totalSupply",
              }
            ]
          },

          {
            reference: "InfoFive",
            contractAddress: farmConfig.tokenAddresses,
            abi: ABI,
            calls: [
              {
                reference: "decimals",
                methodName: "decimals",
              }
            ]
          },
          {
            reference: "InfoSix",
            contractAddress: farmConfig.quoteTokenAdresses,
            abi: ABI,
            calls: [
              {
                reference: "decimals",
                methodName: "decimals",
              }
            ]
          },
          {
            reference: "Infoseven",
            contractAddress: config.MasterChef,
            abi: MasterChef,
            calls: [
              {
                reference: "poolInfo",
                methodName: "poolInfo",
                methodParameters: [farmConfig.pid]

              }
            ]
          }
        ];
        const poolresults = await multicall.call(masterChefContract);

        let tokenBalanceLP = await getFormatMulticall(poolresults, "InfoOne", 0);
        let quoteTokenBalanceLP = await getFormatMulticall(poolresults, "InfoTwo", 0);
        let lpTokenBalanceMC = await getFormatMulticall(poolresults, "InfoThree", 0);
        let lpTotalSupply = await getFormatMulticall(poolresults, "InfoFour", 0);
        let tokenDecimals = await getFormatMulticall(poolresults, "InfoFive", 0);
        let quoteTokenDecimals = await getFormatMulticall(poolresults, "InfoSix", 0);
        let info = await getFormatMulticall(poolresults, "Infoseven", 0);

        const aprValue = (info && info[4] && info[4].hex) ?
          new BigNumber(info[4].hex) / (100) : new BigNumber(0)

        tokenBalanceLP = new BigNumber(tokenBalanceLP.hex, 16);
        tokenBalanceLP = tokenBalanceLP.toString(10);

        quoteTokenBalanceLP = new BigNumber(quoteTokenBalanceLP.hex, 16);
        quoteTokenBalanceLP = quoteTokenBalanceLP.toString(10);
        console.log('quoteTokenBalanceeeeeeeeeLP: ', quoteTokenBalanceLP);

        lpTokenBalanceMC = new BigNumber(lpTokenBalanceMC.hex, 16);
        lpTokenBalanceMC = lpTokenBalanceMC.toString(10);

        lpTotalSupply = new BigNumber(lpTotalSupply.hex, 16);
        lpTotalSupply = lpTotalSupply.toString(10);


        const lpTokenRatio = parseFloat(lpTokenBalanceMC) / parseFloat(lpTotalSupply)
        const tokenAmountTotal = parseFloat(tokenBalanceLP) / (10 ** 18)
        const quoteTokenAmountTotal = parseFloat(quoteTokenBalanceLP) / (10 ** (18))
        console.log('parseFlssskenBalanceLP: ', parseFloat(quoteTokenBalanceLP))
        const quoteTokenAmountMc = (quoteTokenAmountTotal) * (lpTokenRatio)
        console.log(lpTokenRatio, 'qqqqquoteTokenAmountMc: ', quoteTokenAmountMc);
        // const lpTotalInQuoteToken = quoteTokenAmountMc * (new BigNumber(2))
        const lpTotalInQuoteToken = quoteTokenAmountMc !== 0 ? quoteTokenAmountMc * new BigNumber(2) : 0;

        console.log('lpTotalInQuuuuoteToken: ', lpTotalInQuoteToken);

        console.log({
          tokenAmountTotal: tokenAmountTotal,
          lpTotalSupply: (lpTotalSupply),
          lpTotalInQuoteToken: lpTotalInQuoteToken,
          tokenPriceVsQuote: quoteTokenAmountTotal / tokenAmountTotal,
          poolWeight: "100",
          multiplier: '1X',
          pid: farmConfig.pid,
          token: farmConfig.tokenAddresses,
          quoteToken: farmConfig.quoteTokenAdresses,
          quoteTokenSymbol: farmConfig.quoteTokenSymbol,
          tokensymbol: farmConfig.tokenSymbol,
          lpSymbol: farmConfig.lpSymbol,
          aprPercenatage: aprValue
        }, 'uuuuuuuuuuuuuuuuurrrrrrrrrrree')
        return {
          tokenAmountTotal: tokenAmountTotal,
          lpTotalSupply: (lpTotalSupply),
          lpTotalInQuoteToken: lpTotalInQuoteToken,
          tokenPriceVsQuote: quoteTokenAmountTotal / tokenAmountTotal,
          poolWeight: "100",
          multiplier: '1X',
          pid: farmConfig.pid,
          token: farmConfig.tokenAddresses,
          quoteToken: farmConfig.quoteTokenAdresses,
          quoteTokenSymbol: farmConfig.quoteTokenSymbol,
          // tokensymbol: farmConfig.tokensymbol,
          tokensymbol: farmConfig.tokenSymbol,
          lpSymbol: farmConfig.lpSymbol,
          aprPercenatage: aprValue
        }
      }))
    return datas
  } catch (err) {
    console.log(err, 'errerrerrerdddrerrerrerr')
    return {
      tokenAmountTotal: 0,
      lpTotalSupply: 0,
      lpTotalInQuoteToken: 0,
      tokenPriceVsQuote: 0,
      poolWeight: "0",
      multiplier: '0X',
      pid: 0,
      token: 0,
      quoteToken: 0,
      quoteTokenSymbol: 0,
      tokensymbol: 0,
      lpSymbol: 0,
      aprPercenatage: 0
    }
  }
}



export const fetchPoolsTotalStaking = async () => {
  var get = await connection();
  // return;
  try {
    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }
    const multicall = new Multicall({
      web3Instance: web3,
    });


    var { result } = await getPoolData()
    const nonBnbPools = result?.filter((p) => p.lpSymbol !== 'BNB')
    console.log('nonBnbPools: ', nonBnbPools);
    const bnbPool = result?.filter((p) => p.lpSymbol === 'BNB')
    console.log('bnbPool34343434: ', bnbPool);

    // const nonBnbPools = poolsTokenlists && poolsTokenlists.tokens.filter((p) => p.lpSymbol !== 'BNB')
    // console.log('nonBnbPools: ', nonBnbPools);
    //  const bnbPool = poolsTokenlists && poolsTokenlists.tokens.filter((p) => p.lpSymbol === 'BNB')


    const callsBnbPools = await Promise.all(bnbPool && bnbPool.map(async (Configg) => {

      var masterChefContract = [
        {
          reference: "balanceOnee",
          contractAddress: config.WBNB,
          abi: ABI,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [config.MasterChef]
            }
          ]
        },
      ];
      const poolresults = await multicall.call(masterChefContract);

      var balanceTwo = await getFormatMulticall(poolresults, "balanceOnee", 0);
      balanceTwo = new BigNumber(balanceTwo.hex, 16);
      balanceTwo = balanceTwo.toString(10);

      // return {
      //   ...nonBnbPools.map((p, index) => ({
      //     sousId: p.sousId,
      //     totalStaked: balanceTwo,
      //   })),
      // }
      return balanceTwo
    }))



    const callsNonBnbPools = await Promise.all(nonBnbPools && nonBnbPools.map(async (farmConfig) => {
      console.log('farmConfig: ', farmConfig);

      var masterChefContract = [
        {
          reference: "balance",
          // contractAddress: farmConfig.stakingToken,
          contractAddress: farmConfig.tokenAddresses,
          abi: ABI,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [config.MasterChef]
            }
          ]
        },


      ];
      const poolresults = await multicall.call(masterChefContract);

      var balanceeone = await getFormatMulticall(poolresults, "balance", 0);
      balanceeone = new BigNumber(balanceeone.hex, 16);
      balanceeone = balanceeone.toString(10);

      return balanceeone
      // return [
      //   ...bnbPool.map((p, index) => ({
      //     sousId: p.sousId,
      //     totalStaked: balanceeone,
      //   })),
      // ]
    }))

    return {
      callsBnbPools, callsNonBnbPools
    }
  }
  catch (err) {
    console.log(err, 'errerrerrderdddrerrerrerr')
    return {
      balanceeone: 0,
      balanceTwo: 0
    }
  }



}


// export async function getTotalValueLocked() {
//   var get = await connection();
//   // return;
//   try {
//     var web3 = get.web3;
//     if (web3 === "" || !web3) {
//       web3 = new Web3(config.netWorkUrl);
//     }
//     var address = zeroAddr;
//     if (get.address && get.address !== "") {
//       address = get.address;
//     }
//     const multicall = new Multicall({
//       web3Instance: web3,
//     });
//     const datas = await Promise.all(farmsconfig && farmsconfig.tokens && farmsconfig.tokens.length > 0
//       && farmsconfig.tokens.map(async (farmConfig) => {
//         const lpAdress = farmConfig.lpAddresses[config.NetworkId];
//         var masterChefContract = [
//           {
//             reference: "InfoOne",
//             contractAddress: farmConfig.tokenAddresses[config.NetworkId],
//             abi: ABI,
//             calls: [
//               {
//                 reference: "balanceOf",
//                 methodName: "balanceOf",
//                 methodParameters: [lpAdress]
//               }
//             ]
//           },
//           {
//             reference: "InfoTwo",
//             contractAddress: farmConfig.quoteTokenAdresses,
//             abi: ABI,
//             calls: [
//               {
//                 reference: "balanceOf",
//                 methodName: "balanceOf",
//                 methodParameters: [lpAdress]
//               }
//             ]
//           },
//           {
//             reference: "InfoThree",
//             contractAddress: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[config.NetworkId] : lpAdress,
//             abi: farmConfig.isTokenOnly ? ABI : LPABI,
//             calls: [
//               {
//                 reference: "balanceOf",
//                 methodName: "balanceOf",
//                 methodParameters: [config.MasterChef]
//               }
//             ]
//           },
//           {
//             reference: "InfoFour",
//             contractAddress: lpAdress,
//             abi: LPABI,
//             calls: [
//               {
//                 reference: "totalSupply",
//                 methodName: "totalSupply",
//               }
//             ]
//           },
//           {
//             reference: "InfoFive",
//             contractAddress: farmConfig.tokenAddresses[config.NetworkId],
//             abi: ABI,
//             calls: [
//               {
//                 reference: "decimals",
//                 methodName: "decimals",
//               }
//             ]
//           },
//           {
//             reference: "InfoSix",
//             contractAddress: farmConfig.quoteTokenAdresses,
//             abi: ABI,
//             calls: [
//               {
//                 reference: "decimals",
//                 methodName: "decimals",
//               }
//             ]
//           }
//         ];
//         const poolresults = await multicall.call(masterChefContract);

//         let tokenBalanceLP = await getFormatMulticall(poolresults, "InfoOne", 0);
//         let quoteTokenBlanceLP = await getFormatMulticall(poolresults, "InfoTwo", 0);
//         let lpTokenBalanceMC = await getFormatMulticall(poolresults, "InfoThree", 0);
//         let lpTotalSupply = await getFormatMulticall(poolresults, "InfoFour", 0);
//         let tokenDecimals = await getFormatMulticall(poolresults, "InfoFive", 0);
//         let quoteTokenDecimals = await getFormatMulticall(poolresults, "InfoSix", 0);

//         let tokenAmount;
//         let lpTotalInQuoteToken;
//         let tokenPriceVsQuote;

//         if (farmConfig.isTokenOnly) {
//           tokenAmount = new BigNumber(lpTokenBalanceMC.hex, 16);

//           tokenAmount = tokenAmount.toString(10);
//           // tokenAmount = parseFloat(tokenAmount) / tokenDecimals
//           tokenAmount = parseFloat(tokenAmount) / 1e18


//           if (farmConfig.tokenSymbol === "BUSD" && farmConfig.quoteTokenSymbol === "BUSD") {
//             tokenPriceVsQuote = new BigNumber(1);

//           } else {

//             //  tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));

//             tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP.hex, 16);
//             tokenPriceVsQuote = tokenPriceVsQuote.toString(10);

//             tokenBalanceLP = new BigNumber(tokenBalanceLP.hex, 16);
//             tokenBalanceLP = tokenBalanceLP.toString(10);

//             tokenPriceVsQuote = parseFloat(tokenPriceVsQuote) / parseFloat(tokenBalanceLP)
//           }


//           lpTotalInQuoteToken = parseFloat(tokenAmount) * parseFloat(tokenPriceVsQuote);

//         } else {
//           var lpTokenRatioo = new BigNumber(lpTokenBalanceMC.hex, 16);
//           lpTokenRatioo = lpTokenRatioo.toString(10);

//           let LPtotalsupply = new BigNumber(lpTotalSupply.hex, 16)
//           LPtotalsupply = LPtotalsupply.toString(10);


//           const lpTokenRatio = lpTokenRatioo / LPtotalsupply;

//           // Total value in staking in quote token value
//           lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP.hex)
//             .div(new BigNumber(10).pow(18))
//             .times(new BigNumber(2))
//             .times(lpTokenRatio)


//           // lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP.hex, 16);
//           // lpTotalInQuoteToken = lpTotalInQuoteToken.toString(10);
//           // lpTotalInQuoteToken = lpTotalInQuoteToken / 1e18;
//           // lpTotalInQuoteToken = lpTotalInQuoteToken * new BigNumber(2) * lpTokenRatio;

//           tokenAmount = new BigNumber(tokenBalanceLP.hex, 16);
//           tokenAmount = tokenAmount.toString(10);
//           tokenAmount = (tokenAmount / 1e18) * lpTokenRatio;

//           var quoteTokenBlanceLPP = new BigNumber(quoteTokenBlanceLP.hex, 16);
//           quoteTokenBlanceLPP = quoteTokenBlanceLPP.toString(10);
//           quoteTokenBlanceLPP = quoteTokenBlanceLPP / 1e18;

//           const quoteTokenAmount = quoteTokenBlanceLPP * lpTokenRatio


//           if (parseFloat(tokenAmount) > 0) {
//             tokenPriceVsQuote = quoteTokenAmount / (tokenAmount);
//           } else {
//             tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP.hex, 16);
//             tokenPriceVsQuote = tokenPriceVsQuote.toString(10);

//             let tokenBalanceLP = new BigNumber(tokenBalanceLP.hex, 16);
//             tokenBalanceLP = tokenBalanceLP.toString(10);
//             tokenPriceVsQuote = tokenPriceVsQuote / tokenBalanceLP
//           }
//         }
//         console.log({
//           tokenAmount: tokenAmount,
//           // quoteTokenAmount: quoteTokenAmount,
//           lpTotalInQuoteToken: parseFloat(lpTotalInQuoteToken),
//           tokenPriceVsQuote: tokenPriceVsQuote,
//           quoteTokenSymbol: farmConfig.quoteTokenSymbol
//         }, 'jjjjjjjjjjjjjjjjjjjjjkkkk')
//         return {
//           // ...farmConfig,
//           tokenAmount: tokenAmount,
//           // quoteTokenAmount: quoteTokenAmount,
//           lpTotalInQuoteToken: lpTotalInQuoteToken,
//           tokenPriceVsQuote: tokenPriceVsQuote,
//           quoteTokenSymbol: farmConfig.quoteTokenSymbol

//         }
//       }))

//     return datas





//   } catch (err) {
//     console.log(err, 'errerrerrerrerrerrerr')
//     return {
//       tokenAmount: 0,
//       lpTotalInQuoteToken: 0,
//       tokenPriceVsQuote: 0,
//     }
//   }

// }

export async function getBusdTokenPricee() {

  var get = await connection();
  // return;
  try {

    var web3 = get.web3;
    if (web3 === "" || !web3) {
      web3 = new Web3(config.netWorkUrl);
    }
    var address = zeroAddr;
    if (get.address && get.address !== "") {
      address = get.address;
    }
    const multicall = new Multicall({
      web3Instance: web3,
    });
    var masterChefContract = [
      {
        reference: "balanceOfETHLP",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          }
        ],
      },
      {
        reference: "balanceOfBusdLP",
        contractAddress: config.BUSD,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          }
        ],
      },
    ];


    const poolresults = await multicall.call(masterChefContract);


    var EthlpBAl = await getFormatMulticall(poolresults, "balanceOfETHLP", 0);
    EthlpBAl = new BigNumber(EthlpBAl.hex, 16);
    EthlpBAl = EthlpBAl.toString(10);
    EthlpBAl = EthlpBAl / 1e18;

    var BusdLpbal = await getFormatMulticall(poolresults, "balanceOfBusdLP", 0);
    BusdLpbal = new BigNumber(BusdLpbal.hex, 16);
    BusdLpbal = BusdLpbal.toString(10);
    BusdLpbal = BusdLpbal / 1e18;

    var busdprice = BusdLpbal / EthlpBAl;
    console.log(BusdLpbal, 'EthlpBaaaaaaaaaaaaaaaAl: ', EthlpBAl);

    console.log('busssssssssssssssssdprice: ', busdprice);

    return {
      busdprice: parseFloat(busdprice)
    }

  } catch (err) {
    console.log(err, 'errerrerrerrerrerrerr')
    return {
      busdprice: 0
    }
  }

}

export async function transactionFunction1(address, setTransctiondetails) {
  try {
    let getdatas = JSON.parse(localStorage.getItem(address));
    if (getdatas && getdatas.length > 20) {
      getdatas.splice(0, 1);
      localStorage.setItem(address, JSON.stringify(getdatas));
      setTransctiondetails(getdatas)
    }
    else {
      setTransctiondetails(getdatas)
    }

  }
  catch (err) {
  }
}

export async function transactionFunction(address, setTransctiondetails) {
  try {
    let data = {
      address: address
    }
    const { result } = await getUserRecentTransaction(data)
    setTransctiondetails(result)
  }
  catch (err) {
    console.log('esdsdsdssdsdrr: ', err);

  }

}





export async function getFormatMulticall(results, name, pos) {
  try {
    var returnVal =
      results &&
        results.results &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext[pos] &&
        results.results[name].callsReturnContext[pos].returnValues &&
        results.results[name].callsReturnContext[pos].returnValues[0]
        ? (results.results[name].callsReturnContext[pos].returnValues.length > 1) ?
          results.results[name].callsReturnContext[pos].returnValues : results.results[name].callsReturnContext[pos].returnValues[0]
        : "";

    return returnVal;
  } catch (err) {
    return "";
  }
}