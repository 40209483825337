export default {
    ABOUT: "Di",
    ADVANCED_TOOLS: "Strumenti avanzati per gli operatori",
    APPROVE: "Approvare",
    APY: "Apy",
    AUDITS: "Audit",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog del marchio",
    BURNED_TOKEN: "Token bruciato",
    BUY_FARM_SPEND: "Compralo, coltivalo, spenderlo e puntalo!",
    BUY_ONE80_TOKENS: "Acquista un 80 token",
    CANCEL: "Annulla",
    CIRCULATE_SUPPLY: "Fornitura circolante",
    CLOSE: "Chiudere",
    COMMUNITY: "Comunità",
    COMMUNITY_JOIN: "Dopamine sta trasformando il mondo DIFI, la nostra comunità e gli sviluppatori stanno creando un mondo finanziario senza boarder, piattaforma di trading DeFI per ora e il futuro. Unisciti a noi!",
    CONFIRM: "Confermare",
    CONFIRM_SWAP: "Conferma lo scambio",
    CONNECT_WALLET: "Connettiti a un portafoglio",
    CONNECT_WALLET_TRADE_TOKEN: "Collega il tuo portafoglio, scambia qualsiasi token in ETH in pochi secondi.",
    CONTRIBUTORS: "Contributori",
    CRYPTO_GROW: "Guarda la tua criptovalo crescere!",
    CUSTOMER_SUPPORT: "Servizio Clienti",
    DEPOSIT_FEE: "Commissione di deposito:",
    DETAILS: "Particolari",
    DEVELOPERS: "Sviluppatori",
    DISCONNECT_WALLET: "Scollet del portafoglio",
    DOCUMENTATION: "Documentazione",
    EARNED: "Guadagnato",
    EARN_PASSIVE_INCOME: "Guadagnare reddito passivo 24/7 con uno80",
    EARN_WHILE_SLEEP: "Guadagna mentre dormi!",
    ETHEREUM: "Binance",
    EXCHANGE: "Scambio",
    FARMS: "Fattorie",
    FARMS_POOLS: "Fattorie e piscine",
    FINISHED: "Finito",
    GITHUB: "Github",
    GUIDES: "Guide",
    HARVEST: "Raccolto",
    HEART_ECOSYSTEM: "Dopamine token è al centro del nostro ecosistema.",
    HELP: "Aiuto",
    IDEAS_LAB: "Ideas Lab",
    JOIN_ONE80: "Unisciti alla rivoluzione DEFI Dopamine.",
    LAUNCH_APP: "AVPIA APP",
    LIQUIDITY: "Liquidità",
    LIQUIDITY_PROVIDER_FEE: "Commissione del fornitore di liquidità",
    LITE_PAPER: "Carta lite",
    LIVE: "Abitare",
    LOADING: "Caricamento in corso...",
    LOAD_MORE: "Carica di più",
    MARKET_CAP: "Capitalizzazione di mercato",
    MAX: "Max",
    MINIMUM_RECEIVED: "Ricevuto minimo",
    MULTICHAIN_INSTANT_ACCESS: "Multichain in arrivo, dandoti un accesso immediato a un mondo di criptovaluta.",
    NO_FARMS: "Nessuna fattoria",
    NO_POOLS: "Nessuna piscina",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Dopamine benefici per tutti, Hodl, Exchange and Trade 24/7.",
    ONE80_EARNED: "Dopamine guadagnato",
    ONE80_MAKES_EASY: "Dopamine ti rende facile far funzionare la tua criptovaluta.",
    ONE80_WORLD: "Dopamine fa girare il nostro mondo.",
    OUTPUT_ESTIMATED: "Viene stimato l'output. Se il prezzo cambia di oltre lo 0,8%, la transazione tornerà.",
    OUTPUT_RECEIVE_ATLEAST: "Viene stimato l'output. Riceverà almeno",
    PARTHER_BURNED: "LP Parther/Busd bruciato",
    POOLS: "Piscine",
    PRICE: "Prezzo",
    PRICE_IMPACT: "Impatto sui prezzi",
    READABLE_CONTENT: "È un fatto a lungo stabilito che un lettore sarà distratto dal contenuto leggibile.",
    SEARCH_FARMS: "Cerca fattorie",
    SEARCH_POOLS: "Pool di ricerca",
    SORT_BY: "Ordina per",
    STAKE: "Palo:",
    STAKED_ONLY: "Solo punta",
    STAKE_EARN_ONE80: "Token di palo per guadagnare uno80",
    STAKE_ONE80: "I token LP di palo per guadagnare uno80",
    STAKE_TOKENS: "Token di palo",
    START_EARNING: "Inizia a guadagnare",
    SUCCESS_COMPLETE: "Completato con successo",
    SUSTAIN: "Sostenibilità",
    TOKEN_PRICE: "Prezzo simbolico",
    TOTAL_LIQUIDITY: "Liquidità totale:",
    TOTAL_STAKE: "Totale punta:",
    TOTAL_STAKED_S: "Totale punta",
    TOTAL_SUPPLY: "Fornitura totale",
    TRADERS_CHOOSE_ONE80: "I trader scelgono uno80 per scambiare automaticamente le negoziazioni per i migliori DEX, dandoti i migliori risultati.",
    TRADE_EARN_JOIN: "Scambia, guadagna e si unisci alla nostra comunità, beneficia di Dopamine Rewards.",
    TRADE_NOW: "Scambia ora",
    TRADE_REGISTRATION_HASSLE: "Scambia qualsiasi cosa. Nessuna registrazione, nessuna seccatura",
    TRANSACTION_REVERT: "o la transazione tornerà.",
    TROUBLE_SHOOT: "Scatto di problemi",
    TRUSTED_WORLD: "Fidati in tutto il mondo",
    UNLOCK_WALLET: "Sblocca il portafoglio",
    UNSTAKE_TOKENS: "Token spiccati",
    VIEW_ETHERSCAN: "Visualizza su BscScan",
    YOU_RECEIVE: "Riceverà",
    //Exchange and Liquidity
    ADD: "Aggiungere",
    ADD_LIQUIDITY: "Aggiungi liquidità",
    AMOUNT: "Quantità",
    BALANCE: "Equilibrio:",
    CLICK_SUPPLY_REVIEW: "Una volta che sei soddisfatto dell'offerta di clic per la revisione.",
    CONNECT_WALLET_LIQUIDITY: "Connettiti a un portafoglio per visualizzare la liquidità.",
    CREATE_PAIR: "Crea coppia",
    CREATE_POOL: "Crea pool.",
    DONT_HAVE_LIQUIDITY: "Non hai ancora liquidità in questo pool.",
    DONT_SEE_POOL_JOINED: "Non vedi una piscina che ti sei unito?",
    ENABLE: "Abilitare",
    ENTER_AMOUNT: "Inserire l'importo",
    ENTER_AMOUNT_S: "Inserire l'importo",
    FIRST_LIQUIDITY_PROVIDER: "Sei il primo fornitore di liquidità.",
    FOR: "per",
    FROM: "Da",
    IMPORT_IT: "Importalo.",
    IMPORT_POOL: "Pool di importazione",
    INSUFFICIENT: "Insufficiente",
    INSUFFICIENT_BALANCE: "Equilibrio insufficiente",
    INSUFFICIENT_LIQUIDITY: "Liquidità insufficiente per questo commercio.",
    LIQUIDITY_PROV_FEE: "Commissione del fornitore di liquidità:",
    LIQUIDITY_REWARDS: "Premi del fornitore di liquidità",
    LP_TOKENS_IN_WALLET: "Token LP nel tuo portafoglio",
    MINUTES: "Minuti",
    NO_LIQUTITY_LIST: "Nessun elenco di liqutity",
    NO_POOL_FOUND: "Nessuna piscina trovata.",
    PER: "per",
    POOL: "Piscina",
    POOL_FOUND: "Piscina trovata!",
    POOL_SHARE: "La tua condivisione del pool:",
    POOL_TOKENS: "I tuoi token in piscina:",
    PRICE_IMPACTS: "Impatto sui prezzi:",
    PRICE_IMPACT_HIGH: "Impatto sui prezzi all'alto",
    RATES: "Aliquote",
    RATIO_PRICE_POOL: "Il rapporto tra i token aggiunti imposterà il prezzo di questo pool.",
    RECENT_TRANSACTIONS: "Le transazioni recenti",
    REMOVE: "Rimuovere",
    REMOVE_LIQUIDITY: "Rimuovere la liquidità",
    REMOVING_POOL_TOKENS: "La rimozione dei token del pool converte la tua posizione in token sottostanti alla tariffa attuale, proporzionale alla tua quota del pool. Le commissioni maturate sono incluse negli importi che ricevi.",
    ROUTE: "Percorso:",
    SELECT_A_TOKEN: "Seleziona un token",
    SETTINGS: "Impostazioni",
    SHARE_OF_POOL: "Quota di pool:",
    SLIPPAGE_TOL: "Tolleranza di slittamento",
    SLIPPAGE_TOLERANCE: "Tolleranza di slittamento:",
    SUPPLY: "La fornitura",
    SWAP: "Scambio",
    TO: "Per",
    TOKEN_FARM_UNSTAKE: "Oppure, se hai puntato i tuoi token da un 80-LP in una fattoria, ti scagli per vederli qui.",
    TRANSACTION_DEADLINE: "Scadenza delle transazioni",
    TRANSACTION_NOT_FOUND: "Trancazione non trovata",
    TRANSACTION_REVERT_PERCENTAGE: "La tua trasazione tornerà se il prezzo cambia sfavorevolmente di più di questa percentuale",
    WITHDRAW_LIQUIDITY: "I fornitori di liquidità guadagnano una commissione dello 0.25%% su tutte le operazioni proporzionali alla loro quota del pool. Le commissioni vengono aggiunte al pool, maturano in tempo reale e possono essere richieste prelevando la liquidità.",
    YOUR_LIQUIDITY: "La tua liquidità",
    bALANCE: "equilibrio",
    MAXIMUM_SOLD: "Massimo venduto",
    MINIMUM_RECEIVED_C: "Minimo ricevuto",
    TOKEN_NAME: "Nome simbolico",
    FOUND_MY_ADDRESS: "Trovato per indirizzo",
    IMPORT_TOKEN: "Token di importazione",
    SEARCH_NAME: "Cerca per nome o indirizzo",

    AND: "e",
    CONFIRM_SUPPLY: "Conferma la fornitura",
    CONFIRM_TRANSACTION_WALLET: "Conferma questa transazione nel tuo portafoglio",
    CONNECT_WALLETTT: "Collegare il portafoglio",
    DEPOSITED: "Depositato:",
    DISMISS: "Congedare",
    ERROR: "Errore",
    INPUT_ESTIMATED_SELL_MOST: "Viene stimato l'input. Venderai al massimo",
    LEARN_ABOUT_WALLETS: "Ulteriori informazioni sui portafogli",
    NEW_TO_ETHEREUM: "Nuovo per Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Viene stimato l'output. Se il prezzo cambia di più di",
    PRICEE: "Prezzo:",
    SUPPLYING: "Fornitura",
    SWAPPING: "Scambiarsi",
    TRANSACTION_REJECTED: "Transazione rifiutata",
    TRANSACTION_SUBMITTED: "Transazione presentata",
    VIEW_ON_BSCSCAN: "Visualizza su BSCSCan",
    WAITING_CONFIRMATION: "In attesa di conferma",
    YOUR_TRANSACTION_REVERT: "La tua transazione tornerà.",
    YOU_WILL_RECEIVE: "Riceverai :",
    PARTNERSHIPS: "Partnership",
}