import { getPoolData } from '../../Api/PoolActions';
import { fetchPoolsTotalStaking, getTotalValueLocked } from '../../ContractActions/MasterChefAction';
import poolsConfig from '../../views/Tokenlists/poolsTokenlists.json'
import { fetchFarmsPrices } from './topfarms';
import config from '../../config/config'

export const getTokenPricesFromFarm = async () => {
    const farmdata = await fetchFarmsPrices()
    console.log('farmsdsdsdsdsdsddata: ', farmdata);
    return farmdata && farmdata.length > 0 && farmdata.reduce((prices, farm) => {
        const quoteTokenAddress = farm.quoteToken.toLocaleLowerCase()
        const tokenAddress = farm.token.toLocaleLowerCase()
        /* eslint-disable no-param-reassign */
        if (!prices[quoteTokenAddress]) {
            prices[quoteTokenAddress] = (farm.quoteTokenPriceBusd)
        }
        if (!prices[tokenAddress]) {
            prices[tokenAddress] = (farm.tokenPriceBusd)
        }
        console.log('rrrrrrrrrpriceceececprices: ', prices);

        return prices
    }, {})
}

export const fetchPoolsPublicDataAsync = async () => {
    try {
        // const blockLimits = await fetchPoolsBlockLimits()
        // const totalStakings = await fetchPoolsTotalStaking()
        const prices = await getTokenPricesFromFarm()
        console.log('pprprprprprrpprrices: ', prices);
        const totakstaked = await fetchPoolsTotalStaking()

        const { result } = await getPoolData()
        // const liveData = poolsConfig && poolsConfig.tokens && poolsConfig.tokens.map((pool) => {

        const liveData = result && result && result.map((pool) => {


            let gettotalstaked = totakstaked && totakstaked.callsNonBnbPools;
            gettotalstaked = gettotalstaked[0]
            // var stakingTokenAddress = pool.stakingToken ? pool.stakingToken.toLocaleLowerCase() : null
            var stakingTokenAddress = pool.tokenAddresses ? pool.tokenAddresses.toLocaleLowerCase() : null
            console.log('stakingTokenAddress: ', stakingTokenAddress);

            var stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0
            console.log('stakingTokesdsdsdnPrice: ', stakingTokenPrice);
            // var earningTokenAddress = pool.earningToken ? pool.earningToken.toLowerCase() : null
            var earningTokenAddress = config.DopamineToken ? config.DopamineToken.toLowerCase() : null
            console.log('earningTokennnananAddress: ', earningTokenAddress);
            var earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
            console.log('earningTokenPrice: ', earningTokenPrice);
            return {
                stakingTokenPrice,
                gettotalstaked,
                earningTokenPrice,
            }
        })
        return liveData
    }
    catch (err) {
        console.log('ersdsdsdsdsdr: ', err);
    }
}