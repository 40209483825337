export default {
    ABOUT: "Handla om",
    ADVANCED_TOOLS: "Avancerade verktyg för handlare",
    APPROVE: "Godkänna",
    APY: "Apy",
    AUDITS: "Granskning",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Varumärkesblogg",
    BURNED_TOKEN: "Bränd token",
    BUY_FARM_SPEND: "Köp det, odla det, spendera det och satsa det!",
    BUY_ONE80_TOKENS: "Köp Dopamine tokens",
    CANCEL: "Avbryt",
    CIRCULATE_SUPPLY: "Cirkulationsförsörjning",
    CLOSE: "Stänga",
    COMMUNITY: "gemenskap",
    COMMUNITY_JOIN: "Dopamine förvandlar defi -världen, vårt samhälle och utvecklare skapar en finansiell värld utan pensionärer, Defi -handelsplattform för nu och framtiden. Gå med!",
    CONFIRM: "Bekräfta",
    CONFIRM_SWAP: "Bekräfta byte",
    CONNECT_WALLET: "Anslut till en plånbok",
    CONNECT_WALLET_TRADE_TOKEN: "Anslut din plånbok, handla alla token på ETH på några sekunder.",
    CONTRIBUTORS: "Bidragsgivare",
    CRYPTO_GROW: "Se din krypto växa!",
    CUSTOMER_SUPPORT: "Kundsupport",
    DEPOSIT_FEE: "Insättningsavgift:",
    DETAILS: "Information",
    DEVELOPERS: "Utvecklare",
    DISCONNECT_WALLET: "Koppla bort plånboken",
    DOCUMENTATION: "Dokumentation",
    EARNED: "Tjänade",
    EARN_PASSIVE_INCOME: "Tjäna passiv inkomst 24/7 med Dopamine",
    EARN_WHILE_SLEEP: "Tjäna medan du sover!",
    ETHEREUM: "Binance",
    EXCHANGE: "Utbyta",
    FARMS: "Gårdar",
    FARMS_POOLS: "Gårdar och pooler",
    FINISHED: "Färdiga",
    GITHUB: "Github",
    GUIDES: "Guider",
    HARVEST: "Skörda",
    HEART_ECOSYSTEM: "Dopamine -token är kärnan i vårt ekosystem.",
    HELP: "Hjälp",
    IDEAS_LAB: "Ideas Lab",
    JOIN_ONE80: "Gå med i Dopamine defi -revolutionen.",
    LAUNCH_APP: "Starta app",
    LIQUIDITY: "Likviditet",
    LIQUIDITY_PROVIDER_FEE: "Likviditetsleverantörsavgift",
    LITE_PAPER: "Lite papper",
    LIVE: "leva",
    LOADING: "Läser in...",
    LOAD_MORE: "Ladda mer",
    MARKET_CAP: "Börsvärde",
    MAX: "Max",
    MINIMUM_RECEIVED: "Minimum mottaget",
    MULTICHAIN_INSTANT_ACCESS: "Multichain kommer snart och ger dig omedelbar tillgång till en värld av krypto.",
    NO_FARMS: "Inga gårdar",
    NO_POOLS: "Inga pooler",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Dopamine förmåner för alla, HODL, utbyte och handel dygnet runt.",
    ONE80_EARNED: "Dopamine tjänat",
    ONE80_MAKES_EASY: "Dopamine gör det enkelt att få din krypto att fungera för dig.",
    ONE80_WORLD: "Dopamine får vår värld att gå runt.",
    OUTPUT_ESTIMATED: "Utgången uppskattas. Om priset ändras med mer än 0,8% kommer din transaktion att återgå.",
    OUTPUT_RECEIVE_ATLEAST: "Utgången uppskattas. du kommer att få åtminstone",
    PARTHER_BURNED: "LP Parther/Busd brände",
    POOLS: "Bassänger",
    PRICE: "Pris",
    PRICE_IMPACT: "Prispåverkan",
    READABLE_CONTENT: "Det är ett länge etablerat faktum att en läsare kommer att bli distraherad av det läsbara innehållet.",
    SEARCH_FARMS: "Sökgårdar",
    SEARCH_POOLS: "Sökpooler",
    SORT_BY: "Sortera efter",
    STAKE: "Insats:",
    STAKED_ONLY: "Endast stakad",
    STAKE_EARN_ONE80: "Insatser för att tjäna Dopamine",
    STAKE_ONE80: "Stake LP -symboler för att tjäna Dopamine",
    STAKE_TOKENS: "Insatser",
    START_EARNING: "Börja tjäna",
    SUCCESS_COMPLETE: "Framgångsrikt slutförd",
    SUSTAIN: "Hållbarhet",
    TOKEN_PRICE: "Tokenpris",
    TOTAL_LIQUIDITY: "Total likviditet:",
    TOTAL_STAKE: "Totalt sagt:",
    TOTAL_STAKED_S: "Total inlagd",
    TOTAL_SUPPLY: "Total utbud",
    TRADERS_CHOOSE_ONE80: "Handlare väljer Dopamine för att automatiskt byta ut och dirigera affärer till de bästa DEX: erna, vilket ger dig de bästa resultaten.",
    TRADE_EARN_JOIN: "Handla, tjäna och gå med i vårt samhälle, dra nytta av Dopamine belöning.",
    TRADE_NOW: "Handel nu",
    TRADE_REGISTRATION_HASSLE: "Handla vad som helst. Ingen registrering, inget krångel",
    TRANSACTION_REVERT: "eller transaktionen kommer att återgå.",
    TROUBLE_SHOOT: "Besvär",
    TRUSTED_WORLD: "Pålitlig över hela världen",
    UNLOCK_WALLET: "Låsa upp plånboken",
    UNSTAKE_TOKENS: "Tokens",
    VIEW_ETHERSCAN: "Visa på BscScan",
    YOU_RECEIVE: "Du kommer att få",
    //Exchange and Liquidity
    ADD: "Lägg till",
    ADD_LIQUIDITY: "Lägga till likviditet",
    AMOUNT: "Belopp",
    BALANCE: "Balans:",
    CLICK_SUPPLY_REVIEW: "När du är nöjd med priset klickar du leveransen för att granska.",
    CONNECT_WALLET_LIQUIDITY: "Anslut till en plånbok för att se din likviditet.",
    CREATE_PAIR: "Skapa par",
    CREATE_POOL: "Skapa pool.",
    DONT_HAVE_LIQUIDITY: "Du har inte likviditet i den här poolen än.",
    DONT_SEE_POOL_JOINED: "Ser du inte en pool du gick med?",
    ENABLE: "Gör det möjligt",
    ENTER_AMOUNT: "Ange belopp",
    ENTER_AMOUNT_S: "Ange belopp",
    FIRST_LIQUIDITY_PROVIDER: "Du är den första likviditetsleverantören.",
    FOR: "för",
    FROM: "Från",
    IMPORT_IT: "Importera det.",
    IMPORT_POOL: "Importpool",
    INSUFFICIENT: "Otillräcklig",
    INSUFFICIENT_BALANCE: "Otillräcklig balans",
    INSUFFICIENT_LIQUIDITY: "Otillräcklig likviditet för denna handel.",
    LIQUIDITY_PROV_FEE: "Likviditetsleverantörsavgift:",
    LIQUIDITY_REWARDS: "Likviditetsleverantör belöningar",
    LP_TOKENS_IN_WALLET: "LP -symboler i din plånbok",
    MINUTES: "Minuter",
    NO_LIQUTITY_LIST: "Ingen liqutity -lista",
    NO_POOL_FOUND: "Ingen pool hittades.",
    PER: "per",
    POOL: "Slå samman",
    POOL_FOUND: "Pool hittades!",
    POOL_SHARE: "Din poolandel:",
    POOL_TOKENS: "Dina pooltokens:",
    PRICE_IMPACTS: "Prispåverkan:",
    PRICE_IMPACT_HIGH: "Prispåverkan till hög",
    RATES: "Priser",
    RATIO_PRICE_POOL: "Förhållandet mellan tokens du lägger till kommer att ställa in priset på denna pool.",
    RECENT_TRANSACTIONS: "Senaste transaktioner",
    REMOVE: "Ta bort",
    REMOVE_LIQUIDITY: "Ta bort likviditet",
    REMOVING_POOL_TOKENS: "Att ta bort pooltokens omvandlar din position tillbaka till underliggande tokens med den nuvarande takt, proportionell mot din del av poolen. Upplupna avgifter ingår i de belopp du får.",
    ROUTE: "Rutt:",
    SELECT_A_TOKEN: "Välj ett symbol",
    SETTINGS: "inställningar",
    SHARE_OF_POOL: "Dela av poolen:",
    SLIPPAGE_TOL: "Glidningstolerans",
    SLIPPAGE_TOLERANCE: "Slippage -tolerans:",
    SUPPLY: "Tillförsel",
    SWAP: "Byta",
    TO: "Till",
    TOKEN_FARM_UNSTAKE: "Eller, om du satsade dina Dopamine-LP-symboler på en gård, avstänga dem för att se dem här.",
    TRANSACTION_DEADLINE: "Transaktionsfrist",
    TRANSACTION_NOT_FOUND: "Transation hittas inte",
    TRANSACTION_REVERT_PERCENTAGE: "Din trasaktion kommer att återgå om priset ändras ogynnsamt med mer än denna procentandel",
    WITHDRAW_LIQUIDITY: "Likviditetsleverantörer tjänar en avgift på 0.25%% på alla branscher proportionella mot sin andel av poolen. Avgifter läggs till i poolen, tillkommer i realtid och kan krävas genom att dra tillbaka din likviditet.",
    YOUR_LIQUIDITY: "Din likviditet",
    bALANCE: "balans",
    MAXIMUM_SOLD: "Max sålt",
    MINIMUM_RECEIVED_C: "Minsta mottagna",
    TOKEN_NAME: "Tokennamn",
    FOUND_MY_ADDRESS: "Hittas efter adress",
    IMPORT_TOKEN: "Importtoken",
    SEARCH_NAME: "Sök efter namn eller adress",

    AND: "och",
    CONFIRM_SUPPLY: "Bekräfta leveransen",
    CONFIRM_TRANSACTION_WALLET: "Bekräfta denna transaktion i din plånbok",
    CONNECT_WALLETTT: "Anslut plånbok",
    DEPOSITED: "Deponerade:",
    DISMISS: "Avfärda",
    ERROR: "Fel",
    INPUT_ESTIMATED_SELL_MOST: "Ingång uppskattas. Du kommer att sälja högst",
    LEARN_ABOUT_WALLETS: "Lär dig mer om plånböcker",
    NEW_TO_ETHEREUM: "Nytt för Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Utgången uppskattas. Om priset förändras med mer än",
    PRICEE: "Pris:",
    SUPPLYING: "Leverans",
    SWAPPING: "Byte",
    TRANSACTION_REJECTED: "Transaktion avvisad",
    TRANSACTION_SUBMITTED: "Transaktion som skickats in",
    VIEW_ON_BSCSCAN: "Visa på bscscan",
    WAITING_CONFIRMATION: "Väntar på bekräftelse",
    YOUR_TRANSACTION_REVERT: "Din transaktion kommer att återgå.",
    YOU_WILL_RECEIVE: "Du kommer att få :",
    PARTNERSHIPS: "Partnerskap",
}