export default {
    // FARMS
    FARMS: "plase",
    STAKE_ONE80: "Steek LP-tokens in om Dopamine te verdien",
    STAKED_ONLY: "Slegs ingesit",
    LIVE: "Leef",
    FINISHED: "Klaar",
    SEARCH_FARMS: "Soek plase",
    SORT_BY: "Sorteer Volgens",
    EARNED: "Verdien",
    TOTAL_STAKED_S: "Totaal ingesit",
    APY: "APY",
    HARVEST: "Oes",
    DEPOSIT_FEE: "Deposito fooi:",
    START_EARNING: "Begin Verdien",
    UNLOCK_WALLET: "Ontsluit Wallet",
    APPROVE: "Keur goed",
    DETAILS: "Besonderhede",
    STAKE: "Inzet:",
    TOTAL_LIQUIDITY: "Totale likiditeit:",
    VIEW_ETHERSCAN: "Kyk op BscScan",
    LOAD_MORE: "Laai meer",
    NO_FARMS: "Geen plase nie",
    LOADING: "Laai tans...",
    YOU_RECEIVE: "Jy sal ontvang",
    BITCOIN_S: "Bitcoin",
    BITCOIN_C: "BITCOIN",
    ETHEREUM: "Binance",
    OUTPUT_ESTIMATED: "Uitset word geskat. As die prys met meer as 0.8% verander, sal jou transaksie terugkeer.",
    PARTHER_BURNED: "LP PARTHER/BUSD verbrand",
    PRICE: "Prys",
    CONFIRM: "Bevestig",
    CONFIRM_SWAP: "Bevestig omruiling",
    OUTPUT_RECEIVE_ATLEAST: "Uitset word geskat. jy sal ten minste ontvang",
    TRANSACTION_REVERT: "of die transaksie sal terugkeer.",
    MINIMUM_RECEIVED: "Minimum ontvang",
    PRICE_IMPACT: "Prys impak",
    LIQUIDITY_PROVIDER_FEE: "Likiditeitsverskafferfooi",
    SUCCESS_COMPLETE: "Suksesvol voltooi",
    READABLE_CONTENT: "Dit is 'n lang gevestigde feit dat 'n leser afgelei sal word deur die leesbare inhoud.",
    CLOSE: "Naby",
    UNSTAKE_TOKENS: "Ontspan Tokens",
    BAL: "Bal:",
    MAX: "Maks",
    CANCEL: "Kanselleer",
    STAKE_TOKENS: "Stake Tokens",
    // POOLS
    POOLS: "Swembaddens",
    STAKE_EARN_ONE80: "Inzet tokens om Dopamine te verdien",
    SEARCH_POOLS: "Soek swembaddens",
    ONE80_EARNED: "Dopamine VERDIEN",
    ONE80: "Dopamine",
    TOTAL_STAKE: "Totale inset:",
    NO_POOLS: "Geen swembaddens",
    //HOME
    JOIN_ONE80: "Sluit aan by die Dopamine DeFi-revolusie.",
    TRADE_EARN_JOIN: "Handel, verdien en sluit aan by ons gemeenskap, trek voordeel uit Dopamine-belonings.",
    LAUNCH_APP: "Begin App",
    TRUSTED_WORLD: "Wêreldwyd vertrou",
    ONE80_BENEFITS: "Dopamine voordele vir almal, HODL, ruil en handel 24/7.",
    BUY_ONE80_TOKENS: "Koop Dopamine-tekens",
    CRYPTO_GROW: "Kyk hoe jou kripto groei!",
    TRADE_REGISTRATION_HASSLE: "Handel enigiets. Geen registrasie, geen moeite",
    CONNECT_WALLET_TRADE_TOKEN: "Koppel jou beursie, handel enige teken op Eth in sekondes.",
    MULTICHAIN_INSTANT_ACCESS: "Multichain kom binnekort, gee jou onmiddellike toegang tot 'n wêreld van kripto.",
    TRADE_NOW: "Handel nou",
    ADVANCED_TOOLS: "Gevorderde gereedskap vir handelaars",
    TRADERS_CHOOSE_ONE80: "Handelaars kies Dopamine om transaksies outomaties te ruil en na die beste DEX'e te stuur, wat jou die beste resultate gee.",
    EARN_PASSIVE_INCOME: "Verdien Passiewe Inkomste 24/7 met Dopamine",
    ONE80_MAKES_EASY: "Dopamine maak dit maklik om jou kripto vir jou te laat werk.",
    EARN_WHILE_SLEEP: "Verdien terwyl jy slaap!",
    FARMS_POOLS: "Plase en swembaddens",
    TOTAL_SUPPLY: "Totale aanbod",
    BURNED_TOKEN: "Verbrande teken",
    MARKET_CAP: "Markkapitalisasie",
    CIRCULATE_SUPPLY: "Sirkulerende Voorraad",
    TOKEN_PRICE: "Token prys",
    ONE80_WORLD: "Dopamine laat ons wêreld draai.",
    HEART_ECOSYSTEM: "Dopamine-teken is die kern van ons ekosisteem.",
    BUY_FARM_SPEND: "Koop dit, boer dit, spandeer dit en spel dit!",
    //HEADER
    EXCHANGE: "Ruil",
    LIQUIDITY: "Likiditeit",
    DISCONNECT_WALLET: "Ontkoppel Wallet",
    CONNECT_WALLET: "Koppel aan 'n beursie",
    //FOOTER
    COMMUNITY_JOIN: "Dopamine is besig om die DeFI-wêreld te transformeer, ons gemeenskap en ontwikkelaars skep 'n finansiële wêreld sonder grense, DeFI-handelsplatform vir nou en die toekoms. Sluit by ons aan!",
    ABOUT: "Oor",
    BRAND_BLOG: "Handelsmerkblog",
    COMMUNITY: "Gemeenskap",
    SUSTAIN: "Volhoubaarheid",
    LITE_PAPER: "Lite papier",
    HELP: "Help",
    CUSTOMER_SUPPORT: "Klientediens",
    TROUBLE_SHOOT: "Probleemskiet",
    DEVELOPERS: "Ontwikkelaars",
    GITHUB: "Github",
    DOCUMENTATION: "Dokumentasie",
    AUDITS: "Oudits",
    CONTRIBUTORS: "Bydraers",
    IDEAS_LAB: "Idees Lab",
    GUIDES: "Gidse",
    //Exchange and Liquidity
    ADD: "Byvoeg",
    ADD_LIQUIDITY: "Voeg likiditeit by",
    AMOUNT: "Bedrag",
    BALANCE: "Balans",
    CLICK_SUPPLY_REVIEW: "Sodra u tevrede is met die tariefkliktoevoer om te hersien.",
    CONNECT_WALLET_LIQUIDITY: "Koppel aan 'n beursie om u likiditeit te sien.",
    CREATE_PAIR: "Skep 'n paar",
    CREATE_POOL: "Skep swembad.",
    DONT_HAVE_LIQUIDITY: "U het nog nie likiditeit in hierdie swembad nie.",
    DONT_SEE_POOL_JOINED: "Sien u nie 'n swembad waarby u aangesluit het nie?",
    ENABLE: "In staat stel",
    ENTER_AMOUNT: "Voer bedrag in",
    ENTER_AMOUNT_S: "Voer bedrag in",
    FIRST_LIQUIDITY_PROVIDER: "U is die eerste likiditeitsverskaffer.",
    FOR: "vir",
    FROM: "Van",
    IMPORT_IT: "Voer dit in.",
    IMPORT_POOL: "Invoer swembad",
    INSUFFICIENT: "Onvoldoende",
    INSUFFICIENT_BALANCE: "Onvoldoende balans",
    INSUFFICIENT_LIQUIDITY: "Onvoldoende likiditeit vir hierdie handel.",
    LIQUIDITY_PROV_FEE: "Fooi vir likiditeitsverskaffers:",
    LIQUIDITY_REWARDS: "Likiditeitsverskafferbelonings",
    LP_TOKENS_IN_WALLET: "LP -tekens in u beursie",
    MINUTES: "Minute",
    NO_LIQUTITY_LIST: "Geen liqutity -lys nie",
    NO_POOL_FOUND: "Geen swembad gevind nie.",
    PER: "per",
    POOL: "Swembad",
    POOL_FOUND: "Pool gevind!",
    POOL_SHARE: "U swembadaandeel:",
    POOL_TOKENS: "U swembadtokens:",
    PRICE_IMPACTS: "Prysimpak:",
    PRICE_IMPACT_HIGH: "Prysimpak tot hoog",
    RATES: "Tariewe",
    RATIO_PRICE_POOL: "Die verhouding van tekens wat u byvoeg, sal die prys van hierdie swembad bepaal.",
    RECENT_TRANSACTIONS: "Onlangse transaksies",
    REMOVE: "Verwyder",
    REMOVE_LIQUIDITY: "Verwyder die likiditeit",
    REMOVING_POOL_TOKENS: "Die verwydering van swembadtokens skakel u posisie om in onderliggende tekens teen die huidige tempo, eweredig aan u deel van die swembad. Opgelope fooie is ingesluit in die bedrae wat u ontvang.",
    ROUTE: "Roete:",
    SELECT_A_TOKEN: "Kies 'n teken",
    SETTINGS: "Instellings",
    SHARE_OF_POOL: "Deel van die swembad:",
    SLIPPAGE_TOL: "Slipverdraagsaamheid",
    SLIPPAGE_TOLERANCE: "Slipverdraagsaamheid:",
    SUPPLY: "Verskaf",
    SWAP: "Ruil",
    TO: "Na",
    TOKEN_FARM_UNSTAKE: "Of, as u u Dopamine-LP-tekens op 'n plaas vasgevang het, moet u dit los om hulle hier te sien.",
    TRANSACTION_DEADLINE: "Sperdatum vir transaksies",
    TRANSACTION_NOT_FOUND: "Trancation nie gevind nie",
    TRANSACTION_REVERT_PERCENTAGE: "U trasaksie sal terugkeer as die prys met meer as hierdie persentasie ongunstig verander",
    WITHDRAW_LIQUIDITY: "Likiditeitsverskaffers verdien 'n fooi van 0.25% op alle ambagte wat eweredig is aan hul deel van die swembad. Fooie word by die swembad gevoeg, intyds opgeloop en kan geëis word deur u likiditeit te onttrek.",
    YOUR_LIQUIDITY: "U likiditeit",
    bALANCE: "balans",
    MAXIMUM_SOLD: "Maksimum verkoop",
    MINIMUM_RECEIVED_C: "Minimum ontvang",
    TOKEN_NAME: "Token Naam",
    FOUND_MY_ADDRESS: "Deur adres gevind",
    IMPORT_TOKEN: "Invoer token",
    SEARCH_NAME: "Soek volgens name of adres",

    AND: "en",
    CONFIRM_SUPPLY: "Bevestig die aanbod",
    CONFIRM_TRANSACTION_WALLET: "Bevestig hierdie transaksie in u beursie",
    CONNECT_WALLETTT: "Verbind beursie",
    DEPOSITED: "Gedeponeer:",
    DISMISS: "Afdank",
    ERROR: "Fout",
    INPUT_ESTIMATED_SELL_MOST: "Insette word geskat. U sal hoogstens verkoop",
    LEARN_ABOUT_WALLETS: "Lees meer oor beursies",
    NEW_TO_ETHEREUM: "Nuut in Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Uitset word geskat. As die prys met meer as verander",
    PRICEE: "Prys:",
    SUPPLYING: "Verskaffing",
    SWAPPING: "Uitruil",
    TRANSACTION_REJECTED: "Transaksie verwerp",
    TRANSACTION_SUBMITTED: "Transaksie ingedien",
    VIEW_ON_BSCSCAN: "Uitsig op BSCSCAN",
    WAITING_CONFIRMATION: "Wag vir bevestiging",
    YOUR_TRANSACTION_REVERT: "U transaksie sal terugkeer.",
    YOU_WILL_RECEIVE: "Jy sal ontvang :",
    PARTNERSHIPS: "Vennootskappe",
}