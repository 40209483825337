import React, { useEffect, useState } from 'react';
import config from '../config/config';


const RecentTransactionnModal = (props) => {
    console.log('sssssssssssssssssprops: ', props);
    var transHash = props.transactionHash;
    var symbol = props.symbol;
    var fromprop = props.from
    const hideModal = async () => {
        window.$("#withdraw_success_modal").modal("hide");
    }

    return (
        <div
            className="modal fade primary_modal"
            id="withdraw_success_modal"
            tabIndex={-1}
            aria-labelledby="withdraw_success_modal_modalLabel"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="withdraw_success_modalLabel">
                            {
                                fromprop === "exchange" ?
                                    (symbol !== "BNB" ? `Successfully Received ${symbol} token` : "Successfully Received BNB") :
                                    (fromprop === "liquidity" ?
                                        `Successfully Received ${symbol} LP token` :
                                        "Successfully withdraw DOPE Token"
                                    )
                            }
                        </h5>
                        <button
                            type="button"
                            className="btn-close btn-close-new"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={hideModal}
                        >
                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fill-rule="nonzero"/></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="buytoken_confirm_body buytoken_confirm_body_chg">
                            <div className="justify-content-center mt-4">
                                <svg className='success' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                </svg>
                                {/* <a href={config.txurl + transHash} target="_blank" className="d-block mt-3"> <i className="fa fa-eye" aria-hidden="true">
                        </i> View Transaction </a> */}
                                <a className="d-flex mt-4 justify-content-center" href={config.txUrl + transHash} target="_blank"> <i className="fa fa-eye mt-1 mr-2" aria-hidden="true">
                                </i> View Transaction </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentTransactionnModal;