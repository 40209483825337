export default {
    ABOUT: "Over",
    ADVANCED_TOOLS: "Geavanceerde tools voor handelaren",
    APPROVE: "Goedkeuren",
    APY: "Apy",
    AUDITS: "Audits",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Merkblog",
    BURNED_TOKEN: "Verbrand token",
    BUY_FARM_SPEND: "Koop het, boer het, geef het uit en stak het in!",
    BUY_ONE80_TOKENS: "Koop Dopamine -tokens",
    CANCEL: "Annuleren",
    CIRCULATE_SUPPLY: "Circulerende levering",
    CLOSE: "Dichtbij",
    COMMUNITY: "Gemeenschap",
    COMMUNITY_JOIN: "Dopamine transformeert de defi -wereld, onze gemeenschap en ontwikkelaars creëren een financiële wereld zonder boarders, defi -handelsplatform voor nu en de toekomst. Doe met ons mee!",
    CONFIRM: "Bevestigen",
    CONFIRM_SWAP: "Bevestig swap",
    CONNECT_WALLET: "Maak verbinding met een portemonnee",
    CONNECT_WALLET_TRADE_TOKEN: "Sluit uw portemonnee aan, ruil in enkele seconden elk token op ETH.",
    CONTRIBUTORS: "Medewerkers",
    CRYPTO_GROW: "Kijk hoe je crypto groeit!",
    CUSTOMER_SUPPORT: "Klantenservice",
    DEPOSIT_FEE: "Deposito -vergoeding:",
    DETAILS: "Details",
    DEVELOPERS: "Ontwikkelaars",
    DISCONNECT_WALLET: "Koppel de portemonnee los",
    DOCUMENTATION: "Documentatie",
    EARNED: "Verdiend",
    EARN_PASSIVE_INCOME: "Verdien 24/7 passief inkomen met Dopamine",
    EARN_WHILE_SLEEP: "Verdien terwijl je slaapt!",
    ETHEREUM: "Binance",
    EXCHANGE: "Aandelenbeurs",
    FARMS: "Boerderijen",
    FARMS_POOLS: "Boerderijen en zwembaden",
    FINISHED: "Afgerond",
    GITHUB: "Gitub",
    GUIDES: "Gidsen",
    HARVEST: "Oogst",
    HEART_ECOSYSTEM: "Dopamine -token vormt de kern van ons ecosysteem.",
    HELP: "Helpen",
    IDEAS_LAB: "Ideeënlaboratorium",
    JOIN_ONE80: "Word lid van de Dopamine Defi -revolutie.",
    LAUNCH_APP: "Lanceer app",
    LIQUIDITY: "Liquiditeit",
    LIQUIDITY_PROVIDER_FEE: "Liquiditeitsprovider vergoeding",
    LITE_PAPER: "Lite Paper",
    LIVE: "Live",
    LOADING: "Bezig met laden...",
    LOAD_MORE: "Meer laden",
    MARKET_CAP: "Marktkapitalisatie",
    MAX: "Maximaal",
    MINIMUM_RECEIVED: "Minimaal ontvangen",
    MULTICHAIN_INSTANT_ACCESS: "Multichain komt binnenkort en geeft je onmiddellijk toegang tot een wereld van crypto.",
    NO_FARMS: "Geen boerderijen",
    NO_POOLS: "Geen pools",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Dopamine Voordelen voor iedereen, HODL, Exchange and Trade 24/7.",
    ONE80_EARNED: "Dopamine verdiend",
    ONE80_MAKES_EASY: "Dopamine maakt het gemakkelijk om uw crypto voor u te laten werken.",
    ONE80_WORLD: "Dopamine laat onze wereld rondgaan.",
    OUTPUT_ESTIMATED: "Output wordt geschat. Als de prijs met meer dan 0,8% verandert, keert uw transactie terug.",
    OUTPUT_RECEIVE_ATLEAST: "Output wordt geschat. U zult tenminste ontvangen",
    PARTHER_BURNED: "LP Parther/Busd verbrand",
    POOLS: "Zwembaden",
    PRICE: "Prijs",
    PRICE_IMPACT: "Prijseffect",
    READABLE_CONTENT: "Het is een lang vastgesteld feit dat een lezer wordt afgeleid door de leesbare inhoud.",
    SEARCH_FARMS: "Zoek boerderijen",
    SEARCH_POOLS: "Zoekpools",
    SORT_BY: "Sorteer op",
    STAKE: "Inzet:",
    STAKED_ONLY: "Alleen gestaakt",
    STAKE_EARN_ONE80: "Stak Tokens om Dopamine te verdienen",
    STAKE_ONE80: "Stake LP -tokens om Dopamine te verdienen",
    STAKE_TOKENS: "Stake tokens",
    START_EARNING: "Begin met verdienen",
    SUCCESS_COMPLETE: "Succesvol afgerond",
    SUSTAIN: "Duurzaamheid",
    TOKEN_PRICE: "Tokenprijs",
    TOTAL_LIQUIDITY: "Totale liquiditeit:",
    TOTAL_STAKE: "Totaal uitgezet:",
    TOTAL_STAKED_S: "Totaal uitgezet",
    TOTAL_SUPPLY: "Totaal aanbod",
    TRADERS_CHOOSE_ONE80: "Traders kiezen Dopamine om automatisch transacties uit te wisselen en te routeren naar de beste DEX's, waardoor u de beste resultaten krijgt.",
    TRADE_EARN_JOIN: "Handel, verdien en sluit zich aan bij onze gemeenschap, profiteren van Dopamine Rewards.",
    TRADE_NOW: "Handel nu",
    TRADE_REGISTRATION_HASSLE: "Ruil iets. Geen registratie, geen gedoe",
    TRANSACTION_REVERT: "of de transactie zal terugkeren.",
    TROUBLE_SHOOT: "Trouble Shoot",
    TRUSTED_WORLD: "Wereldwijd vertrouwd",
    UNLOCK_WALLET: "Ontgrendelportel",
    UNSTAKE_TOKENS: "Niet -stake tokens",
    VIEW_ETHERSCAN: "Bekijk op BscScan",
    YOU_RECEIVE: "U zult ontvangen",
    //Exchange and Liquidity
    ADD: "Toevoegen",
    ADD_LIQUIDITY: "Liquiditeit toevoegen",
    AMOUNT: "Hoeveelheid",
    BALANCE: "Evenwicht:",
    CLICK_SUPPLY_REVIEW: "Zodra u tevreden bent met de tariefklikvoorraad om te beoordelen.",
    CONNECT_WALLET_LIQUIDITY: "Maak verbinding met een portemonnee om uw liquiditeit te bekijken.",
    CREATE_PAIR: "Maak een paar",
    CREATE_POOL: "Maak een zwembad.",
    DONT_HAVE_LIQUIDITY: "Je hebt nog geen liquiditeit in dit zwembad.",
    DONT_SEE_POOL_JOINED: "Zie je geen zwembad waar je lid van bent?",
    ENABLE: "Inschakelen",
    ENTER_AMOUNT: "Voer een bedrag in",
    ENTER_AMOUNT_S: "Voer een bedrag in",
    FIRST_LIQUIDITY_PROVIDER: "U bent de eerste liquiditeitsprovider.",
    FOR: "voor",
    FROM: "Van",
    IMPORT_IT: "Import het.",
    IMPORT_POOL: "Importpool",
    INSUFFICIENT: "Niet voldoende",
    INSUFFICIENT_BALANCE: "Onvoldoende balans",
    INSUFFICIENT_LIQUIDITY: "Onvoldoende liquiditeit voor deze handel.",
    LIQUIDITY_PROV_FEE: "Liquiditeitsprovider vergoeding:",
    LIQUIDITY_REWARDS: "Liquiditeitsprovider beloningen",
    LP_TOKENS_IN_WALLET: "LP -tokens in uw portemonnee",
    MINUTES: "Minuten",
    NO_LIQUTITY_LIST: "Geen vloeistoflijst",
    NO_POOL_FOUND: "Geen zwembad gevonden.",
    PER: "per",
    POOL: "Pool",
    POOL_FOUND: "Pool gevonden!",
    POOL_SHARE: "Uw poolaandeel:",
    POOL_TOKENS: "Uw zwembadtokens:",
    PRICE_IMPACTS: "Prijsimpact:",
    PRICE_IMPACT_HIGH: "Prijseffect op hoog",
    RATES: "Tarieven",
    RATIO_PRICE_POOL: "De verhouding van tokens die u toevoegt, stelt de prijs van deze pool in.",
    RECENT_TRANSACTIONS: "recente transacties",
    REMOVE: "Verwijderen",
    REMOVE_LIQUIDITY: "Verwijder de liquiditeit",
    REMOVING_POOL_TOKENS: "Het verwijderen van pooltokens zet uw positie terug in onderliggende tokens tegen het huidige tarief, evenredig met uw aandeel in de pool. Opgebouwde kosten zijn opgenomen in de bedragen die u ontvangt.",
    ROUTE: "Route:",
    SELECT_A_TOKEN: "Selecteer een token",
    SETTINGS: "Instellingen",
    SHARE_OF_POOL: "Aandeel van pool:",
    SLIPPAGE_TOL: "Slippentolerantie",
    SLIPPAGE_TOLERANCE: "Slippen tolerantie:",
    SUPPLY: "Levering",
    SWAP: "Ruil",
    TO: "Tot",
    TOKEN_FARM_UNSTAKE: "Of, als je je Dopamine-LP-tokens in een boerderij heeft gestoken, maak ze dan niet om ze hier te zien.",
    TRANSACTION_DEADLINE: "Transactiedeadline",
    TRANSACTION_NOT_FOUND: "Trancatie niet gevonden",
    TRANSACTION_REVERT_PERCENTAGE: "Uw trasactie zal terugkeren als de prijs ongunstig verandert door meer dan dit percentage",
    WITHDRAW_LIQUIDITY: "Liquiditeitsaanbieders verdienen een vergoeding van 0.25%% op alle transacties die evenredig zijn aan hun aandeel in de pool. Kosten worden aan het zwembad toegevoegd, worden in realtime opgebouwd en kunnen worden beweerd door uw liquiditeit in te trekken.",
    YOUR_LIQUIDITY: "Uw liquiditeit",
    bALANCE: "evenwicht",
    MAXIMUM_SOLD: "Maximaal verkocht",
    MINIMUM_RECEIVED_C: "Minimaal ontvangen",
    TOKEN_NAME: "Tokennaam",
    FOUND_MY_ADDRESS: "Gevonden per adres",
    IMPORT_TOKEN: "Import token",
    SEARCH_NAME: "Zoek op naam of adres",

    AND: "en",
    CONFIRM_SUPPLY: "Bevestig aanbod",
    CONFIRM_TRANSACTION_WALLET: "Bevestig deze transactie in uw portemonnee",
    CONNECT_WALLETTT: "Verbind de portemonnee",
    DEPOSITED: "Gedeponeerd:",
    DISMISS: "Afwijzen",
    ERROR: "Fout",
    INPUT_ESTIMATED_SELL_MOST: "Input wordt geschat. U zult maximaal verkopen",
    LEARN_ABOUT_WALLETS: "Lees meer over portefeuilles",
    NEW_TO_ETHEREUM: "Nieuw voor Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Output wordt geschat. Als de prijs meer dan verandert",
    PRICEE: "Prijs:",
    SUPPLYING: "Levering",
    SWAPPING: "Verwisseling",
    TRANSACTION_REJECTED: "Transactie afgewezen",
    TRANSACTION_SUBMITTED: "Verzonden transactie",
    VIEW_ON_BSCSCAN: "Bekijk op BSCSCAN",
    WAITING_CONFIRMATION: "op bevestiging wachten",
    YOUR_TRANSACTION_REVERT: "Uw transactie keert terug.",
    YOU_WILL_RECEIVE: "Je ontvangt :",
    PARTNERSHIPS: "partnerschappen",
}