export default {
    ABOUT: "Om",
    ADVANCED_TOOLS: "Avancerede værktøjer til forhandlere",
    APPROVE: "Godkende",
    APY: "Apy",
    AUDITS: "Revisioner",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Brand Blog",
    BURNED_TOKEN: "Brændt token",
    BUY_FARM_SPEND: "Køb det, opdræt det, brug det og stav det!",
    BUY_ONE80_TOKENS: "Køb Dopamine -tokens",
    CANCEL: "Afbestille",
    CIRCULATE_SUPPLY: "Cirkulerende forsyning",
    CLOSE: "Tæt",
    COMMUNITY: "Fællesskab",
    COMMUNITY_JOIN: "Dopamine omdanner DEFI -verdenen, vores samfund og udviklere skaber en finansiel verden uden boarders, Defi Trading Platform for nu og fremtiden. Kom med os!",
    CONFIRM: "Bekræfte",
    CONFIRM_SWAP: "Bekræft swap",
    CONNECT_WALLET: "Opret forbindelse til en tegnebog",
    CONNECT_WALLET_TRADE_TOKEN: "Tilslut din tegnebog, handel ethvert tegn på ETH på få sekunder.",
    CONTRIBUTORS: "Bidragydere",
    CRYPTO_GROW: "Se din krypto vokse!",
    CUSTOMER_SUPPORT: "Kunde support",
    DEPOSIT_FEE: "Depositum:",
    DETAILS: "detaljer",
    DEVELOPERS: "Udviklere",
    DISCONNECT_WALLET: "Frakobl tegnebogen",
    DOCUMENTATION: "Dokumentation",
    EARNED: "Optjent",
    EARN_PASSIVE_INCOME: "Tjen passiv indkomst 24/7 med en80",
    EARN_WHILE_SLEEP: "Tjen, mens du sover!",
    ETHEREUM: "Binance",
    EXCHANGE: "Udveksling",
    FARMS: "Gårde",
    FARMS_POOLS: "Gårde og puljer",
    FINISHED: "Færdig",
    GITHUB: "GitHub",
    GUIDES: "Guider",
    HARVEST: "Høst",
    HEART_ECOSYSTEM: "Dopamine -token er kernen i vores økosystem.",
    HELP: "Hjælp",
    IDEAS_LAB: "Idéer lab",
    JOIN_ONE80: "Deltag i Dopamine Defi Revolution.",
    LAUNCH_APP: "Start -app",
    LIQUIDITY: "Likviditet",
    LIQUIDITY_PROVIDER_FEE: "Gebyr for likviditetsudbyder",
    LITE_PAPER: "Lite papir",
    LIVE: "Direkte",
    LOADING: "Indlæser...",
    LOAD_MORE: "Indlæs mere",
    MARKET_CAP: "Markedsværdi",
    MAX: "Maks",
    MINIMUM_RECEIVED: "Minimum modtaget",
    MULTICHAIN_INSTANT_ACCESS: "Multichain kommer snart, hvilket giver dig øjeblikkelig adgang til en verden af ​​krypto.",
    NO_FARMS: "Ingen gårde",
    NO_POOLS: "Ingen puljer",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Dopamine fordele for alle, HODL, udveksling og handel 24/7.",
    ONE80_EARNED: "Dopamine optjent",
    ONE80_MAKES_EASY: "Dopamine gør det nemt at få din krypto til at fungere for dig.",
    ONE80_WORLD: "Dopamine får vores verden til at gå rundt.",
    OUTPUT_ESTIMATED: "Output estimeres. Hvis prisændringerne med mere end 0,8% vil din transaktion vende tilbage.",
    OUTPUT_RECEIVE_ATLEAST: "Output estimeres. Du vil modtage mindst",
    PARTHER_BURNED: "LP Parther/Busd brændte",
    POOLS: "Puljer",
    PRICE: "Pris",
    PRICE_IMPACT: "Prispåvirkning",
    READABLE_CONTENT: "Det er en længe etableret kendsgerning, at en læser vil blive distraheret af det læsbare indhold.",
    SEARCH_FARMS: "Søg i gårde",
    SEARCH_POOLS: "Søgepuljer",
    SORT_BY: "Sorter efter",
    STAKE: "Stake:",
    STAKED_ONLY: "Kun staket",
    STAKE_EARN_ONE80: "Stake tokens for at tjene en80",
    STAKE_ONE80: "Stake LP -tokens for at tjene en80",
    STAKE_TOKENS: "Stake tokens",
    START_EARNING: "Begynd at tjene",
    SUCCESS_COMPLETE: "Med succes afsluttet",
    SUSTAIN: "Bæredygtighed",
    TOKEN_PRICE: "Tokenpris",
    TOTAL_LIQUIDITY: "Total likviditet:",
    TOTAL_STAKE: "Total staket:",
    TOTAL_STAKED_S: "Total staket",
    TOTAL_SUPPLY: "Total forsyning",
    TRADERS_CHOOSE_ONE80: "Forhandlere vælger en80 til automatisk at udveksle og rute handler til de bedste DEX'er, hvilket giver dig de bedste resultater.",
    TRADE_EARN_JOIN: "Handel, tjen og tilmeld dig vores samfund, drager fordel af en80 belønninger.",
    TRADE_NOW: "Handel nu",
    TRADE_REGISTRATION_HASSLE: "Handle noget. Ingen registrering, ingen besvær",
    TRANSACTION_REVERT: "Eller transaktionen vender tilbage.",
    TROUBLE_SHOOT: "FREMPRESSE",
    TRUSTED_WORLD: "Betroet over hele verden",
    UNLOCK_WALLET: "Lås op tegnebog",
    UNSTAKE_TOKENS: "Upart tokens",
    VIEW_ETHERSCAN: "Udsigt til BscScan",
    YOU_RECEIVE: "Du vil modtage",
    //Exchange and Liquidity
    ADD: "Tilføje",
    ADD_LIQUIDITY: "Tilføj likviditet",
    AMOUNT: "Beløb",
    BALANCE: "Balance:",
    CLICK_SUPPLY_REVIEW: "Når du er tilfreds med den sats, skal du klikke på Supply for at gennemgå.",
    CONNECT_WALLET_LIQUIDITY: "Opret forbindelse til en tegnebog for at se din likviditet.",
    CREATE_PAIR: "Opret par",
    CREATE_POOL: "Opret pool.",
    DONT_HAVE_LIQUIDITY: "Du har ikke likviditet i denne pool endnu.",
    DONT_SEE_POOL_JOINED: "Kan du ikke se en pool, du kom med?",
    ENABLE: "Aktivér",
    ENTER_AMOUNT: "Indtast beløb",
    ENTER_AMOUNT_S: "Indtast beløb",
    FIRST_LIQUIDITY_PROVIDER: "Du er den første likviditetsudbyder.",
    FOR: "til",
    FROM: "Fra",
    IMPORT_IT: "Importer det.",
    IMPORT_POOL: "Importpool",
    INSUFFICIENT: "Utilstrækkelig",
    INSUFFICIENT_BALANCE: "Utilstrækkelig balance",
    INSUFFICIENT_LIQUIDITY: "Utilstrækkelig likviditet til denne handel.",
    LIQUIDITY_PROV_FEE: "Gebyr for likviditetsudbyder:",
    LIQUIDITY_REWARDS: "Likviditetsudbyder belønner",
    LP_TOKENS_IN_WALLET: "LP -tokens i din tegnebog",
    MINUTES: "Minutter",
    NO_LIQUTITY_LIST: "Ingen liqutity -liste",
    NO_POOL_FOUND: "Ingen pool fundet.",
    PER: "om",
    POOL: "Pool",
    POOL_FOUND: "Pool fundet!",
    POOL_SHARE: "Din poolandel:",
    POOL_TOKENS: "Dine pool -tokens:",
    PRICE_IMPACTS: "Prispåvirkning:",
    PRICE_IMPACT_HIGH: "Prispåvirkning til høj",
    RATES: "Satser",
    RATIO_PRICE_POOL: "Forholdet mellem tokens, du tilføjer, indstiller prisen på denne pool.",
    RECENT_TRANSACTIONS: "Seneste transaktioner",
    REMOVE: "Fjerne",
    REMOVE_LIQUIDITY: "Fjern likviditeten",
    REMOVING_POOL_TOKENS: "Fjernelse af pooltokens konverterer din position tilbage til underliggende tokens til den aktuelle hastighed, proportional med din andel af poolen. Påløbne gebyrer er inkluderet i de beløb, du modtager.",
    ROUTE: "Rute:",
    SELECT_A_TOKEN: "Vælg et token",
    SETTINGS: "Indstillinger",
    SHARE_OF_POOL: "Andel af pool:",
    SLIPPAGE_TOL: "Glidetolerance",
    SLIPPAGE_TOLERANCE: "Glidningstolerance:",
    SUPPLY: "Levere",
    SWAP: "Bytte rundt",
    TO: "Til",
    TOKEN_FARM_UNSTAKE: "Eller, hvis du stak dine Dopamine-LP-symboler på en gård, skal du fjerne dem for at se dem her.",
    TRANSACTION_DEADLINE: "Transaktionsfrist",
    TRANSACTION_NOT_FOUND: "Trancation ikke fundet",
    TRANSACTION_REVERT_PERCENTAGE: "Din trasaktion vender tilbage, hvis prisen ændres ugunstigt med mere end denne procentdel",
    WITHDRAW_LIQUIDITY: "Likviditetsudbydere tjener et gebyr på 0.25% på alle handler, der er proportionalt med deres andel af poolen. Gebyrer føjes til poolen, påløber i realtid og kan kræves ved at trække din likviditet tilbage.",
    YOUR_LIQUIDITY: "Din likviditet",
    bALANCE: "balance",
    MAXIMUM_SOLD: "Maksimalt solgt",
    MINIMUM_RECEIVED_C: "Minimum modtaget",
    TOKEN_NAME: "Token navn",
    FOUND_MY_ADDRESS: "Fundet efter adresse",
    IMPORT_TOKEN: "Import token",
    SEARCH_NAME: "Søg efter navn",

    AND: "og",
    CONFIRM_SUPPLY: "Bekræft forsyning",
    CONFIRM_TRANSACTION_WALLET: "Bekræft denne transaktion i din tegnebog",
    CONNECT_WALLETTT: "Tilslut tegnebogen",
    DEPOSITED: "Deponeret:",
    DISMISS: "Afskedige",
    ERROR: "Fejl",
    INPUT_ESTIMATED_SELL_MOST: "Input estimeres. Du vil højst sælge",
    LEARN_ABOUT_WALLETS: "Lær mere om tegnebøger",
    NEW_TO_ETHEREUM: "Ny på Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Output estimeres. Hvis prisen ændrer sig med mere end",
    PRICEE: "Pris:",
    SUPPLYING: "Leverer",
    SWAPPING: "Bytter",
    TRANSACTION_REJECTED: "Transaktion afvist",
    TRANSACTION_SUBMITTED: "Transaktion indsendt",
    VIEW_ON_BSCSCAN: "Vis på BSCSCAN",
    WAITING_CONFIRMATION: "Venter på bekræftelse",
    YOUR_TRANSACTION_REVERT: "Din transaktion vil vende tilbage.",
    YOU_WILL_RECEIVE: "Du vil modtage :",
    PARTNERSHIPS: "Partnerskaber",
}