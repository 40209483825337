let key = {
  netWorkUrl: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
  NetworkId: 97,
  Factory: "0x60C793b4F4559f683FEd9d24F4DF56F0f8DfAb65",
  ETHSYMBOL: "BNB"
};


if (process.env.NODE_ENV === "production") {
  key = {
    rpcurl: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
    liqutityfee: 0.25,
    FrontendUrl: "https://dopaminedex.wealwindemo.com/",
    baseUrl: "https://dopaminedexapi.wealwindemo.com",
    farmImg: "https://dopaminedexapi.wealwindemo.com/farms/",
    imageUrl: "https://dopaminedexapi.wealwindemo.com/",///
    // imageUrl: "https://dopaminedex.wealwindemo.com/images/question.svg",///
    BUSD: "0x2121606905753D5E7059db6776d98187A214539F",
    NetworkId: 97,
    networkName: "Binance chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    walletlink: "https://mainnet-infura.wallet.coinbase.com",
    walletconnect: "003dd3bbc4d947eb9e9c1870a5958806",
    fortmatic: "pk_live_BD23844E856FE115",
    portis: "cdb771b8-d780-4ca5-bb0a-588e225760f0",
    // defaultLogo: "https://dopaminedex.wealwindemo.com/images/question.svg",
    defaultLogo: "https://dopaminedexapi.wealwindemo.com/tokens/default.png",
    netWorkUrl: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
    limit: 6,
    ETHSYMBOL: "BNB",
    Router: "0xcf260959b5C3F3DB5028212cd105b2a1D73f6a74",//
    MasterChef:"0x8F91A4a04A3Ef05782E99f43A07c226313a2948f",
    Factory: "0x60C793b4F4559f683FEd9d24F4DF56F0f8DfAb65",//
    DopamineToken: "0xBbf254a340a72fC14aB31748c8444f521dCfD08e",//
    Ethax_Busd_LP: "0xe02CE7810dfEe940469B08010b8bD59EFC950fc4",//
    Ethax_Bnb_LP: "0x71b854Ec56b1F2678E1ae614936d7b2a34C30185",//
    WBNB: "0x64678728EdA0d78772086010b133102e6E485c73"//
  };
} else {
  key = {
    rpcurl: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    liqutityfee: 0.25,
    FrontendUrl: "http://localhost:3000/",
    baseUrl: "http://localhost:3533",//
    farmImg:"http://localhost:3533/farms/",
    // imageUrl: "https://dopaminedex.wealwindemo.com/images/question.svg",///
    imageUrl: "http://localhost:3533/",///
    BUSD: "0x2121606905753D5E7059db6776d98187A214539F",
    NetworkId: 97,
    networkName: "Binance chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    walletlink: "https://mainnet-infura.wallet.coinbase.com",
    walletconnect: "003dd3bbc4d947eb9e9c1870a5958806",
    fortmatic: "pk_live_BD23844E856FE115",
    portis: "cdb771b8-d780-4ca5-bb0a-588e225760f0",
    // defaultLogo: "https://dopaminedex.wealwindemo.com/images/question.svg",
    defaultLogo: "https://dopaminedexapi.wealwindemo.com/tokens/default.png",
    netWorkUrl: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    limit: 6,
    ETHSYMBOL: "BNB",
    Router: "0xcf260959b5C3F3DB5028212cd105b2a1D73f6a74",//
    MasterChef:"0x8F91A4a04A3Ef05782E99f43A07c226313a2948f",
    Factory: "0x60C793b4F4559f683FEd9d24F4DF56F0f8DfAb65",//
    DopamineToken: "0xBbf254a340a72fC14aB31748c8444f521dCfD08e",//
    Ethax_Busd_LP: "0xe02CE7810dfEe940469B08010b8bD59EFC950fc4",//
    Ethax_Bnb_LP: "0x71b854Ec56b1F2678E1ae614936d7b2a34C30185",//
    WBNB: "0x64678728EdA0d78772086010b133102e6E485c73"//
  };
}
export default key;