export default {
    ABOUT: "关于",
    ADVANCED_TOOLS: "交易者的高级工具",
    APPROVE: "批准",
    APY: "APY",
    AUDITS: "审核",
    BAL: "BAL：",
    BITCOIN_C: "比特币",
    BITCOIN_S: "比特币",
    BRAND_BLOG: "品牌博客",
    BURNED_TOKEN: "燃烧的令牌",
    BUY_FARM_SPEND: "购买，耕种，花费它并利用它！",
    BUY_ONE80_TOKENS: "购买Dopamine令牌",
    CANCEL: "取消",
    CIRCULATE_SUPPLY: "循环供应",
    CLOSE: "关",
    COMMUNITY: "社区",
    COMMUNITY_JOIN: "Dopamine正在改变Defi世界，我们的社区和开发商正在创建一个没有寄宿生的财务世界，现在和未来。加入我们！",
    CONFIRM: "确认",
    CONFIRM_SWAP: "确认交换",
    CONNECT_WALLET: "连接钱包",
    CONNECT_WALLET_TRADE_TOKEN: "连接您的钱包，在几秒钟内用ETH交换任何令牌。",
    CONTRIBUTORS: "贡献者",
    CRYPTO_GROW: "看着您的加密货币成长！",
    CUSTOMER_SUPPORT: "客户支持",
    DEPOSIT_FEE: "存款费：",
    DETAILS: "细节",
    DEVELOPERS: "开发人员",
    DISCONNECT_WALLET: "断开钱包",
    DOCUMENTATION: "文档",
    EARNED: "赢得了",
    EARN_PASSIVE_INCOME: "用Dopamine赚取24/7的被动收入",
    EARN_WHILE_SLEEP: "睡觉时赚钱！",
    ETHEREUM: "以太坊",
    EXCHANGE: "交换",
    FARMS: "农场",
    FARMS_POOLS: "农场和游泳池",
    FINISHED: "完成的",
    GITHUB: "github",
    GUIDES: "向导",
    HARVEST: "收成",
    HEART_ECOSYSTEM: "Dopamine令牌是我们生态系统的核心。",
    HELP: "帮助",
    IDEAS_LAB: "创意实验室",
    JOIN_ONE80: "加入Dopamine Defi Revolution。",
    LAUNCH_APP: "启动应用程序",
    LIQUIDITY: "流动性",
    LIQUIDITY_PROVIDER_FEE: "流动性提供商费",
    LITE_PAPER: "Lite纸",
    LIVE: "居住",
    LOADING: "加载...",
    LOAD_MORE: "装载更多",
    MARKET_CAP: "市值",
    MAX: "最大限度",
    MINIMUM_RECEIVED: "最低收到",
    MULTICHAIN_INSTANT_ACCESS: "多章即将推出，使您可以立即访问一个加密世界。",
    NO_FARMS: "没有农场",
    NO_POOLS: "没有游泳池",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "All，HODL，Exchange and Trade 24/7的Dopamine福利。",
    ONE80_EARNED: "赚Dopamine",
    ONE80_MAKES_EASY: "Dopamine使您的加密货币适合您很容易。",
    ONE80_WORLD: "Dopamine使我们的世界转向环绕。",
    OUTPUT_ESTIMATED: "估计输出。如果价格变化超过0.8％，您的交易将恢复。",
    OUTPUT_RECEIVE_ATLEAST: "估计输出。您至少会收到",
    PARTHER_BURNED: "LP Parther/Busd燃烧",
    POOLS: "游泳池",
    PRICE: "价格",
    PRICE_IMPACT: "价格影响",
    READABLE_CONTENT: "漫长的事实是，读者将因可读内容而分心。",
    SEARCH_FARMS: "搜索农场",
    SEARCH_POOLS: "搜索池",
    SORT_BY: "排序方式",
    STAKE: "赌注：",
    STAKED_ONLY: "仅粘贴",
    STAKE_EARN_ONE80: "股份代币赚取Dopamine",
    STAKE_ONE80: "Stake LP代币赚取Dopamine",
    STAKE_TOKENS: "股份令牌",
    START_EARNING: "开始赚钱",
    SUCCESS_COMPLETE: "成功完成",
    SUSTAIN: "可持续性",
    TOKEN_PRICE: "令牌价格",
    TOTAL_LIQUIDITY: "总流动性：",
    TOTAL_STAKE: "总固定：",
    TOTAL_STAKED_S: "总固定",
    TOTAL_SUPPLY: "总供应",
    TRADERS_CHOOSE_ONE80: "交易者选择Dopamine来自动将交易和路线交易与最佳DEX交换，从而为您带来最佳效果。",
    TRADE_EARN_JOIN: "贸易，赚钱和加入我们的社区，从一一奖励中受益。",
    TRADE_NOW: "现在交易",
    TRADE_REGISTRATION_HASSLE: "交易任何东西。没有注册，没有麻烦",
    TRANSACTION_REVERT: "否则交易将恢复。",
    TROUBLE_SHOOT: "麻烦拍摄",
    TRUSTED_WORLD: "全世界信任的",
    UNLOCK_WALLET: "解锁钱包",
    UNSTAKE_TOKENS: "删除令牌",
    VIEW_ETHERSCAN: "查看Bscscan",
    YOU_RECEIVE: "你会收到的",
    //Exchange and Liquidity
    ADD: "添加",
    ADD_LIQUIDITY: "增加流动性",
    AMOUNT: "数量",
    BALANCE: "平衡：",
    CLICK_SUPPLY_REVIEW: "一旦您满意，请点击供应供应供应。",
    CONNECT_WALLET_LIQUIDITY: "连接到钱包以查看您的流动性。",
    CREATE_PAIR: "创建对",
    CREATE_POOL: "创建池。",
    DONT_HAVE_LIQUIDITY: "您在这个池中还没有流动性。",
    DONT_SEE_POOL_JOINED: "看不到您加入的游泳池吗？",
    ENABLE: "使能够",
    ENTER_AMOUNT: "输入金额",
    ENTER_AMOUNT_S: "输入金额",
    FIRST_LIQUIDITY_PROVIDER: "您是第一个流动性提供商。",
    FOR: "为了",
    FROM: "从",
    IMPORT_IT: "导入它。",
    IMPORT_POOL: "进口池",
    INSUFFICIENT: "不足的",
    INSUFFICIENT_BALANCE: "平衡不足",
    INSUFFICIENT_LIQUIDITY: "这项交易流动性不足。",
    LIQUIDITY_PROV_FEE: "流动性提供商费：",
    LIQUIDITY_REWARDS: "流动性提供商奖励",
    LP_TOKENS_IN_WALLET: "LP代币中的钱包",
    MINUTES: "分钟",
    NO_LIQUTITY_LIST: "没有液体清单",
    NO_POOL_FOUND: "找不到游泳池。",
    PER: "每",
    POOL: "水池",
    POOL_FOUND: "游泳池找到了！",
    POOL_SHARE: "您的游泳池分享：",
    POOL_TOKENS: "您的游泳池令牌：",
    PRICE_IMPACTS: "价格影响：",
    PRICE_IMPACT_HIGH: "价格影响高",
    RATES: "费率",
    RATIO_PRICE_POOL: "您添加的令牌比率将设定此池的价格。",
    RECENT_TRANSACTIONS: "最近的交易",
    REMOVE: "消除",
    REMOVE_LIQUIDITY: "删除流动性",
    REMOVING_POOL_TOKENS: "删除游泳池令牌将您的位置以当前的速率转换为基础令牌，与您在池的份额成正比。您收到的金额包括应计费。",
    ROUTE: "路线：",
    SELECT_A_TOKEN: "选择一个令牌",
    SETTINGS: "设置",
    SHARE_OF_POOL: "分享池：",
    SLIPPAGE_TOL: "滑移公差",
    SLIPPAGE_TOLERANCE: "滑移耐受性：",
    SUPPLY: "供应",
    SWAP: "交换",
    TO: "至",
    TOKEN_FARM_UNSTAKE: "或者，如果您将Dopamine-LP令牌放在农场中，请取消种植它们在这里看到它们。",
    TRANSACTION_DEADLINE: "交易截止日期",
    TRANSACTION_NOT_FOUND: "找不到Trancation",
    TRANSACTION_REVERT_PERCENTAGE: "如果价格不利于这个百分比",
    WITHDRAW_LIQUIDITY: "流动性提供商的所有交易与其在游泳池份额成正比的所有交易中获得0.25%％的费用。费用被添加到游泳池中，实时积累，可以通过撤回流动性来要求。",
    YOUR_LIQUIDITY: "您的流动性",
    bALANCE: "平衡",
    MAXIMUM_SOLD: "最大销售量",
    MINIMUM_RECEIVED_C: "最低接收",
    TOKEN_NAME: "令牌名称",
    FOUND_MY_ADDRESS: "通过地址找到",
    IMPORT_TOKEN: "导入令牌",
    SEARCH_NAME: "按名称或地址搜索",

    AND: "和",
    CONFIRM_SUPPLY: "确认供应",
    CONFIRM_TRANSACTION_WALLET: "在您的钱包中确认此交易",
    CONNECT_WALLETTT: "连接钱包",
    DEPOSITED: "沉积：",
    DISMISS: "解雇",
    ERROR: "错误",
    INPUT_ESTIMATED_SELL_MOST: "估计输入。您最多会出售",
    LEARN_ABOUT_WALLETS: "了解有关钱包的更多信息",
    NEW_TO_ETHEREUM: "以太坊的新手？",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "估计输出。如果价格变化超过",
    PRICEE: "价格：",
    SUPPLYING: "供应",
    SWAPPING: "交换",
    TRANSACTION_REJECTED: "交易被拒绝",
    TRANSACTION_SUBMITTED: "交易提交",
    VIEW_ON_BSCSCAN: "在BSCSCAN上查看",
    WAITING_CONFIRMATION: "等待确认中",
    YOUR_TRANSACTION_REVERT: "您的交易将恢复。",
    YOU_WILL_RECEIVE: "您将收到 ：",
    PARTNERSHIPS: "伙伴关系",
}