export default {
    ABOUT: "Noin",
    ADVANCED_TOOLS: "Edistyneitä työkaluja kauppiaille",
    APPROVE: "Hyväksyä",
    APY: "Apy",
    AUDITS: "Tarkastus",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Tuotemerkkiblogi",
    BURNED_TOKEN: "Poltettu merkki",
    BUY_FARM_SPEND: "Osta se, maatila, vie se ja ota se!",
    BUY_ONE80_TOKENS: "Osta Dopamine -merkkejä",
    CANCEL: "Peruuttaa",
    CIRCULATE_SUPPLY: "Kiertävä tarvike",
    CLOSE: "kiinni",
    COMMUNITY: "Yhteisö",
    COMMUNITY_JOIN: "Dopamine muuttaa DEFI -maailmaa, yhteisömme ja kehittäjät luovat finanssimaailman ilman rajat ylittäviä, defi -kaupankäyntialustaan ​​nyt ja tulevaisuuteen. Liity meihin!",
    CONFIRM: "Vahvistaa",
    CONFIRM_SWAP: "Vahvista vaihto",
    CONNECT_WALLET: "Yhdistä lompakkoon",
    CONNECT_WALLET_TRADE_TOKEN: "Yhdistä lompakkosi, vaihda kaikki merkit ETH: stä sekunneissa.",
    CONTRIBUTORS: "Avustajat",
    CRYPTO_GROW: "Katso kryptosi kasvavan!",
    CUSTOMER_SUPPORT: "Asiakaspalvelu",
    DEPOSIT_FEE: "Talletusmaksu:",
    DETAILS: "Yksityiskohdat",
    DEVELOPERS: "Kehittäjät",
    DISCONNECT_WALLET: "Irrota lompakko",
    DOCUMENTATION: "Dokumentointi",
    EARNED: "Ansaittu",
    EARN_PASSIVE_INCOME: "Ansaitse passiivisia tuloja ympäri vuorokauden ja omalla80: lla",
    EARN_WHILE_SLEEP: "Ansaitse nukkuessasi!",
    ETHEREUM: "Binance",
    EXCHANGE: "Vaihto",
    FARMS: "Maatilat",
    FARMS_POOLS: "Maatilat",
    FINISHED: "Valmis",
    GITHUB: "Github",
    GUIDES: "Oppaat",
    HARVEST: "Sato",
    HEART_ECOSYSTEM: "Dopamine -tunnus on ekosysteemimme ytimessä.",
    HELP: "auta",
    IDEAS_LAB: "Ideoita laboratorio",
    JOIN_ONE80: "Liity Dopamine Defi -vallankumoukseen.",
    LAUNCH_APP: "Käynnistyssovellus",
    LIQUIDITY: "Likviditeetti",
    LIQUIDITY_PROVIDER_FEE: "Likviditeettipalvelun maksu",
    LITE_PAPER: "Litepaperi",
    LIVE: "Elää",
    LOADING: "Ladataan...",
    LOAD_MORE: "Lataa lisää",
    MARKET_CAP: "Markkina-arvo",
    MAX: "Max",
    MINIMUM_RECEIVED: "Vähimmäis vastaanotettu",
    MULTICHAIN_INSTANT_ACCESS: "Multichain tulee pian, mikä antaa sinulle välittömän pääsyn salausmaailmaan.",
    NO_FARMS: "Ei tiloja",
    NO_POOLS: "Ei uima -altaat",
    ONE80: "Yksi 80",
    ONE80_BENEFITS: "Dopamine -hyöty kaikille, HODL, vaihto ja kauppa ympäri vuorokauden.",
    ONE80_EARNED: "Dopamine ansaittu",
    ONE80_MAKES_EASY: "Dopamine: n avulla on helppo saada salaus toimimaan sinulle.",
    ONE80_WORLD: "Dopamine saa maailman kiertämään.",
    OUTPUT_ESTIMATED: "Tulos arvioidaan. Jos hinta muuttuu yli 0,8%, tapahtuma palautuu.",
    OUTPUT_RECEIVE_ATLEAST: "Tulos arvioidaan. Saat ainakin",
    PARTHER_BURNED: "LP Parther/Busd poltettu",
    POOLS: "Uima -allas",
    PRICE: "Hinta",
    PRICE_IMPACT: "Hintavaikutus",
    READABLE_CONTENT: "On jo pitkään vakiintunut tosiasia, että lukija häiritsee luettavissa oleva sisältö.",
    SEARCH_FARMS: "Etsi maatilat",
    SEARCH_POOLS: "Hakualtaat",
    SORT_BY: "Järjestä",
    STAKE: "Panos:",
    STAKED_ONLY: "Vain pistänyt",
    STAKE_EARN_ONE80: "Stake Tokens ansaita yksi80",
    STAKE_ONE80: "Stake LP -merkit ansaitakseen Dopamine: n",
    STAKE_TOKENS: "Vaarmamerkit",
    START_EARNING: "Alkaa ansaita",
    SUCCESS_COMPLETE: "Onnistuneesti suoritettu",
    SUSTAIN: "Kestävyys",
    TOKEN_PRICE: "Rahoitushinta",
    TOTAL_LIQUIDITY: "Kokonais likviditeetti:",
    TOTAL_STAKE: "Kokonaisvaruste:",
    TOTAL_STAKED_S: "Kokonaisarvoinen",
    TOTAL_SUPPLY: "Kokonaismäärä",
    TRADERS_CHOOSE_ONE80: "Kauppiaat valitsevat Dopamine: n vaihtamaan ja reitittämään kauppoja automaattisesti parhaisiin DEX -arvoihin, mikä antaa sinulle parhaat tulokset.",
    TRADE_EARN_JOIN: "Vaihda, ansaitse ja liittyä yhteisöömme, hyöty Dopamine -palkinnoista.",
    TRADE_NOW: "Kauppa nyt",
    TRADE_REGISTRATION_HASSLE: "Vaihda mitä tahansa. Ei rekisteröintiä, ei vaivaa",
    TRANSACTION_REVERT: "tai tapahtuma palautuu.",
    TROUBLE_SHOOT: "Vaivaa ampua",
    TRUSTED_WORLD: "Luotettava maailmanlaajuisesti",
    UNLOCK_WALLET: "Avaa lompakko",
    UNSTAKE_TOKENS: "Irrottaa rahakkeet",
    VIEW_ETHERSCAN: "Näkymä BscScan",
    YOU_RECEIVE: "Saat",
    //Exchange and Liquidity
    ADD: "Lisätä",
    ADD_LIQUIDITY: "Lisätä likviditeettiä",
    AMOUNT: "Määrä",
    BALANCE: "Saldo:",
    CLICK_SUPPLY_REVIEW: "Kun olet tyytyväinen tarkistamiseen, napsauta Suution.",
    CONNECT_WALLET_LIQUIDITY: "Yhdistä lompakkoon nähdäksesi likviditeettisi.",
    CREATE_PAIR: "Luoda pari",
    CREATE_POOL: "Luo uima -allas.",
    DONT_HAVE_LIQUIDITY: "Sinulla ei ole vielä likviditeettiä tässä uima -altaassa.",
    DONT_SEE_POOL_JOINED: "Etkö näe uima -allasta, johon liittit?",
    ENABLE: "ota käyttöön",
    ENTER_AMOUNT: "Syötä summa",
    ENTER_AMOUNT_S: "Syötä summa",
    FIRST_LIQUIDITY_PROVIDER: "Olet ensimmäinen likviditeettitoimittaja.",
    FOR: "puolesta",
    FROM: "-Sta",
    IMPORT_IT: "Tuo se.",
    IMPORT_POOL: "Tuontiyhdistelmä",
    INSUFFICIENT: "Riittämätön",
    INSUFFICIENT_BALANCE: "Riittämätön tasapaino",
    INSUFFICIENT_LIQUIDITY: "Riittämätön likviditeetti tälle kaupalle.",
    LIQUIDITY_PROV_FEE: "Likviditeetin tarjoajan maksu:",
    LIQUIDITY_REWARDS: "Likviditeettipalveluntarjoajan palkkiot",
    LP_TOKENS_IN_WALLET: "LP -tokenit lompakossa",
    MINUTES: "Pöytäkirja",
    NO_LIQUTITY_LIST: "Ei liekitysluetteloa",
    NO_POOL_FOUND: "Ei uima -allasta löydetty.",
    PER: "-",
    POOL: "Uima -allas",
    POOL_FOUND: "Uima -allas löytyy!",
    POOL_SHARE: "Uima -allasosuus:",
    POOL_TOKENS: "Uima -altaan rahakkeet:",
    PRICE_IMPACTS: "Hintavaikutus:",
    PRICE_IMPACT_HIGH: "Hintavaikutus korkealle",
    RATES: "Hinnat",
    RATIO_PRICE_POOL: "Lisäämäsi rahakkeiden suhde asettaa tämän poolin hinnan.",
    RECENT_TRANSACTIONS: "Viimeaikaiset tapahtumat",
    REMOVE: "Poista",
    REMOVE_LIQUIDITY: "Poista likviditeetti",
    REMOVING_POOL_TOKENS: "Pool -rahakkeiden poistaminen muuntaa asemasi takaisin taustalla oleviin rahakkeiksi nykyisellä nopeudella, verrannollinen uima -altaan osuuteen. Kertyneet maksut sisältyvät saamiin määriin.",
    ROUTE: "Reitti:",
    SELECT_A_TOKEN: "Valitse tunnusmerkki",
    SETTINGS: "asetukset",
    SHARE_OF_POOL: "Osuus uima -altaasta:",
    SLIPPAGE_TOL: "Liukumistoleranssi",
    SLIPPAGE_TOLERANCE: "Liukua toleranssi:",
    SUPPLY: "Toimittaa",
    SWAP: "Vaihtaa",
    TO: "-Lla",
    TOKEN_FARM_UNSTAKE: "Tai jos olet panostanut Dopamine-LP-rahakkeesi tilalle, poista ne näkemään ne täällä.",
    TRANSACTION_DEADLINE: "Tapahtuman määräaika",
    TRANSACTION_NOT_FOUND: "Tracationia ei löydy",
    TRANSACTION_REVERT_PERCENTAGE: "Tracation palautuu, jos hinta muuttuu epäsuotuisasti enemmän kuin tämä prosenttiosuus",
    WITHDRAW_LIQUIDITY: "Likviditeetin tarjoajat ansaitsevat 0.25%%: n maksun kaikista kaupoista, jotka ovat verrannollisia uima -altaan osuuteen. Maksut lisätään uima -altaaseen, kertyy reaaliajassa, ja niitä voidaan vaatia peruuttamalla likviditeettisi.",
    YOUR_LIQUIDITY: "Likviditeettisi",
    bALANCE: "saldo",
    MAXIMUM_SOLD: "Maksimi myyty",
    MINIMUM_RECEIVED_C: "Minimi vastaanotettu",
    TOKEN_NAME: "Merkkinimi",
    FOUND_MY_ADDRESS: "Löydetty osoitteessa",
    IMPORT_TOKEN: "Tuontitunnus",
    SEARCH_NAME: "Hae nimien tai osoitteen perusteella",

    AND: "ja",
    CONFIRM_SUPPLY: "Vahvista tarjonta",
    CONFIRM_TRANSACTION_WALLET: "Vahvista tämä tapahtuma lompakossasi",
    CONNECT_WALLETTT: "Kytke lompakko",
    DEPOSITED: "Talletettu:",
    DISMISS: "Hylätä",
    ERROR: "Virhe",
    INPUT_ESTIMATED_SELL_MOST: "Tulo arvioidaan. Myyt korkeintaan",
    LEARN_ABOUT_WALLETS: "Lisätietoja lompakoista",
    NEW_TO_ETHEREUM: "UUSI Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Tulos arvioidaan. Jos hinta muuttuu enemmän kuin",
    PRICEE: "Hinta:",
    SUPPLYING: "Toimittaja",
    SWAPPING: "Vaihtaminen",
    TRANSACTION_REJECTED: "Tapahtuma hylätty",
    TRANSACTION_SUBMITTED: "Tapahtuma toimitettu",
    VIEW_ON_BSCSCAN: "Näkymä BSCScaniin",
    WAITING_CONFIRMATION: "odottaa vahvistusta",
    YOUR_TRANSACTION_REVERT: "Tapahtumasi palaa.",
    YOU_WILL_RECEIVE: "Sinä tulet vastaanottamaan :",
    PARTNERSHIPS: "Kumppanuudet",
}