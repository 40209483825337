export default {
    // FARMS
    "FARMS": "Farms",
    "STAKE_ONE80": "Stake LP tokens to Earn Dopamine",
    "STAKED_ONLY": "Staked only",
    "LIVE": "Live",
    "FINISHED": "Finished",
    "SEARCH_FARMS": "Search Farms",
    "SORT_BY": "Sort By",
    "EARNED": "Earned",
    "TOTAL_STAKED_S": "Total staked",
    "APY": "APY",
    "HARVEST": "Harvest",
    "DEPOSIT_FEE": "Deposit Fee:",
    "START_EARNING": "Staked Amount",
    "UNLOCK_WALLET": "Connect Wallet",
    "APPROVE": "Approve",
    "DETAILS": "Details",
    "STAKE": "Stake:",
    "TOTAL_LIQUIDITY": "Total Liquidity:",
    "VIEW_ETHERSCAN": "View on BscScan",
    "LOAD_MORE": "Load More",
    "NO_FARMS": "No Farms",
    "LOADING": "Loading...",
    "YOU_RECEIVE": "You will receive",
    "BITCOIN_S": "Bitcoin",
    "BITCOIN_C": "BITCOIN",
    "ETHEREUM": "Binance",
    "OUTPUT_ESTIMATED": "Output is estimated. If the price changes by more than 0.8% your transaction will revert.",
    "PARTHER_BURNED": "LP PARTHER/BUSD Burned",
    "PRICE": "Price",
    "CONFIRM": "Confirm",
    "CONFIRM_SWAP": "Confirm Swap",
    "OUTPUT_RECEIVE_ATLEAST": "Output is estimated. you will receive at least",
    "TRANSACTION_REVERT": "or the transaction will revert.",
    "MINIMUM_RECEIVED": "Minimum received",
    "PRICE_IMPACT": "Price Impact",
    "LIQUIDITY_PROVIDER_FEE": "Liquidity Provider Fee",
    "SUCCESS_COMPLETE": "Successfully Completed",
    "READABLE_CONTENT": "It is a long established fact that a reader will be distracted by the readable content.",
    "CLOSE": "Close",
    "UNSTAKE_TOKENS": "Unstake Tokens",
    "BAL": "Bal:",
    "MAX": "Max",
    "CANCEL": "Cancel",
    "STAKE_TOKENS": "Stake Tokens",
    // POOLS
    "POOLS": "Pools",
    "STAKE_EARN_ONE80": "Stake tokens to Earn Dopamine",
    "SEARCH_POOLS": "Search Pools",
    "ONE80_EARNED": "Dopamine STAKED",
    "ONE80": "Dopamine",
    "TOTAL_STAKE": "Total Staked:",
    "NO_POOLS": "No Pools",
    //HOME
    "JOIN_ONE80": "Join the Dopamine DeFi revolution.",
    "TRADE_EARN_JOIN": "Trade, earn and join our community, benefit from Dopamine rewards.",
    "LAUNCH_APP": "Launch App",
    "TRUSTED_WORLD": "Trusted worldwide",
    "ONE80_BENEFITS": "Dopamine benefits for all, HODL, exchange and trade 24/7.",
    "BUY_ONE80_TOKENS": "Buy Dopamine tokens",
    "CRYPTO_GROW": "Watch your crypto grow!",
    "TRADE_REGISTRATION_HASSLE": "Trade anything. No registration, No hassle",
    "CONNECT_WALLET_TRADE_TOKEN": "Connect your wallet, trade any token on Eth in seconds.",
    "MULTICHAIN_INSTANT_ACCESS": "Multichain coming soon, giving you instant access to a world of crypto.",
    "TRADE_NOW": "Trade Now",
    "ADVANCED_TOOLS": "Advanced tools for traders",
    "TRADERS_CHOOSE_ONE80": "Traders choose Dopamine to automatically exchange and route trades to the best DEXs, giving you the best results.",
    "EARN_PASSIVE_INCOME": "Earn Passive Income 24/7 with Dopamine",
    "ONE80_MAKES_EASY": "Dopamine makes it easy to make your crypto work for you.",
    "EARN_WHILE_SLEEP": "Earn while you sleep!",
    "FARMS_POOLS": "Farms and Pools",
    "TOTAL_SUPPLY": "Total Supply",
    "BURNED_TOKEN": "Burned Token",
    "MARKET_CAP": "Market cap",
    "CIRCULATE_SUPPLY": "Circulating Supply",
    "TOKEN_PRICE": "Token Price",
    "ONE80_WORLD": "Dopamine makes our world go round.",
    "HEART_ECOSYSTEM": "Dopamine token is at the heart of our ecosystem.",
    "BUY_FARM_SPEND": "Buy it, farm it, spend it and stake it!",
    //HEADER
    "EXCHANGE": "Exchange",
    "LIQUIDITY": "Liquidity",
    "DISCONNECT_WALLET": "Disconnect Wallet",
    "CONNECT_WALLET": "Connect Wallet",
    //FOOTER
    "COMMUNITY_JOIN": "Dopamine is transforming the DeFI world, our community and developers are creating a financial world without boarders, DeFI trading platform for now and the future. Join us! ",
    "ABOUT": "About",
    "BRAND_BLOG": "Brand Blog",
    "COMMUNITY": "Community",
    "SUSTAIN": "Sustainability",
    "LITE_PAPER": "Lite paper",
    "HELP": "Help",
    "CUSTOMER_SUPPORT": "Customer Support",
    "TROUBLE_SHOOT": "Trouble Shoot",
    "DEVELOPERS": "Developers",
    "GITHUB": "Github",
    "DOCUMENTATION": "Documentation",
    "AUDITS": "Audits",
    "CONTRIBUTORS": "Contributors",
    "IDEAS_LAB": "Ideas Lab",
    "GUIDES": "Guides",
    //Exchange
    "FROM": "From",
    "BALANCE": "Balance: ",
    "TO": "To",
    "INSUFFICIENT_LIQUIDITY": "Insufficient liquidity for this trade.",
    "ENTER_AMOUNT": "Enter Amount",
    "INSUFFICIENT_BALANCE": "Insufficient balance",
    "PRICE_IMPACT_HIGH": "Price impact to high",
    "ENABLE": "Enable",
    "SWAP": "Swap",
    "SLIPPAGE_TOLERANCE": "Slippage Tolerance:",
    "LIQUIDITY_PROV_FEE": "Liquidity Provider Fee:",
    "PRICE_IMPACTS": "Price Impact:",
    "ROUTE": "Route:",
    //Slippage Model
    "SETTINGS": "Settings",
    "SLIPPAGE_TOL": "Slippage tolerance",
    "TRANSACTION_REVERT_PERCENTAGE": "Your transaction will revert if the price changes unfavourably by more than this percentage",
    "MINUTES": "Minutes",
    "TRANSACTION_DEADLINE": "Transaction deadline",
    //LIQUIDITY
    "LIQUIDITY_REWARDS": "Liquidity provider rewards",
    "WITHDRAW_LIQUIDITY": "Liquidity providers earn a 0.25% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.",
    "ADD_LIQUIDITY": "Add Liquidity",
    "YOUR_LIQUIDITY": "Your Liquidity",
    "CREATE_PAIR": "Create Pair",
    "CONNECT_WALLET_LIQUIDITY": "Connect to a wallet to view your liquidity.",
    "POOL": "Pool",
    "POOL_TOKENS": "Your pool tokens :",
    "POOL_SHARE": "Your pool share :",
    "ADD": "Add",
    "REMOVE_LIQUIDITY": "Remove Liquidity",
    "NO_LIQUTITY_LIST": "No Liquitity list",
    "DONT_SEE_POOL_JOINED": "Don't see a pool you joined? ",
    "IMPORT_IT": "Import it.",
    "TOKEN_FARM_UNSTAKE": "Or, if you staked your Dopamine-LP tokens in a farm, unstake them to see them here.",
    "FIRST_LIQUIDITY_PROVIDER": "You are the first liquidity provider.",
    "RATIO_PRICE_POOL": "The ratio of tokens you add will set the price of this pool.",
    "CLICK_SUPPLY_REVIEW": "Once you are happy with the rate click supply to review.",
    "SELECT_A_TOKEN": "Select a token",
    "ENTER_AMOUNT_S": "Enter amount",
    "INSUFFICIENT": "Insufficient",
    "bALANCE": "balance",
    "SUPPLY": "Supply",
    "PER": "per",
    "SHARE_OF_POOL": "Share of pool:",
    "IMPORT_POOL": "Import Pool",
    "CREATE_POOL": "Create pool.",
    "LP_TOKENS_IN_WALLET": "LP TOKENS IN YOUR WALLET",
    "POOL_FOUND": "Pool found!",
    "DONT_HAVE_LIQUIDITY": "You don’t have liquidity in this pool yet.",
    "NO_POOL_FOUND": "No pool found.",
    "RECENT_TRANSACTIONS": "Recent Transactions",
    "TRANSACTION_NOT_FOUND": "Trancation not found",
    "FOR": "for",
    "REMOVING_POOL_TOKENS": "Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.",
    "AMOUNT": "Amount",
    "RATES": "Rates",
    "REMOVE": "Remove",
    "MAXIMUM_SOLD": "Maximum sold",
    "MINIMUM_RECEIVED_C": "Minimum Received",
    "TOKEN_NAME": "Token Name",
    "FOUND_MY_ADDRESS": "Found by address",
    "IMPORT_TOKEN": "Import Token",
    "SEARCH_NAME": "Search by names or Address",

    //SWAP MODEL
    "CONFIRM_SUPPLY": "Confirm Supply",
    "INPUT_ESTIMATED_SELL_MOST": "Input is estimated. You will sell at most",
    "PRICEE": "Price:",
    "SWAPPING": "Swapping",
    "WAITING_CONFIRMATION": "Waiting for confirmation",
    "CONFIRM_TRANSACTION_WALLET": "Confirm this transaction in your wallet",
    "TRANSACTION_SUBMITTED": "Transaction submitted",
    "VIEW_ON_BSCSCAN": "View on BscScan",
    "TRANSACTION_REJECTED": "Transaction Rejected",
    "DISMISS": "Dismiss",
    "ERROR": "Error",
    //LIQUTITY MODEL
    "OUTPUT_ESTIMATED_PRICE_CHANGES": "Output is estimated. If the price changes by more than",
    "YOUR_TRANSACTION_REVERT": "your transaction will revert.",
    "YOU_WILL_RECEIVE": "You will receive :",
    "DEPOSITED": "Deposited :",
    "SUPPLYING": "Supplying",
    "AND": "and",
    //WALLET MODEL
    "CONNECT_WALLETTT": "Connect Wallet",
    "NEW_TO_ETHEREUM": "New to Binance?",
    "LEARN_ABOUT_WALLETS": "Learn more about wallets",
    "PARTNERSHIPS": "Partnerships",

}