export default {
    ABOUT: "Ról ről",
    ADVANCED_TOOLS: "Fejlett eszközök a kereskedők számára",
    APPROVE: "Jóváhagy",
    APY: "Apa",
    AUDITS: "Ellenőrzések",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Márkablog",
    BURNED_TOKEN: "Megégett token",
    BUY_FARM_SPEND: "Vásárolja meg, gazdálkodjon, töltse el és tedd!",
    BUY_ONE80_TOKENS: "Vásároljon egy 80 tokent",
    CANCEL: "Megszünteti",
    CIRCULATE_SUPPLY: "Keringő ellátás",
    CLOSE: "Bezárás",
    COMMUNITY: "Közösség",
    COMMUNITY_JOIN: "Az Dopamine átalakítja a defi világot, a közösségünk és a fejlesztőink pénzügyi világot teremtenek a határok, a defi kereskedési platform nélkül. Csatlakozz hozzánk!",
    CONFIRM: "megerősít",
    CONFIRM_SWAP: "Erősítse meg a cserét",
    CONNECT_WALLET: "Csatlakozzon egy pénztárcához",
    CONNECT_WALLET_TRADE_TOKEN: "Csatlakoztassa a pénztárcáját, és másodpercek alatt cseréljen bármilyen tokent az ETH -n.",
    CONTRIBUTORS: "Közreműködők",
    CRYPTO_GROW: "Nézze meg, hogy a kripto növekszik!",
    CUSTOMER_SUPPORT: "Vevőszolgálat",
    DEPOSIT_FEE: "Betéti díj:",
    DETAILS: "Részletek",
    DEVELOPERS: "Fejlesztők",
    DISCONNECT_WALLET: "Válasszuk le a pénztárcát",
    DOCUMENTATION: "Dokumentáció",
    EARNED: "Megkeresett",
    EARN_PASSIVE_INCOME: "Keressen passzív jövedelmet 24/7 Dopamine -val",
    EARN_WHILE_SLEEP: "Keressen, amíg alszol!",
    ETHEREUM: "Binance",
    EXCHANGE: "Csere",
    FARMS: "Gazdaságok",
    FARMS_POOLS: "Gazdaságok és medencék",
    FINISHED: "Befejezett",
    GITHUB: "Github",
    GUIDES: "Útmutatók",
    HARVEST: "Aratás",
    HEART_ECOSYSTEM: "Az Dopamine token az ökoszisztéma középpontjában áll.",
    HELP: "Segítség",
    IDEAS_LAB: "Ötletek laboratóriuma",
    JOIN_ONE80: "Csatlakozzon a Dopamine Defi forradalomhoz.",
    LAUNCH_APP: "Indító alkalmazás indítása",
    LIQUIDITY: "Likviditás",
    LIQUIDITY_PROVIDER_FEE: "Likviditási szolgáltató díj",
    LITE_PAPER: "Lite papír",
    LIVE: "Élő",
    LOADING: "Betöltés...",
    LOAD_MORE: "Töltsön fel többet",
    MARKET_CAP: "Piaci felső korlát",
    MAX: "Maximum",
    MINIMUM_RECEIVED: "Minimális beérkezett",
    MULTICHAIN_INSTANT_ACCESS: "A multichain hamarosan jön, és azonnali hozzáférést biztosít a kripto világához.",
    NO_FARMS: "Nincs gazdaság",
    NO_POOLS: "Nincs medence",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Az Dopamine előnyei mindenki számára, HODL, Exchange és Trade 24/7.",
    ONE80_EARNED: "Dopamine megszerzett",
    ONE80_MAKES_EASY: "A Dopamine megkönnyíti a kripto működését az Ön számára.",
    ONE80_WORLD: "Az Dopamine a világunkat körbejárja.",
    OUTPUT_ESTIMATED: "A kimenet becslése. Ha az ár több mint 0,8% -kal változik, akkor a tranzakció visszatér.",
    OUTPUT_RECEIVE_ATLEAST: "A kimenet becslése. Legalább kapsz",
    PARTHER_BURNED: "LP Poster/Busd megégett",
    POOLS: "Medencék",
    PRICE: "Ár",
    PRICE_IMPACT: "Árhatás",
    READABLE_CONTENT: "Régóta megalapozott tény, hogy az olvasót elvonja az olvasható tartalom.",
    SEARCH_FARMS: "Keresési gazdaságok",
    SEARCH_POOLS: "Keresési medencék",
    SORT_BY: "Sorrend",
    STAKE: "Tét:",
    STAKED_ONLY: "Csak",
    STAKE_EARN_ONE80: "Tét tokenek, hogy megszerezzék az Dopamine -at",
    STAKE_ONE80: "STAKE LP tokenek, hogy egy80 -at szerezzenek",
    STAKE_TOKENS: "Tét tokenek",
    START_EARNING: "Elkezd keresni",
    SUCCESS_COMPLETE: "Sikeresen teljesítve",
    SUSTAIN: "Fenntarthatóság",
    TOKEN_PRICE: "Tokenár",
    TOTAL_LIQUIDITY: "Teljes likviditás:",
    TOTAL_STAKE: "Összesen feltöltött:",
    TOTAL_STAKED_S: "Összesen eldobva",
    TOTAL_SUPPLY: "Teljes ellátás",
    TRADERS_CHOOSE_ONE80: "A kereskedők az Dopamine -at választják, hogy automatikusan cseréljék a kereskedelmet a legjobb Dexs -re, így a legjobb eredményeket adják.",
    TRADE_EARN_JOIN: "Kereskedjen, keressen és csatlakozzon a közösségünkhöz, részesüljön a Dopamine jutalmakból.",
    TRADE_NOW: "Kereskedelem most",
    TRADE_REGISTRATION_HASSLE: "Keresse meg bármit. Nincs regisztráció, nincs gond",
    TRANSACTION_REVERT: "vagy a tranzakció visszatér.",
    TROUBLE_SHOOT: "Baj lövöldözése",
    TRUSTED_WORLD: "Megbízható világszerte",
    UNLOCK_WALLET: "Feloldja a pénztárcát",
    UNSTAKE_TOKENS: "Kocsmák tokenek",
    VIEW_ETHERSCAN: "Kilátás az BscScan",
    YOU_RECEIVE: "Meg fogod kapni",
    //Exchange and Liquidity
    ADD: "Hozzáad",
    ADD_LIQUIDITY: "Adjon hozzá likviditást",
    AMOUNT: "Összeg",
    BALANCE: "Egyensúly:",
    CLICK_SUPPLY_REVIEW: "Miután elégedett volt a sebességgel, kattintson a felülvizsgálathoz.",
    CONNECT_WALLET_LIQUIDITY: "Csatlakozzon egy pénztárcához a likviditás megtekintéséhez.",
    CREATE_PAIR: "Készítsen párot",
    CREATE_POOL: "Készítsen medencét.",
    DONT_HAVE_LIQUIDITY: "Még nincs likviditása ebben a medencében.",
    DONT_SEE_POOL_JOINED: "Nem lát egy medencét, amellyel csatlakozott?",
    ENABLE: "Engedélyezze",
    ENTER_AMOUNT: "Adja meg az összeget",
    ENTER_AMOUNT_S: "Adja meg az összeget",
    FIRST_LIQUIDITY_PROVIDER: "Te vagy az első likviditási szolgáltató.",
    FOR: "-ra",
    FROM: "Tól től",
    IMPORT_IT: "Importálja.",
    IMPORT_POOL: "Importmedence",
    INSUFFICIENT: "Elégtelen",
    INSUFFICIENT_BALANCE: "Fedezethiány",
    INSUFFICIENT_LIQUIDITY: "Nem elegendő likviditás ehhez a kereskedelemhez.",
    LIQUIDITY_PROV_FEE: "Likviditási szolgáltató díj:",
    LIQUIDITY_REWARDS: "Likviditási szolgáltató jutalmak",
    LP_TOKENS_IN_WALLET: "LP tokenek a pénztárcájában",
    MINUTES: "Percek",
    NO_LIQUTITY_LIST: "Nincs liqutity lista",
    NO_POOL_FOUND: "Nincs medence.",
    PER: "egy",
    POOL: "Medence",
    POOL_FOUND: "Medence található!",
    POOL_SHARE: "A medence megosztása:",
    POOL_TOKENS: "A medence tokenek:",
    PRICE_IMPACTS: "Árhatás:",
    PRICE_IMPACT_HIGH: "Árhatás a magasra",
    RATES: "Árfolyam",
    RATIO_PRICE_POOL: "A hozzáadott tokenek aránya beállítja a medence árát.",
    RECENT_TRANSACTIONS: "Legutóbbi tranzakciók",
    REMOVE: "Eltávolítás",
    REMOVE_LIQUIDITY: "Távolítsa el a likviditást",
    REMOVING_POOL_TOKENS: "A medence tokenek eltávolítása a helyzetét a jelenlegi sebességgel konvertálja, a medence részesedésével arányosan. A felhalmozott díjakat a kapott összegek tartalmazzák.",
    ROUTE: "Útvonal:",
    SELECT_A_TOKEN: "Válasszon egy tokent",
    SETTINGS: "Beállítások",
    SHARE_OF_POOL: "A medence részesedése:",
    SLIPPAGE_TOL: "Csúszási tolerancia",
    SLIPPAGE_TOLERANCE: "Csúszás tolerancia:",
    SUPPLY: "Kínálat",
    SWAP: "Csere",
    TO: "Nak nek",
    TOKEN_FARM_UNSTAKE: "Vagy ha egy farmba vitte az Dopamine-LP tokeneit, kérje meg őket, hogy itt láthassa őket.",
    TRANSACTION_DEADLINE: "Tranzakciós határidő",
    TRANSACTION_NOT_FOUND: "A transzációt nem találták meg",
    TRANSACTION_REVERT_PERCENTAGE: "Az Ön trazsactionja visszatér, ha az ár kedvezőtlenül változik, mint ez a százalék",
    WITHDRAW_LIQUIDITY: "A likviditási szolgáltatók 0.25%% -os díjat keresnek a medence részesedésével arányos minden ügyletnél. A díjakat a medencébe adják, valós időben felhalmozódnak, és likviditásának visszavonásával igényelhetők.",
    YOUR_LIQUIDITY: "Likviditása",
    bALANCE: "egyensúly",
    MAXIMUM_SOLD: "Maximum eladva",
    MINIMUM_RECEIVED_C: "Minimum beérkezett",
    TOKEN_NAME: "Token név",
    FOUND_MY_ADDRESS: "Cím szerint talált",
    IMPORT_TOKEN: "Import token",
    SEARCH_NAME: "Keresés név vagy cím alapján",

    AND: "és",
    CONFIRM_SUPPLY: "Erősítse meg az ellátást",
    CONFIRM_TRANSACTION_WALLET: "Erősítse meg ezt a tranzakciót a pénztárcájában",
    CONNECT_WALLETTT: "Csatlakoztassa a pénztárcát",
    DEPOSITED: "Letétek:",
    DISMISS: "Elbocsát",
    ERROR: "Hiba",
    INPUT_ESTIMATED_SELL_MOST: "A bemenet becsült. Legfeljebb eladni fogod",
    LEARN_ABOUT_WALLETS: "Tudjon meg többet a pénztárcákról",
    NEW_TO_ETHEREUM: "Új az Ethereumban?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "A kimenet becslése. Ha az ár többet változik, mint",
    PRICEE: "Ár:",
    SUPPLYING: "Ellátó",
    SWAPPING: "Csere",
    TRANSACTION_REJECTED: "A tranzakció elutasította",
    TRANSACTION_SUBMITTED: "Benyújtott tranzakció",
    VIEW_ON_BSCSCAN: "Tekintse meg a BSCSCAN -on",
    WAITING_CONFIRMATION: "visszaigazolásra vár",
    YOUR_TRANSACTION_REVERT: "A tranzakció visszatér.",
    YOU_WILL_RECEIVE: "Kapni fogsz :",
    PARTNERSHIPS: "Partnerségek",
}