export default {
    ABOUT: "Um",
    ADVANCED_TOOLS: "Erweiterte Tools für Händler",
    APPROVE: "Genehmigen",
    APY: "Apy",
    AUDITS: "Audits",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Markenblog",
    BURNED_TOKEN: "Verbranntes Token",
    BUY_FARM_SPEND: "Kaufen Sie es, bewirtschaften Sie es, geben Sie es aus und setzen Sie es ein!",
    BUY_ONE80_TOKENS: "Kaufen Sie Dopamine -Token",
    CANCEL: "Absagen",
    CIRCULATE_SUPPLY: "Umlaufversorgung",
    CLOSE: "Nah dran",
    COMMUNITY: "Gemeinschaft",
    COMMUNITY_JOIN: "Dopamine verändert die Defi -Welt, unsere Community und Entwickler schaffen eine Finanzwelt ohne Boarder, Defi Trading Platform und die Zukunft. Begleiten Sie uns!",
    CONFIRM: "Bestätigen",
    CONFIRM_SWAP: "Swap bestätigen",
    CONNECT_WALLET: "Schließen Sie eine Brieftasche an",
    CONNECT_WALLET_TRADE_TOKEN: "Schließen Sie Ihre Brieftasche an und tauschen Sie ein Token in Sekundenschnelle gegen die ETH.",
    CONTRIBUTORS: "Mitwirkende",
    CRYPTO_GROW: "Beobachten Sie, wie Ihr Krypto wächst!",
    CUSTOMER_SUPPORT: "Kundendienst",
    DEPOSIT_FEE: "Einzahlungsgebühr:",
    DETAILS: "Einzelheiten",
    DEVELOPERS: "Entwickler",
    DISCONNECT_WALLET: "Brieftasche trennen",
    DOCUMENTATION: "Dokumentation",
    EARNED: "Verdient",
    EARN_PASSIVE_INCOME: "Verdienen Sie passives Einkommen rund um die Uhr mit Dopamine",
    EARN_WHILE_SLEEP: "Verdiene, während du schläfst!",
    ETHEREUM: "Äther",
    EXCHANGE: "Austausch",
    FARMS: "Farmen",
    FARMS_POOLS: "Farmen und Pools",
    FINISHED: "Fertig",
    GITHUB: "Github",
    GUIDES: "Führer",
    HARVEST: "Ernte",
    HEART_ECOSYSTEM: "Dopamine Token ist das Herzstück unseres Ökosystems.",
    HELP: "Hilfe",
    IDEAS_LAB: "Ideenlabor",
    JOIN_ONE80: "Treten Sie der Dopamine Defi Revolution bei.",
    LAUNCH_APP: "App starten",
    LIQUIDITY: "Liquidität",
    LIQUIDITY_PROVIDER_FEE: "Liquiditätsanbietergebühr",
    LITE_PAPER: "Lite Paper",
    LIVE: "Live",
    LOADING: "Wird geladen...",
    LOAD_MORE: "Mehr laden",
    MARKET_CAP: "Marktkapitalisierung",
    MAX: "Max",
    MINIMUM_RECEIVED: "Minimum erhalten",
    MULTICHAIN_INSTANT_ACCESS: "Multichain kommt in Kürze und gewährt Ihnen sofortigen Zugang zu einer Welt von Krypto.",
    NO_FARMS: "Keine Farmen",
    NO_POOLS: "Keine Pools",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Ein80 -Leistungen für alle, Hodl, Austausch und Handel rund um die Uhr.",
    ONE80_EARNED: "Dopamine verdient",
    ONE80_MAKES_EASY: "Dopamine erleichtert es einfach, Ihre Krypto für Sie zum Laufen zu bringen.",
    ONE80_WORLD: "Dopamine lässt unsere Welt herumgehen.",
    OUTPUT_ESTIMATED: "Ausgang wird geschätzt. Wenn sich der Preis um mehr als 0,8% ändert, wird Ihre Transaktion zurückgekehrt.",
    OUTPUT_RECEIVE_ATLEAST: "Ausgang wird geschätzt. Sie werden mindestens erhalten",
    PARTHER_BURNED: "LP parther/busd verbrannt",
    POOLS: "Pools",
    PRICE: "Preis",
    PRICE_IMPACT: "Preiswirkung",
    READABLE_CONTENT: "Es ist eine seit langem festgestellte Tatsache, dass ein Leser durch den lesbaren Inhalt abgelenkt wird.",
    SEARCH_FARMS: "Suchfarmen",
    SEARCH_POOLS: "Suchpools",
    SORT_BY: "Sortieren nach",
    STAKE: "Einsatz:",
    STAKED_ONLY: "Nur festgelegt",
    STAKE_EARN_ONE80: "Pfahltoken, um Dopamine zu verdienen",
    STAKE_ONE80: "LP -Token einnehmen, um Dopamine zu verdienen",
    STAKE_TOKENS: "Pfahl Token",
    START_EARNING: "Anfangen zu verdienen",
    SUCCESS_COMPLETE: "Erfolgreich abgeschlossen",
    SUSTAIN: "Nachhaltigkeit",
    TOKEN_PRICE: "Token -Preis",
    TOTAL_LIQUIDITY: "Gesamtliquidität:",
    TOTAL_STAKE: "Total abgesteckt:",
    TOTAL_STAKED_S: "Total abgesteckt",
    TOTAL_SUPPLY: "Gesamtversorgung",
    TRADERS_CHOOSE_ONE80: "Händler wählen Dopamine, um automatisch zu den besten DEXs zu tauschen und zu übertreffen, was Ihnen die besten Ergebnisse erzielt.",
    TRADE_EARN_JOIN: "Handel, verdienen und unserer Gemeinde beizutreten, profitieren von Dopamine -Belohnungen.",
    TRADE_NOW: "Handel jetzt",
    TRADE_REGISTRATION_HASSLE: "Alles tauschen. Keine Registrierung, kein Ärger",
    TRANSACTION_REVERT: "oder die Transaktion wird zurückkehren.",
    TROUBLE_SHOOT: "Probleme mit dem Schießen",
    TRUSTED_WORLD: "Vertrauenswürdig weltweit",
    UNLOCK_WALLET: "Brieftasche entsperren",
    UNSTAKE_TOKENS: "Unstake Tokens",
    VIEW_ETHERSCAN: "Blick auf BscScan",
    YOU_RECEIVE: "Sie werden erhalten",
    //Exchange and Liquidity
    ADD: "Hinzufügen",
    ADD_LIQUIDITY: "Liquidität hinzufügen",
    AMOUNT: "Menge",
    BALANCE: "Gleichgewicht:",
    CLICK_SUPPLY_REVIEW: "Sobald Sie mit der Rate zufrieden sind, klicken Sie auf die Überprüfung.",
    CONNECT_WALLET_LIQUIDITY: "Schließen Sie eine Brieftasche an, um Ihre Liquidität zu sehen.",
    CREATE_PAIR: "Paar erstellen",
    CREATE_POOL: "Pool erstellen.",
    DONT_HAVE_LIQUIDITY: "Sie haben noch keine Liquidität in diesem Pool.",
    DONT_SEE_POOL_JOINED: "Sehen Sie keinen Pool, dem Sie beigetreten sind?",
    ENABLE: "Ermöglichen",
    ENTER_AMOUNT: "Menge eingeben",
    ENTER_AMOUNT_S: "Menge eingeben",
    FIRST_LIQUIDITY_PROVIDER: "Sie sind der erste Liquiditätsanbieter.",
    FOR: "zum",
    FROM: "Aus",
    IMPORT_IT: "Importieren.",
    IMPORT_POOL: "Pool importieren",
    INSUFFICIENT: "Unzureichend",
    INSUFFICIENT_BALANCE: "Mangelhaftes Gleichgewicht",
    INSUFFICIENT_LIQUIDITY: "Unzureichende Liquidität für diesen Handel.",
    LIQUIDITY_PROV_FEE: "Gebühr von Liquiditätsanbietern:",
    LIQUIDITY_REWARDS: "Belohnungen des Liquiditätsanbieters",
    LP_TOKENS_IN_WALLET: "LP -Token in Ihrer Brieftasche",
    MINUTES: "Protokoll",
    NO_LIQUTITY_LIST: "Keine Flüssigkeitsliste",
    NO_POOL_FOUND: "Kein Pool gefunden.",
    PER: "pro",
    POOL: "Schwimmbad",
    POOL_FOUND: "Pool gefunden!",
    POOL_SHARE: "Ihr Poolanteil:",
    POOL_TOKENS: "Ihre Pool -Token:",
    PRICE_IMPACTS: "Preiswirkung:",
    PRICE_IMPACT_HIGH: "Preiswirkung auf Hoch",
    RATES: "Preise",
    RATIO_PRICE_POOL: "Das Verhältnis von Token, die Sie hinzufügen, setzt den Preis dieses Pools.",
    RECENT_TRANSACTIONS: "kürzliche Transaktionen",
    REMOVE: "Entfernen",
    REMOVE_LIQUIDITY: "Liquidität entfernen",
    REMOVING_POOL_TOKENS: "Das Entfernen von Pool -Token wandelt Ihre Position wieder in die zugrunde liegenden Token um, um proportional zu Ihrem Anteil am Pool zu tätigen. Aufgelaufene Gebühren sind in den von Ihnen erhaltenen Beträgen enthalten.",
    ROUTE: "Route:",
    SELECT_A_TOKEN: "Wählen Sie ein Token",
    SETTINGS: "Einstellungen",
    SHARE_OF_POOL: "Anteil des Pools:",
    SLIPPAGE_TOL: "Schlupftoleranz",
    SLIPPAGE_TOLERANCE: "Schlupftoleranz:",
    SUPPLY: "Liefern",
    SWAP: "Tausch",
    TO: "Zu",
    TOKEN_FARM_UNSTAKE: "Oder, wenn Sie Ihre Dopamine-LP-Token in einer Farm gesteckt haben, dann entfernen Sie sie, um sie hier zu sehen.",
    TRANSACTION_DEADLINE: "Transaktionsfrist",
    TRANSACTION_NOT_FOUND: "Tranz nicht gefunden",
    TRANSACTION_REVERT_PERCENTAGE: "Ihre Trasaktion wird zurückkehren, wenn sich der Preis um mehr als diesen Prozentsatz verändert",
    WITHDRAW_LIQUIDITY: "Liquiditätsanbieter erhalten eine Gebühr von 0.25% für alle Geschäfte proportional zu ihrem Anteil am Pool. Dem Pool werden Gebühren hinzugefügt, in Echtzeit entstehen und können durch Abheben Ihrer Liquidität geltend gemacht werden.",
    YOUR_LIQUIDITY: "Ihre Liquidität",
    bALANCE: "Gleichgewicht",
    MAXIMUM_SOLD: "Maximal verkauft",
    MINIMUM_RECEIVED_C: "Minimum erhalten",
    TOKEN_NAME: "Token -Name",
    FOUND_MY_ADDRESS: "Durch Adresse gefunden",
    IMPORT_TOKEN: "Token importieren",
    SEARCH_NAME: "Suche nach Namen oder Adresse",

    AND: "und",
    CONFIRM_SUPPLY: "Versorgung bestätigen",
    CONFIRM_TRANSACTION_WALLET: "Bestätigen Sie diese Transaktion in Ihrer Brieftasche",
    CONNECT_WALLETTT: "Brieftasche anschließen",
    DEPOSITED: "Hinterlegt:",
    DISMISS: "Zurückweisen",
    ERROR: "Fehler",
    INPUT_ESTIMATED_SELL_MOST: "Eingabe wird geschätzt. Sie werden höchstens verkaufen",
    LEARN_ABOUT_WALLETS: "Erfahren Sie mehr über Brieftaschen",
    NEW_TO_ETHEREUM: "Neu in Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Ausgang wird geschätzt. Wenn sich der Preis um mehr als als ändert",
    PRICEE: "Preis:",
    SUPPLYING: "Lieferung",
    SWAPPING: "Tauschen",
    TRANSACTION_REJECTED: "Transaktion abgelehnt",
    TRANSACTION_SUBMITTED: "Transaktion eingereicht",
    VIEW_ON_BSCSCAN: "Blick auf bscscan",
    WAITING_CONFIRMATION: "Auf Bestätigung warten",
    YOUR_TRANSACTION_REVERT: "Ihre Transaktion wird zurückkehren.",
    YOU_WILL_RECEIVE: "Du wirst erhalten :",
    PARTNERSHIPS: "Partnerschaften",
}