export default {
    ABOUT: "에 대한",
    ADVANCED_TOOLS: "거래자를위한 고급 도구",
    APPROVE: "승인하다",
    APY: "Apy",
    AUDITS: "감사",
    BAL: "BAL :",
    BITCOIN_C: "비트 코인",
    BITCOIN_S: "비트 코인",
    BRAND_BLOG: "브랜드 블로그",
    BURNED_TOKEN: "불타는 토큰",
    BUY_FARM_SPEND: "그것을 사서, 농장, 그것을 보내고 스테이크!",
    BUY_ONE80_TOKENS: "180 토큰을 구입하십시오",
    CANCEL: "취소",
    CIRCULATE_SUPPLY: "순환 공급",
    CLOSE: "닫다",
    COMMUNITY: "지역 사회",
    COMMUNITY_JOIN: "Dopamine은 Defi World를 변화시키고 있으며, 커뮤니티와 개발자는 현재 및 미래에 기숙사, Defi Trading Platform이없는 금융 세계를 창조하고 있습니다. 우리와 함께!",
    CONFIRM: "확인하다",
    CONFIRM_SWAP: "스왑을 확인하십시오",
    CONNECT_WALLET: "지갑에 연결하십시오",
    CONNECT_WALLET_TRADE_TOKEN: "지갑을 연결하고 몇 초 만에 Token을 ETH로 거래하십시오.",
    CONTRIBUTORS: "기고자",
    CRYPTO_GROW: "당신의 암호화가 성장하는 것을 지켜보십시오!",
    CUSTOMER_SUPPORT: "고객 지원",
    DEPOSIT_FEE: "예금 수수료 :",
    DETAILS: "세부",
    DEVELOPERS: "개발자",
    DISCONNECT_WALLET: "지갑을 분리하십시오",
    DOCUMENTATION: "선적 서류 비치",
    EARNED: "획득",
    EARN_PASSIVE_INCOME: "One80으로 24/7 수동 소득을 얻습니다",
    EARN_WHILE_SLEEP: "잠을자는 동안 수입하십시오!",
    ETHEREUM: "이더 리움",
    EXCHANGE: "교환",
    FARMS: "전원",
    FARMS_POOLS: "농장과 수영장",
    FINISHED: "완성된",
    GITHUB: "github",
    GUIDES: "가이드",
    HARVEST: "수확하다",
    HEART_ECOSYSTEM: "Dopamine 토큰은 우리 생태계의 중심에 있습니다.",
    HELP: "돕다",
    IDEAS_LAB: "아이디어 실험실",
    JOIN_ONE80: "Dopamine Defi Revolution에 가입하십시오.",
    LAUNCH_APP: "앱을 시작합니다",
    LIQUIDITY: "유동성",
    LIQUIDITY_PROVIDER_FEE: "유동성 제공자 수수료",
    LITE_PAPER: "라이트 종이",
    LIVE: "살다",
    LOADING: "로딩 ...",
    LOAD_MORE: "더로드하십시오",
    MARKET_CAP: "시가 총액",
    MAX: "맥스",
    MINIMUM_RECEIVED: "최소 수신",
    MULTICHAIN_INSTANT_ACCESS: "멀티 치인이 곧 출시되어 크립토의 세계에 즉시 액세스 할 수 있습니다.",
    NO_FARMS: "농장이 없습니다",
    NO_POOLS: "수영장 없음",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "HODL, Exchange 및 Trade 24/7에 대한 80 가지 혜택.",
    ONE80_EARNED: "Dopamine 획득",
    ONE80_MAKES_EASY: "Dopamine은 암호화를 쉽게 만들 수 있습니다.",
    ONE80_WORLD: "Dopamine은 세상을 돌립니다.",
    OUTPUT_ESTIMATED: "출력이 추정됩니다. 가격이 0.8% 이상 변경되면 거래가 되돌아갑니다.",
    OUTPUT_RECEIVE_ATLEAST: "출력이 추정됩니다. 당신은 적어도받을 것입니다",
    PARTHER_BURNED: "LP Parther/Busd가 불 태웠다",
    POOLS: "수영장",
    PRICE: "가격",
    PRICE_IMPACT: "가격 영향",
    READABLE_CONTENT: "독자가 읽을 수있는 콘텐츠에 의해 산만해진다는 것은 오랫동안 확립 된 사실입니다.",
    SEARCH_FARMS: "수색 농장",
    SEARCH_POOLS: "검색 풀",
    SORT_BY: "정렬 기준",
    STAKE: "말뚝:",
    STAKED_ONLY: "만 스테이크",
    STAKE_EARN_ONE80: "180을 얻기위한 스테이크 토큰",
    STAKE_ONE80: "Stake LP 토큰을 얻을 수 있습니다",
    STAKE_TOKENS: "스테이크 토큰",
    START_EARNING: "수입을 시작하십시오",
    SUCCESS_COMPLETE: "성공적으로 완수되었다",
    SUSTAIN: "지속 가능성",
    TOKEN_PRICE: "토큰 가격",
    TOTAL_LIQUIDITY: "총 유동성 :",
    TOTAL_STAKE: "총체적 인 것 :",
    TOTAL_STAKED_S: "총체적 인",
    TOTAL_SUPPLY: "총 공급",
    TRADERS_CHOOSE_ONE80: "거래자는 180을 선택하여 최고의 덱스로 거래를 자동으로 교환하고 노선하여 최상의 결과를 제공합니다.",
    TRADE_EARN_JOIN: "무역, 수입 및 커뮤니티에 가입하고 Dopamine 보상의 혜택.",
    TRADE_NOW: "지금 거래하십시오",
    TRADE_REGISTRATION_HASSLE: "무엇이든 거래하십시오. 등록이없고 번거 로움이 없습니다",
    TRANSACTION_REVERT: "또는 거래가 되돌아갑니다.",
    TROUBLE_SHOOT: "문제 촬영",
    TRUSTED_WORLD: "전 세계적으로 신뢰할 수 있습니다",
    UNLOCK_WALLET: "지갑의 잠금을 해제하십시오",
    UNSTAKE_TOKENS: "토큰을 살펴보십시오",
    VIEW_ETHERSCAN: "Bscscan에서보기",
    YOU_RECEIVE: "당신은받을 것입니다",
    //Exchange and Liquidity
    ADD: "추가하다",
    ADD_LIQUIDITY: "유동성을 추가하십시오",
    AMOUNT: "양",
    BALANCE: "균형:",
    CLICK_SUPPLY_REVIEW: "속도에 만족하면 공급을 클릭하여 검토하십시오.",
    CONNECT_WALLET_LIQUIDITY: "유동성을 보려면 지갑에 연결하십시오.",
    CREATE_PAIR: "쌍을 만듭니다",
    CREATE_POOL: "수영장을 만듭니다.",
    DONT_HAVE_LIQUIDITY: "이 수영장에는 아직 유동성이 없습니다.",
    DONT_SEE_POOL_JOINED: "가입 한 수영장이 보이지 않습니까?",
    ENABLE: "할 수 있게 하다",
    ENTER_AMOUNT: "금액을 입력하세요",
    ENTER_AMOUNT_S: "금액을 입력하세요",
    FIRST_LIQUIDITY_PROVIDER: "당신은 첫 번째 유동성 제공 업체입니다.",
    FOR: "~을 위한",
    FROM: "에서",
    IMPORT_IT: "가져 오십시오.",
    IMPORT_POOL: "가져 오기 풀",
    INSUFFICIENT: "불충분합니다",
    INSUFFICIENT_BALANCE: "잔액 불충분",
    INSUFFICIENT_LIQUIDITY: "이 거래에 대한 유동성이 충분하지 않습니다.",
    LIQUIDITY_PROV_FEE: "유동성 제공자 수수료 :",
    LIQUIDITY_REWARDS: "유동성 제공 업체 보상",
    LP_TOKENS_IN_WALLET: "지갑의 LP 토큰",
    MINUTES: "분",
    NO_LIQUTITY_LIST: "액체 목록이 없습니다",
    NO_POOL_FOUND: "수영장이 없습니다.",
    PER: "당",
    POOL: "수영장",
    POOL_FOUND: "수영장이 발견되었습니다!",
    POOL_SHARE: "수영장 공유 :",
    POOL_TOKENS: "수영장 토큰 :",
    PRICE_IMPACTS: "가격 영향 :",
    PRICE_IMPACT_HIGH: "높은 가격에 가격 영향",
    RATES: "요율",
    RATIO_PRICE_POOL: "추가 한 토큰의 비율은이 풀의 가격을 설정합니다.",
    RECENT_TRANSACTIONS: "최근 거래",
    REMOVE: "제거하다",
    REMOVE_LIQUIDITY: "유동성을 제거하십시오",
    REMOVING_POOL_TOKENS: "풀 토큰을 제거하면 위치를 수영장의 비율에 비례하여 현재 속도로 기본 토큰으로 다시 변환합니다. 발생한 수수료는 귀하가받는 금액에 포함됩니다.",
    ROUTE: "노선:",
    SELECT_A_TOKEN: "토큰을 선택하십시오",
    SETTINGS: "설정",
    SHARE_OF_POOL: "수영장의 공유 :",
    SLIPPAGE_TOL: "미끄러짐 허용 오차",
    SLIPPAGE_TOLERANCE: "미끄러짐 내성 :",
    SUPPLY: "공급",
    SWAP: "교환",
    TO: "에게",
    TOKEN_FARM_UNSTAKE: "또는 농장에서 Dopamine-LP 토큰을 찌르면 여기에서 볼 수 있도록 검토를 해제하십시오.",
    TRANSACTION_DEADLINE: "거래 마감일",
    TRANSACTION_NOT_FOUND: "트랜션을 찾을 수 없습니다",
    TRANSACTION_REVERT_PERCENTAGE: "가격 이이 백분율 이상으로 불리하게 변하면 귀하의 트레이션이 되돌아갑니다.",
    WITHDRAW_LIQUIDITY: "유동성 제공 업체는 풀의 비율에 비례하여 모든 거래에서 0.25%의 수수료를받습니다. 수수료는 수영장에 추가되며 실시간으로 발생하며 유동성을 철회하여 청구 할 수 있습니다.",
    YOUR_LIQUIDITY: "당신의 유동성",
    bALANCE: "균형",
    MAXIMUM_SOLD: "최대 판매",
    MINIMUM_RECEIVED_C: "최소 수신",
    TOKEN_NAME: "토큰 이름",
    FOUND_MY_ADDRESS: "주소로 발견되었습니다",
    IMPORT_TOKEN: "수입 토큰",
    SEARCH_NAME: "이름 또는 주소로 검색",

    AND: "그리고",
    CONFIRM_SUPPLY: "공급을 확인하십시오",
    CONFIRM_TRANSACTION_WALLET: "지갑 에서이 거래를 확인하십시오",
    CONNECT_WALLETTT: "지갑을 연결하십시오",
    DEPOSITED: "예금 :",
    DISMISS: "해고하다",
    ERROR: "오류",
    INPUT_ESTIMATED_SELL_MOST: "입력이 추정됩니다. 당신은 최대를 팔 것입니다",
    LEARN_ABOUT_WALLETS: "지갑에 대해 자세히 알아보십시오",
    NEW_TO_ETHEREUM: "이더 리움에 새로?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "출력이 추정됩니다. 가격이 더 많이 변하면",
    PRICEE: "가격:",
    SUPPLYING: "공급",
    SWAPPING: "스와핑",
    TRANSACTION_REJECTED: "거래 거부",
    TRANSACTION_SUBMITTED: "제출 된 거래",
    VIEW_ON_BSCSCAN: "BSCSCAN에서보기",
    WAITING_CONFIRMATION: "확인을 기다리고 있습니다",
    YOUR_TRANSACTION_REVERT: "거래가 되돌아갑니다.",
    YOU_WILL_RECEIVE: "당신은받을 것입니다 :",
    PARTNERSHIPS: "파트너십",
}