import config from '../config/config';

export function getNetworkName(id) {
    id = parseInt(id);
    var text = "BNB Chain";
    try {
        if (id === 1 || id === 5) {
            text = "Ethereum";
        } else if (id === 56 || id === 97) {
            text = "BNB Chain";
        } else if (id === 137 || id === 80001) {
            text = "Polygon";
        } else if (id === 250 || id === 250) {
            text = "Fantom";
        } else if (id === 43114 || id === 43113) {
            text = "Avalanche";
        } else if (id === 42161 || id === 421613) {
            text = "Arbitrum";
        } else if (id === 10 || id === 420) {
            text = "Optimism";
        } else if (id === 128) {
            text = "Heco";
        }
        else if (id === 11155111) {
            text = "Sepolia";
        }
        else if (id === 8453) {
            text = "Base";
        }
        return text;
    } catch (err) {
        return text;
    }
}

export function getTxLink(id) {

    var link = "";
    id = parseInt(id);
    if (id === 1) {
        link = config.txLink[1];
    } else if (id === 56) {
        link = config.txLink[56];
    } else if (id === 137) {
        link = config.txLink[137];
    } else if (id === 250) {
        link = config.txLink[250];
    } else if (id === 43114) {
        link = config.txLink[43114];
    } else if (id === 42161) {
        link = config.txLink[42161];
    } else if (id === 10) {
        link = config.txLink[10];
    } else if (id === 128) {
        link = config.txLink[128];
    } else if (id === 97) {
        link = config.txLink[97];
    }
    return link;

}
export function getTokenLogo(logoURI, id) {
    id = parseInt(id)
        ;
    var logo = config.defaultLogo;
    try {
        if (id === 1) {
            logo = config.imageUrl + "tokens/eth/" + logoURI;
        } else if (id === 56) {
            logo = config.imageUrl + "tokens/bsc/" + logoURI;
        } else if (id === 137) {
            logo = config.imageUrl + "tokens/polygon/" + logoURI;
        } else if (id === 250) {
            logo = config.imageUrl + "tokens/ftm/" + logoURI;
        } else if (id === 43114) {
            logo = config.imageUrl + "tokens/avax/" + logoURI;
        } else if (id === 42161) {
            logo = config.imageUrl + "tokens/arb/" + logoURI;
        } else if (id === 10) {
            logo = config.imageUrl + "tokens/opt/" + logoURI;
        } else if (id === 8453) {
            logo = config.imageUrl + "tokens/base/" + logoURI;
        }else if (id === 97) {
            logo = config.imageUrl + "tokens/bsc/" + logoURI;
        }
        return logo;
    } catch (err) {
        return logo;
    }
}