import { enqueueSnackbar } from 'notistack';
export function toastAlert(errorType, message, id) {

    if (errorType == 'error') {

        enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 2500,
            maxSnack: 1,
            anchorOrigin: { horizontal: "right", vertical: "top" },
            preventDuplicate: true
        })

    } else if (errorType == 'success') {
        enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 2500,
            maxSnack: 1,
            anchorOrigin: { horizontal: "right", vertical: "top" },
            preventDuplicate: true

        })
    }
}
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// export function toastAlert(errorType, message, id) {

//     if (errorType === 'error') {

//         toast.error(message, {
//             autoClose: 4000,
//             toastId: id,
//             position: toast.POSITION.BOTTOM_RIGHT
//         });

//     } else if (errorType === 'success') {
//         toast.success(message, {
//             autoClose: 4000,
//             toastId: id,
//             position: toast.POSITION.BOTTOM_RIGHT
//         });
//     }
// }