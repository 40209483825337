export default {
    ABOUT: "Về",
    ADVANCED_TOOLS: "Các công cụ nâng cao cho các thương nhân",
    APPROVE: "Chấp thuận",
    APY: "Apy",
    AUDITS: "Kiểm toán",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog thương hiệu",
    BURNED_TOKEN: "Mã thông báo bị đốt cháy",
    BUY_FARM_SPEND: "Mua nó, trang trại nó, chi tiêu và đặt cược nó!",
    BUY_ONE80_TOKENS: "Mua mã thông báo Dopamine",
    CANCEL: "Hủy bỏ",
    CIRCULATE_SUPPLY: "Cung cấp lưu hành",
    CLOSE: "Đóng",
    COMMUNITY: "Cộng đồng",
    COMMUNITY_JOIN: "Dopamine đang biến đổi thế giới DEFI, cộng đồng và các nhà phát triển của chúng ta đang tạo ra một thế giới tài chính không có người nội trú, nền tảng giao dịch Defi cho đến nay và tương lai. Tham gia với chúng tôi!",
    CONFIRM: "Xác nhận",
    CONFIRM_SWAP: "Xác nhận hoán đổi",
    CONNECT_WALLET: "Kết nối với ví",
    CONNECT_WALLET_TRADE_TOKEN: "Kết nối ví của bạn, giao dịch bất kỳ mã thông báo nào trên ETH trong vài giây.",
    CONTRIBUTORS: "Người đóng góp",
    CRYPTO_GROW: "Xem tiền điện tử của bạn phát triển!",
    CUSTOMER_SUPPORT: "Hỗ trợ khách hàng",
    DEPOSIT_FEE: "Phí tiền gửi:",
    DETAILS: "Thông tin chi tiết",
    DEVELOPERS: "Nhà phát triển",
    DISCONNECT_WALLET: "Ngắt kết nối ví",
    DOCUMENTATION: "Tài liệu",
    EARNED: "Kiếm được",
    EARN_PASSIVE_INCOME: "Kiếm thu nhập thụ động 24/7 với Dopamine",
    EARN_WHILE_SLEEP: "Kiếm tiền trong khi bạn ngủ!",
    ETHEREUM: "Binance",
    EXCHANGE: "Trao đổi",
    FARMS: "Trang trại",
    FARMS_POOLS: "Trang trại và hồ bơi",
    FINISHED: "Hoàn thành",
    GITHUB: "GitHub",
    GUIDES: "Hướng dẫn",
    HARVEST: "Mùa gặt",
    HEART_ECOSYSTEM: "Mã thông báo Dopamine là trung tâm của hệ sinh thái của chúng tôi.",
    HELP: "Cứu giúp",
    IDEAS_LAB: "Ý tưởng phòng thí nghiệm",
    JOIN_ONE80: "Tham gia cuộc cách mạng Dopamine Defi.",
    LAUNCH_APP: "Chạy chương trình",
    LIQUIDITY: "Thanh khoản",
    LIQUIDITY_PROVIDER_FEE: "Phí cung cấp thanh khoản",
    LITE_PAPER: "Giấy lite",
    LIVE: "Trực tiếp",
    LOADING: "Đang tải...",
    LOAD_MORE: "Tải nhiều hơn",
    MARKET_CAP: "Vốn hóa thị trường",
    MAX: "Tối đa",
    MINIMUM_RECEIVED: "Tối thiểu nhận được",
    MULTICHAIN_INSTANT_ACCESS: "Multichain sắp ra mắt, cho phép bạn truy cập ngay vào một thế giới tiền điện tử.",
    NO_FARMS: "Không có trang trại",
    NO_POOLS: "Không có hồ bơi",
    ONE80: "Dopamine",
    ONE80_BENEFITS: "Một80 lợi ích cho tất cả, HODL, trao đổi và giao dịch 24/7.",
    ONE80_EARNED: "Dopamine kiếm được",
    ONE80_MAKES_EASY: "Dopamine giúp bạn dễ dàng làm cho tiền điện tử của bạn hoạt động cho bạn.",
    ONE80_WORLD: "Dopamine làm cho thế giới của chúng ta đi vòng quanh.",
    OUTPUT_ESTIMATED: "Đầu ra được ước tính. Nếu giá thay đổi hơn 0,8%, giao dịch của bạn sẽ hoàn nguyên.",
    OUTPUT_RECEIVE_ATLEAST: "Đầu ra được ước tính. Bạn sẽ nhận được ít nhất",
    PARTHER_BURNED: "LP Parther/Busd bị đốt cháy",
    POOLS: "Hồ bơi",
    PRICE: "Giá bán",
    PRICE_IMPACT: "Tác động về giá",
    READABLE_CONTENT: "Đó là một thực tế từ lâu là một người đọc sẽ bị phân tâm bởi nội dung có thể đọc được.",
    SEARCH_FARMS: "Các trang trại tìm kiếm",
    SEARCH_POOLS: "Hồ bơi tìm kiếm",
    SORT_BY: "Sắp xếp theo",
    STAKE: "Cổ phần:",
    STAKED_ONLY: "Chỉ đặt cược",
    STAKE_EARN_ONE80: "Cổ phần mã thông báo để kiếm một80",
    STAKE_ONE80: "Cổ phần mã thông báo LP để kiếm một80",
    STAKE_TOKENS: "Cổ phần mã thông báo",
    START_EARNING: "Bắt đầu kiếm tiền",
    SUCCESS_COMPLETE: "Hoàn tất",
    SUSTAIN: "Sự bền vững",
    TOKEN_PRICE: "Giá mã thông báo",
    TOTAL_LIQUIDITY: "Tổng thanh khoản:",
    TOTAL_STAKE: "Tổng số cược:",
    TOTAL_STAKED_S: "Tổng số đặt cược",
    TOTAL_SUPPLY: "Tổng cung",
    TRADERS_CHOOSE_ONE80: "Các nhà giao dịch chọn Dopamine để tự động trao đổi và định tuyến giao dịch thành DEXS tốt nhất, cho bạn kết quả tốt nhất.",
    TRADE_EARN_JOIN: "Thương mại, kiếm tiền và tham gia cộng đồng của chúng tôi, được hưởng lợi từ phần thưởng Dopamine.",
    TRADE_NOW: "Giao dịch ngay bây giờ",
    TRADE_REGISTRATION_HASSLE: "Giao dịch bất cứ điều gì. Không đăng ký, không rắc rối",
    TRANSACTION_REVERT: "hoặc giao dịch sẽ hoàn nguyên.",
    TROUBLE_SHOOT: "Rắc rối bắn",
    TRUSTED_WORLD: "Đáng tin cậy trên toàn thế giới",
    UNLOCK_WALLET: "Mở khóa ví",
    UNSTAKE_TOKENS: "Token không biết",
    VIEW_ETHERSCAN: "Xem về BscScan",
    YOU_RECEIVE: "Bạn sẽ nhận được",
    //Exchange and Liquidity
    ADD: "cộng",
    ADD_LIQUIDITY: "Thêm thanh khoản",
    AMOUNT: "Số lượng",
    BALANCE: "THĂNG BẰNG:",
    CLICK_SUPPLY_REVIEW: "Một khi bạn hài lòng với nguồn cung cấp nhấp chuột để xem xét.",
    CONNECT_WALLET_LIQUIDITY: "Kết nối với ví để xem thanh khoản của bạn.",
    CREATE_PAIR: "Tạo cặp",
    CREATE_POOL: "Tạo hồ bơi.",
    DONT_HAVE_LIQUIDITY: "Bạn không có tính thanh khoản trong hồ bơi này.",
    DONT_SEE_POOL_JOINED: "Không thấy một hồ bơi bạn đã tham gia?",
    ENABLE: "Cho phép",
    ENTER_AMOUNT: "Nhập số tiền",
    ENTER_AMOUNT_S: "Nhập số tiền",
    FIRST_LIQUIDITY_PROVIDER: "Bạn là nhà cung cấp thanh khoản đầu tiên.",
    FOR: "vì",
    FROM: "Từ",
    IMPORT_IT: "Nhập khẩu nó.",
    IMPORT_POOL: "Nhập nhóm",
    INSUFFICIENT: "Không đủ",
    INSUFFICIENT_BALANCE: "Thiếu cân bằng",
    INSUFFICIENT_LIQUIDITY: "Không đủ thanh khoản cho giao dịch này.",
    LIQUIDITY_PROV_FEE: "Phí cung cấp thanh khoản:",
    LIQUIDITY_REWARDS: "Phần thưởng của nhà cung cấp thanh khoản",
    LP_TOKENS_IN_WALLET: "Mã thông báo LP trong ví của bạn",
    MINUTES: "Phút",
    NO_LIQUTITY_LIST: "Không có danh sách liqutity",
    NO_POOL_FOUND: "Không tìm thấy hồ bơi.",
    PER: "mỗi",
    POOL: "Hồ bơi",
    POOL_FOUND: "Hồ bơi tìm thấy!",
    POOL_SHARE: "Chia sẻ hồ bơi của bạn:",
    POOL_TOKENS: "Mã thông báo hồ bơi của bạn:",
    PRICE_IMPACTS: "Tác động về giá:",
    PRICE_IMPACT_HIGH: "Tác động về giá đến cao",
    RATES: "Giá",
    RATIO_PRICE_POOL: "Tỷ lệ mã thông báo bạn thêm sẽ đặt giá của nhóm này.",
    RECENT_TRANSACTIONS: "Các giao dịch gần đây",
    REMOVE: "Loại bỏ",
    REMOVE_LIQUIDITY: "Loại bỏ thanh khoản",
    REMOVING_POOL_TOKENS: "Loại bỏ các mã thông báo nhóm chuyển đổi vị trí của bạn trở lại thành các mã thông báo bên dưới với tốc độ hiện tại, tỷ lệ thuận với phần của bạn trong nhóm. Phí tích lũy được bao gồm trong số tiền bạn nhận được.",
    ROUTE: "Tuyến đường:",
    SELECT_A_TOKEN: "Chọn một mã thông báo",
    SETTINGS: "Cài đặt",
    SHARE_OF_POOL: "Chia sẻ của hồ bơi:",
    SLIPPAGE_TOL: "Khả năng chịu trượt",
    SLIPPAGE_TOLERANCE: "Khả năng chịu trượt:",
    SUPPLY: "Cung cấp",
    SWAP: "Tráo đổi",
    TO: "Đến",
    TOKEN_FARM_UNSTAKE: "Hoặc, nếu bạn đặt các mã thông báo Dopamine-LP của bạn trong một trang trại, hãy không thể xem chúng ở đây.",
    TRANSACTION_DEADLINE: "Hạn chót giao dịch",
    TRANSACTION_NOT_FOUND: "Không tìm thấy trancation",
    TRANSACTION_REVERT_PERCENTAGE: "Trasaction của bạn sẽ trở lại nếu giá thay đổi không thuận lợi hơn tỷ lệ phần trăm này",
    WITHDRAW_LIQUIDITY: "Các nhà cung cấp thanh khoản kiếm được một khoản phí 0.25%% cho tất cả các giao dịch tỷ lệ thuận với tỷ lệ của họ trong nhóm. Lệ phí được thêm vào nhóm, tích lũy trong thời gian thực và có thể được yêu cầu bằng cách rút thanh khoản của bạn.",
    YOUR_LIQUIDITY: "Thanh khoản của bạn",
    bALANCE: "THĂNG BẰNG",
    MAXIMUM_SOLD: "Đã bán tối đa",
    MINIMUM_RECEIVED_C: "Đã nhận tối thiểu",
    TOKEN_NAME: "Tên mã thông báo",
    FOUND_MY_ADDRESS: "Tìm thấy theo địa chỉ",
    IMPORT_TOKEN: "Nhập mã thông báo",
    SEARCH_NAME: "Tìm kiếm theo tên hoặc địa chỉ",

    AND: "và",
    CONFIRM_SUPPLY: "Xác nhận cung cấp",
    CONFIRM_TRANSACTION_WALLET: "Xác nhận giao dịch này trong ví của bạn",
    CONNECT_WALLETTT: "Kết nối ví",
    DEPOSITED: "Gửi tiền:",
    DISMISS: "Bỏ qua",
    ERROR: "Lỗi",
    INPUT_ESTIMATED_SELL_MOST: "Đầu vào được ước tính. Bạn sẽ bán nhiều nhất",
    LEARN_ABOUT_WALLETS: "Tìm hiểu thêm về ví",
    NEW_TO_ETHEREUM: "Mới đối với Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Đầu ra được ước tính. Nếu giá thay đổi nhiều hơn",
    PRICEE: "Giá bán:",
    SUPPLYING: "Cung cấp",
    SWAPPING: "Đổi",
    TRANSACTION_REJECTED: "Giao dịch bị từ chối",
    TRANSACTION_SUBMITTED: "Giao dịch được gửi",
    VIEW_ON_BSCSCAN: "Xem trên bscscan",
    WAITING_CONFIRMATION: "Đang chờ xác nhận",
    YOUR_TRANSACTION_REVERT: "Giao dịch của bạn sẽ hoàn nguyên.",
    YOU_WILL_RECEIVE: "Bạn sẽ nhận :",
    PARTNERSHIPS: "quan hệ đối tác",
}