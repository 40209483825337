/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const theme = useSelector((state) => state.theme);

  return (
    <footer className={footerClasses + " section"}>
      <div className="footer_home">
        <div className={classes.container}>
          <div className="footer_home_noborder">
            <div className="container">
              <div className="footer-bottom-links">
                <p className="copy_rights">
                  © Copyright 2023 <a href="#">Dopamine DEX</a>, All rights
                  reserved
                </p>
                <ul>
                  <li>
                    <a className="" href="/privacy" target="_blank" scale="sm">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="" href="/terms" target="_blank" scale="sm">
                      Terms and Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
