import axios from "axios/dist/axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const gettemplate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/api/get-site-template`,
      data,
    });
    console.log(respData.data.data, "apidata");
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const getsocialmedialinks = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${baseUrl}/api/get-social-medialinks`,
    });
    console.log(respData.data.data, "apidata");
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}
